import React from 'react'
import { GF_DARK_BLUE } from '../../utils/Constants'

export default function ChevronDown() {
  return (
    <div>
      <svg
        className="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          stroke={GF_DARK_BLUE}
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
        ></path>
      </svg>
    </div>
  )
}
