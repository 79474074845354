import { issuingAPI } from '../services/Axios'
import { APP_COUNT } from './CommonActions'

export const PREFIX = '@manageSales/'

export const LOADING_MANAGE_SALES = `${PREFIX}LOADING_MANAGE_SALES`
export const RECEIVED_MANAGE_SALES = `${PREFIX}RECEIVED_MANAGE_SALES`
export const ERROR_MANAGE_SALES = `${PREFIX}ERROR_MANAGE_SALES`
export const RESET_MANAGE_SALES = `${PREFIX}RESET_MANAGE_SALES`

export const CHANGING_ORDER_STATUS = `${PREFIX}CHANGING_ORDER_STATUS`
export const SUCCESS_ORDER_STATUS = `${PREFIX}SUCCESS_ORDER_STATUS`
export const FAIL_ORDER_STATUS = `${PREFIX}FAIL_ORDER_STATUS`

export const ACTIVATING_ORDER = `${PREFIX}ACTIVATING_ORDER`
export const SUCCESS_ACTIVATE_ORDER = `${PREFIX}SUCCESS_ACTIVATE_ORDER`
export const FAIL_ACTIVATE_ORDER = `${PREFIX}FAIL_ACTIVATE_ORDER`

export const RESET_ORDER_STATUS = `${PREFIX}RESET_ORDER_STATUS`
export const RESET_ACTIVATE_ORDER = `${PREFIX}RESET_ACTIVATE_ORDER`

export const MANAGE_SALES_OPERATION_ID = `${PREFIX}MANAGE_SALES_OPERATION_ID`

export const getManageSalesByDeliveryMethod =
  (
    deliveryMethod,
    deliveryType,
    customerType,
    searchKeyword,
    pageNumber,
    fromDate = null,
    toDate = null,
  ) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_MANAGE_SALES })

    dispatch({ type: APP_COUNT })

    let ct = ''
    if (customerType === 'all') {
      ct = null
    } else {
      ct = customerType
    }

    if (deliveryType === 'awaiting-payment-pick')
      deliveryType = 'awaiting-payment'

    const { tagID } = getState().tag
    const params = {
      home_delivery: deliveryMethod,
      type: deliveryType,
      customer_type: ct,
      query: searchKeyword,
      from_date: fromDate,
      to_date: toDate,
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    }

    if (pageNumber !== 0) {
      Object.assign(params, { page: pageNumber })
    }

    return issuingAPI
      .get(`tag/${tagID}/online-sale`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_MANAGE_SALES, payload: success.data })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_MANAGE_SALES, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }

export const changeManageSalesStatus =
  (reference, tokens, orderStatus) => (dispatch, getState) => {
    console.log('changeManageSalesStatus')
    dispatch({ type: CHANGING_ORDER_STATUS })

    const { tagID } = getState().tag
    const { changeManageSalesStatusOpID } = getState().manageSales

    const headers = {
      'operation-id': changeManageSalesStatusOpID,
    }

    const data = {
      status: orderStatus,
      tokens,
    }

    return issuingAPI
      .put(
        `tag/${tagID}/online-sale/${reference}/status`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_ORDER_STATUS, payload: success.data })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return success
      })
      .catch((error) => {
        dispatch({ type: FAIL_ORDER_STATUS, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }

export const changeManageSalesStatusSent =
  (reference, orderStatus) => (dispatch, getState) => {
    console.log('changeManageSalesStatusSent')
    dispatch({ type: CHANGING_ORDER_STATUS })

    const { tagID } = getState().tag

    const { changeManageSalesStatusSentOpID } = getState().manageSales

    const headers = {
      'operation-id': changeManageSalesStatusSentOpID,
    }

    const data = {
      status: orderStatus,
    }

    return issuingAPI
      .put(
        `tag/${tagID}/online-sale/${reference}/status`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_ORDER_STATUS, payload: success.data })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return success
      })
      .catch((error) => {
        dispatch({ type: FAIL_ORDER_STATUS, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }

export const changeManageSalesStatusByGlobalOrderRef =
  (reference, orderStatus) => (dispatch, getState) => {
    dispatch({ type: CHANGING_ORDER_STATUS })

    const { tagID } = getState().tag

    const { changeManageSalesStatusSentOpID } = getState().manageSales

    const headers = {
      'operation-id': changeManageSalesStatusSentOpID,
    }

    const data = {
      status: orderStatus,
    }

    return issuingAPI
      .put(
        `tag/${tagID}/online-sale/global-order/${reference}/status`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_ORDER_STATUS, payload: success.data })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return success
      })
      .catch((error) => {
        dispatch({ type: FAIL_ORDER_STATUS, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }

export const activateCardOrderByGlobalOrderRef =
  (reference) => (dispatch, getState) => {
    dispatch({ type: ACTIVATING_ORDER })

    const { tagID } = getState().tag
    const { activateOerderOpID } = getState().manageSales

    const headers = {
      'operation-id': activateOerderOpID,
    }

    return issuingAPI
      .post(
        `tag/${tagID}/online-sale/global-order/${reference}/activate`,
        {},
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_ACTIVATE_ORDER, payload: success.data })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return success
      })
      .catch((error) => {
        dispatch({ type: FAIL_ACTIVATE_ORDER, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }

export const changeManageSalesStatusPaid =
  (reference, orderStatus) => (dispatch, getState) => {
    console.log('changeManageSalesStatusPaid')
    dispatch({ type: CHANGING_ORDER_STATUS })

    const { tagID } = getState().tag

    const { changeManageSalesStatusPaidOpID } = getState().manageSales

    const headers = {
      'operation-id': changeManageSalesStatusPaidOpID,
    }

    const data = {
      status: orderStatus,
    }

    return issuingAPI
      .put(
        `tag/${tagID}/online-sale/${reference}/status`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_ORDER_STATUS, payload: success.data })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return success
      })
      .catch((error) => {
        dispatch({ type: FAIL_ORDER_STATUS, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }

export const activateCardOrder = (reference) => (dispatch, getState) => {
  console.log('activateCardOrder')
  dispatch({ type: ACTIVATING_ORDER })

  const { tagID } = getState().tag
  const { activateOerderOpID } = getState().manageSales

  const headers = {
    'operation-id': activateOerderOpID,
  }

  return issuingAPI
    .post(`tag/${tagID}/online-sale/${reference}/activate`, {}, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_ACTIVATE_ORDER, payload: success.data })
      dispatch({ type: MANAGE_SALES_OPERATION_ID })
      return success
    })
    .catch((error) => {
      dispatch({ type: FAIL_ACTIVATE_ORDER, payload: error })
      dispatch({ type: MANAGE_SALES_OPERATION_ID })
      return error
    })
}

export const prepareAndActivateOrder =
  (reference, tokens, orderStatus) => (dispatch, getState) => {
    console.log('prepareAndActivateOrder')
    dispatch({ type: CHANGING_ORDER_STATUS })

    const { tagID } = getState().tag
    const { prepareOrderOpID } = getState().manageSales

    const data = {
      status: orderStatus,
      tokens,
    }

    const headers = {
      'operation-id': prepareOrderOpID,
    }

    return issuingAPI
      .put(
        `tag/${tagID}/online-sale/${reference}/status`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch(activateCardOrder(reference))
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
      })
      .catch((error) => {
        dispatch({ type: FAIL_ORDER_STATUS, payload: error })
        dispatch({ type: MANAGE_SALES_OPERATION_ID })
        return error
      })
  }
