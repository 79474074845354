/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Userpilot } from 'userpilot'
import ReactDatePicker from 'react-datepicker'
import CurrencyFormat from 'react-currency-format'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import Container from '../../../UIComponents/Container'
import { getCalanderLocale } from '../../../../utils/CalanderLocale'
import {
  RESET_PERFORMANCE_REPORT,
  getPerformanceReport,
  getPerformanceReportExcel,
} from '../../../../actions/ManagementPerformanceReportActions'
import MessageAlert from '../../../Alert/MessageAlert'

export default function ManagementPerformanceReport() {
  const { selectedLanguage } = useSelector((state) => state.language)
  const { tagCurrency, receivedTagList, selectedTag } = useSelector(
    (state) => state.tag,
  )
  const { appCount } = useSelector((state) => state.commonReducer)
  const {
    loadingPerformanceReport,
    receivedPerformanceReport,
    summaryPerformanceReport,
    errorPerformanceReport,
  } = useSelector((state) => state.performanceReport)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [reportType, setReportType] = useState('1')
  const [viewType, setViewType] = useState('1')
  const [yearPicker, setYearPicker] = useState(new Date())
  const [monthPicker, setMonthPicker] = useState(new Date())

  getCalanderLocale(selectedLanguage)

  useEffect(() => {
    Userpilot.reload()

    if (appCount === 0) {
      dispatch({ type: RESET_PERFORMANCE_REPORT })
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_PERFORMANCE_REPORT })
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_PERFORMANCE_REPORT })
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
    <input
      data-testid={id}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border cursor-pointer text-center w-40 rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
    />
  ))

  const setReportTypeHandler = (type) => {
    dispatch({ type: RESET_PERFORMANCE_REPORT })
    setReportType(type)
  }

  const setReportViewHandler = (view) => {
    dispatch({ type: RESET_PERFORMANCE_REPORT })
    setViewType(view)
  }

  const yearHandler = (year) => {
    dispatch({ type: RESET_PERFORMANCE_REPORT })
    setYearPicker(year)
  }

  const monthHandler = (month) => {
    dispatch({ type: RESET_PERFORMANCE_REPORT })
    setMonthPicker(month)
  }

  const getPerformanceReportTable = () => {
    dispatch(
      getPerformanceReport(
        reportType === '1' ? 'monthly' : reportType === '2' ? 'ytd' : 'r12m',
        viewType === '2' ? 'group' : '',
        yearPicker.getFullYear(),
        monthPicker.getMonth() + 1,
      ),
    )
  }

  const getPerformanceReportCSV = () => {
    dispatch(
      getPerformanceReportExcel(
        reportType === '1' ? 'monthly' : reportType === '2' ? 'ytd' : 'r12m',
        viewType === '2' ? 'group' : '',
        yearPicker.getFullYear(),
        monthPicker.getMonth() + 1,
      ),
    )
  }

  const getTableBackgroundColor = (yoy) => {
    let classStr = ''
    const converted = yoy

    if (converted === 0.0 || converted === null) {
      return classStr
    }
    if (converted <= -20) {
      classStr = 'background_red_03'
    } else if (converted > -20 && converted <= -10) {
      classStr = 'background_red_02'
    } else if (converted > -10 && converted < 0.0) {
      classStr = 'background_red_01'
    } else if (converted > 0 && converted <= 10) {
      classStr = 'background_green_01'
    } else if (converted > 10 && converted < 20) {
      classStr = 'background_green_02'
    } else {
      classStr = 'background_green_03'
    }

    return classStr
  }

  const shouldDisplayDatePicker = useMemo(() => viewType === '2', [viewType])

  return (
    <Container
      title={t('management-performance-report')}
      loading={loadingPerformanceReport}
    >
      <div className="flex flex-col mb-20">
        <div className="flex flex-col sm:flex-col md:flex-row md:gap-10 items-start mb-10">
          <div className="flex flex-row gap-10">
            <div className="flex flex-col justify-start">
              <div className="py-2">{t('select-type-of-report')}</div>
              <div className="">
                <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                  <input
                    type="radio"
                    id="radio-one-card-type1"
                    name="switch-one-card-type1"
                    value="1"
                    checked={reportType === '1'}
                    onChange={(e) => {
                      setReportTypeHandler(e.target.value)
                    }}
                  />
                  <label
                    style={{ width: '120px' }}
                    htmlFor="radio-one-card-type1"
                  >
                    {t('monthly')}
                  </label>
                  <input
                    type="radio"
                    id="radio-two-card-type2"
                    name="switch-two-card-type2"
                    value="2"
                    checked={reportType === '2'}
                    onChange={(e) => {
                      setReportTypeHandler(e.target.value)
                    }}
                  />
                  <label
                    style={{ width: '80px' }}
                    htmlFor="radio-two-card-type2"
                  >
                    {t('ytd')}
                  </label>
                  <input
                    type="radio"
                    id="radio-two-card-type3"
                    name="switch-two-card-type3"
                    value="3"
                    checked={reportType === '3'}
                    onChange={(e) => {
                      setReportTypeHandler(e.target.value)
                    }}
                  />
                  <label htmlFor="radio-two-card-type3">
                    {t('rolling-12-months')}
                  </label>
                </div>
              </div>
            </div>
            {receivedTagList && receivedTagList.length > 1 && (
              <div className="flex flex-col justify-start">
                <div className="py-2">{t('select-view')}</div>
                <div className="">
                  <div className="h-10 mb-5 sm:mb-5 md:mb-0 " id="switch-field">
                    <input
                      type="radio"
                      id="radio-one-card-view1"
                      name="switch-one-card-view1"
                      value="1"
                      checked={viewType === '1'}
                      onChange={(e) => {
                        setReportViewHandler(e.target.value)
                      }}
                    />
                    <label
                      style={{ width: '180px' }}
                      htmlFor="radio-one-card-view1"
                      title={selectedTag.name}
                    >
                      {selectedTag.name}
                    </label>
                    <input
                      type="radio"
                      id="radio-two-card-view2"
                      name="switch-two-card-view2"
                      value="2"
                      checked={viewType === '2'}
                      onChange={(e) => {
                        setReportViewHandler(e.target.value)
                      }}
                    />
                    <label
                      style={{ width: '120px' }}
                      htmlFor="radio-two-card-view2"
                    >
                      {t('portfolio')}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="ml-auto">
            {' '}
            {}
            <div className="flex flex-row gap-5 items-end">
              <div className="flex flex-col w-full sm:w-full md:w-48">
                <div className="flex items-center   py-2">{t('Year')}</div>
                <ReactDatePicker
                  id="yearID"
                  showYearPicker
                  dateFormat="yyyy"
                  selected={yearPicker}
                  onChange={(year) => yearHandler(year)}
                  customInput={<DPCustomInput />}
                  locale={selectedLanguage.value}
                />
              </div>
              {shouldDisplayDatePicker && (
                <div className="flex flex-col w-full sm:w-full md:w-48">
                  <div className="flex items-center   py-2">{t('Month')}</div>
                  <ReactDatePicker
                    id="monthID"
                    selected={monthPicker}
                    onChange={(month) => monthHandler(month)}
                    dateFormat="MMMM"
                    renderCustomHeader={() => <div></div>}
                    showMonthYearPicker
                    showTwoColumnMonthYearPicker
                    customInput={<DPCustomInput />}
                    locale={selectedLanguage.value}
                  />
                </div>
              )}
              <button
                disabled={loadingPerformanceReport}
                onClick={getPerformanceReportTable}
                className="border h-12ß w-20 h-10 flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
              >
                {t('Go')}!
              </button>
            </div>
          </div>
        </div>

        <div className="border-t border-gfPeriwinkle">
          {receivedPerformanceReport &&
            receivedPerformanceReport.length !== 0 && (
              <div className="flex flex-row justify-end items-center my-5">
                <div className="flex flex-row justify-start items-end gap-5"></div>
                <button
                  className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2 "
                  onClick={getPerformanceReportCSV}
                >
                  {t('export-excel')}
                </button>
              </div>
            )}
          <div className="flex flex-row justify-center">
            {receivedPerformanceReport &&
              receivedPerformanceReport.length === 0 && (
                <div className="my-10">
                  <MessageAlert
                    message={t(
                      'There-are-no-information-to-display-for-this-date',
                    )}
                  />
                </div>
              )}
            {receivedPerformanceReport &&
              receivedPerformanceReport.length !== 0 && (
                <div>
                  <div class="mpr_main_wrapper" className="mb-30">
                    <table
                      width="1100"
                      border="0"
                      cellspacing="0"
                      cellpadding="4"
                      class="mpr_table_main"
                    >
                      <tbody>
                        <tr>
                          <td colSpan="2" rowSpan="3" class="no-border"></td>
                          <td
                            colSpan="6"
                            class="caps_on higlight_border_full"
                            style={{
                              width: '600px',
                              padding: '10px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                            }}
                          >
                            {reportType === '1' && t('monthly')}
                            {reportType === '2' && t('ytd')}
                            {reportType === '3' && t('rolling-12-months')}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="3"
                            class=" higlight_border_left higlight_border_right higlight_border_bottom"
                            style={{
                              width: '300px',
                              padding: '8px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            <strong>{t('cards-loaded')}</strong>
                          </td>
                          <td
                            colSpan="3"
                            class=" higlight_border_right higlight_border_bottom"
                            style={{
                              width: '300px',
                              padding: '8px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            <strong>{t('load-value')}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="higlight_border_left higlight_border_right"
                            style={{
                              width: '100px',
                              padding: '4px',
                              color: '#5B6282',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            {yearPicker.getFullYear() - 1}
                          </td>
                          <td
                            class=" higlight_border_right"
                            style={{
                              width: '100px',
                              padding: '4px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            {yearPicker.getFullYear()}
                          </td>
                          <td
                            class=" higlight_border_right"
                            style={{
                              width: '100px',
                              padding: '4px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            %YoY
                          </td>
                          <td
                            class="higlight_border_right"
                            style={{
                              width: '100px',
                              padding: '4px',
                              color: '#5B6282',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            {yearPicker.getFullYear() - 1}
                          </td>
                          <td
                            class=" higlight_border_right"
                            style={{
                              width: '100px',
                              padding: '4px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            {yearPicker.getFullYear()}
                          </td>
                          <td
                            class=" higlight_border_right"
                            style={{
                              width: '100px',
                              padding: '4px',
                              color: '#172352',
                              backgroundColor: '#BEBAB7',
                              fontFamily: 'MulishBold',
                            }}
                          >
                            % YoY
                          </td>
                        </tr>

                        {receivedPerformanceReport &&
                          receivedPerformanceReport.map((item, index) => (
                            <tr key={index}>
                              <td
                                data-testid={`${viewType === '2' ? item.name : t(item.month_name)}`}
                                class={`align-right higlight_border_right higlight_border_left ${index === 0 ? 'higlight_border_top' : ''} ${index + 1 === receivedPerformanceReport.length ? 'higlight_border_bottom' : ''}`}
                                style={{
                                  width: '250px',
                                  color: '#172352',
                                  backgroundColor: '#BEBAB7',
                                  fontFamily: 'MulishBold',
                                }}
                              >
                                {viewType === '2'
                                  ? item.name
                                  : t(item.month_name)}
                              </td>
                              {index === 0 && (
                                <td
                                  rowSpan={receivedPerformanceReport.length}
                                  class="no-border"
                                  style={{ width: '50px' }}
                                ></td>
                              )}
                              <td
                                data-testid={`cards-loaded-${viewType === '2' ? item.name : t(item.month_name)}-${yearPicker.getFullYear() - 1}`}
                                style={{
                                  textAlign: 'right',
                                  fontFamily: 'MulishRegular',
                                }}
                                class="text-disabled size-12 higlight_border_left higlight_border_right text-right"
                              >
                                {item.previous_quantity !== null
                                  ? item.previous_quantity
                                  : '-'}
                              </td>
                              <td
                                data-testid={`cards-loaded-${viewType === '2' ? item.name : t(item.month_name)}-${yearPicker.getFullYear()}`}
                                style={{
                                  textAlign: 'right',
                                  fontFamily: 'MulishRegular',
                                }}
                                class="higlight_border_right"
                              >
                                {item.quantity !== null ? item.quantity : '-'}
                              </td>
                              <td
                                data-testid={`cards-loaded-${viewType === '2' ? item.name : t(item.month_name)}-%YoY`}
                                style={{
                                  textAlign: 'right',
                                  fontFamily: 'MulishRegular',
                                }}
                                class={`${getTableBackgroundColor(item.quantity_yoy)} higlight_border_right`}
                              >
                                <span>
                                  {item.quantity_yoy !== null
                                    ? item.quantity_yoy
                                    : '-'}
                                </span>
                                {item.quantity_yoy !== null ? (
                                  <span style={{ marginLeft: '2px' }}>%</span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td
                                data-testid={`load-value-${viewType === '2' ? item.name : t(item.month_name)}-${yearPicker.getFullYear() - 1}`}
                                style={{
                                  textAlign: 'right',
                                  fontFamily: 'MulishRegular',
                                }}
                                class="text-disabled size-12 higlight_border_right"
                              >
                                {item.previous_amount !== null ? (
                                  <div className="flex justify-end text-gfLightBlue">
                                    <CurrencyFormat
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      value={item.previous_amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                    />
                                    <span className="ml-2">{` ${item.currency}`}</span>
                                  </div>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td
                                data-testid={`load-value-${viewType === '2' ? item.name : t(item.month_name)}-${yearPicker.getFullYear()}`}
                                style={{
                                  textAlign: 'right',
                                  fontFamily: 'MulishRegular',
                                }}
                                class="higlight_border_right"
                              >
                                {item.amount !== null ? (
                                  <div className="flex justify-end text-gfLightBlue">
                                    <CurrencyFormat
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      value={item.amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                    />
                                    <span className="ml-2">{` ${item.currency}`}</span>
                                  </div>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td
                                data-testid={`load-value-${viewType === '2' ? item.name : t(item.month_name)}-%YoY`}
                                style={{
                                  textAlign: 'right',
                                  fontFamily: 'MulishRegular',
                                }}
                                class={`${getTableBackgroundColor(item.amount_yoy)} higlight_border_right`}
                              >
                                <span>
                                  {item.amount_yoy !== null
                                    ? item.amount_yoy
                                    : '-'}
                                </span>
                                {item.amount_yoy !== null ? (
                                  <span style={{ marginLeft: '2px' }}>%</span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          ))}

                        {summaryPerformanceReport &&
                        reportType === '1' &&
                        viewType === '1' ? (
                          <tr>
                            <td class="no-border"></td>
                            {viewType !== '2' && (
                              <td
                                rowSpan={receivedPerformanceReport.length}
                                class="no-border"
                                style={{ width: '50px' }}
                              ></td>
                            )}
                            <td
                              data-testid={`cards-loaded-summary-${yearPicker.getFullYear() - 1}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right text-disabled size-12 higlight_border_left higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.previous_total_quantity !==
                              null
                                ? summaryPerformanceReport.previous_total_quantity
                                : '-'}
                            </td>
                            <td
                              data-testid={`cards-loaded-summary-${yearPicker.getFullYear()}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.total_quantity !== null
                                ? summaryPerformanceReport.total_quantity
                                : '-'}
                            </td>
                            <td
                              data-testid={`cards-loaded-summary-%YoY`}
                              style={{ textAlign: 'right' }}
                              class={`${getTableBackgroundColor(summaryPerformanceReport.total_quantity_yoy)} higlight_border_right higlight_border_bottom higlight_border_top`}
                            >
                              {summaryPerformanceReport.total_quantity_yoy !==
                              null ? (
                                <span>
                                  {summaryPerformanceReport.total_quantity_yoy}
                                </span>
                              ) : (
                                '-'
                              )}
                              {summaryPerformanceReport.total_quantity_yoy !==
                              null ? (
                                <span style={{ marginLeft: '2px' }}>%</span>
                              ) : (
                                ''
                              )}
                            </td>
                            <td
                              data-testid={`load-value-summary-${yearPicker.getFullYear() - 1}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right text-disabled size-12 higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.previous_total_amount !==
                              null ? (
                                <div className="flex justify-end text-gfLightBlue">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={
                                      summaryPerformanceReport.previous_total_amount
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-2">{` ${tagCurrency}`}</span>
                                </div>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td
                              data-testid={`load-value-summary-${yearPicker.getFullYear()}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.total_amount !==
                              null ? (
                                <div className="flex justify-end text-gfLightBlue">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={
                                      summaryPerformanceReport.total_amount
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-2">{` ${tagCurrency}`}</span>
                                </div>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td
                              data-testid={`load-value-summary-%YoY`}
                              style={{ textAlign: 'right' }}
                              class={`${getTableBackgroundColor(summaryPerformanceReport.total_amount_yoy)} higlight_border_right higlight_border_bottom higlight_border_top`}
                            >
                              {summaryPerformanceReport.total_amount_yoy !==
                              null ? (
                                <span>
                                  {summaryPerformanceReport.total_amount_yoy}
                                </span>
                              ) : (
                                '-'
                              )}
                              {summaryPerformanceReport.total_amount_yoy !==
                              null ? (
                                <span style={{ marginLeft: '2px' }}>%</span>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ) : summaryPerformanceReport &&
                          reportType === '1' &&
                          viewType === '2' ? (
                          <tr>
                            <td class="no-border"></td>
                            {viewType !== '1' && (
                              <td
                                rowSpan={receivedPerformanceReport.length}
                                class="no-border"
                                style={{ width: '50px' }}
                              ></td>
                            )}
                            <td
                              data-testid={`card-loaded-summary-${yearPicker.getFullYear() - 1}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right text-disabled size-12 higlight_border_left higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.previous_total_quantity !==
                              null
                                ? summaryPerformanceReport.previous_total_quantity
                                : '-'}
                            </td>
                            <td
                              data-testid={`card-loaded-summary-${yearPicker.getFullYear()}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.total_quantity !== null
                                ? summaryPerformanceReport.total_quantity
                                : '-'}
                            </td>
                            <td
                              data-testid={`card-loaded-summary-%YoY`}
                              style={{ textAlign: 'right' }}
                              class={`${getTableBackgroundColor(summaryPerformanceReport.total_quantity_yoy)} higlight_border_right higlight_border_bottom higlight_border_top`}
                            >
                              <span>
                                {summaryPerformanceReport.total_quantity_yoy !==
                                null
                                  ? summaryPerformanceReport.total_quantity_yoy
                                  : '-'}
                              </span>
                              {summaryPerformanceReport.total_quantity_yoy !==
                              null ? (
                                <span style={{ marginLeft: '2px' }}>%</span>
                              ) : (
                                ''
                              )}
                            </td>
                            <td
                              data-testid={`card-value-summary-${yearPicker.getFullYear() - 1}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right text-disabled size-12 higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.previous_total_amount !==
                              null ? (
                                <div className="flex justify-end text-gfLightBlue">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={
                                      summaryPerformanceReport.previous_total_amount
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-2">{` ${tagCurrency}`}</span>
                                </div>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td
                              data-testid={`card-value-summary-${yearPicker.getFullYear()}`}
                              style={{ textAlign: 'right' }}
                              class="higlight_border_right higlight_border_bottom higlight_border_top"
                            >
                              {summaryPerformanceReport.total_amount !==
                              null ? (
                                <div className="flex justify-end text-gfLightBlue">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={
                                      summaryPerformanceReport.total_amount
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-2">{` ${tagCurrency}`}</span>
                                </div>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td
                              data-testid={`card-value-summary-%YoY`}
                              style={{ textAlign: 'right' }}
                              class={`${getTableBackgroundColor(summaryPerformanceReport.total_amount_yoy)} higlight_border_right higlight_border_bottom higlight_border_top`}
                            >
                              <span>
                                {summaryPerformanceReport.total_amount_yoy !==
                                null
                                  ? summaryPerformanceReport.total_amount_yoy
                                  : '-'}
                              </span>
                              {summaryPerformanceReport.total_amount_yoy !==
                              null ? (
                                <span style={{ marginLeft: '2px' }}>%</span>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Container>
  )
}
