import { issuingAPI } from '../services/Axios'
import {
  APP_COUNT,
  RESET_APP_COUNT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
} from './CommonActions'
import { exportExcelFile } from '../utils/Helper'

export const PREFIX = '@transactionOverview/'

export const LOADING_TRANSACTION_OVERVIEW = `${PREFIX}LOADING_TRANSACTION_OVERVIEW`
export const RECEIVED_TRANSACTION_OVERVIEW = `${PREFIX}RECEIVED_TRANSACTION_OVERVIEW`
export const ERROR_TRANSACTION_OVERVIEW = `${PREFIX}ERROR_TRANSACTION_OVERVIEW`
export const RESET_TRANSACTION_OVERVIEW = `${PREFIX}RESET_TRANSACTION_OVERVIEW`
export const PAGINATE_TRANSACTION_OVERVIEW = `${PREFIX}PAGINATE_TRANSACTION_OVERVIEW`

export const getTransactionOverview =
  (fromDate, toDate, paymentDirection, page, token) => (dispatch, getState) => {
    dispatch({ type: PAGINATE_TRANSACTION_OVERVIEW })

    dispatch({ type: APP_COUNT })

    const { tagID } = getState().tag

    const params = {
      payment_direction: paymentDirection,
      page,
      per_page: process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    }

    if (fromDate) {
      params.from_date = fromDate

      if (toDate) {
        params.to_date = toDate
      }
    }

    // {{host}}/tag/{{tag_id}}/card/{{card_token}}/transaction-details?per_page=50&page=1&from_date=2024-04-15&payment_direction=all

    return issuingAPI
      .get(`/tag/${tagID}/card/${token}/transaction-details`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_TRANSACTION_OVERVIEW, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_TRANSACTION_OVERVIEW, payload: error })
        dispatch({ type: RESET_APP_COUNT })
        return error
      })
  }

export const getMVCOverviewExcelExport =
  (fromDate, toDate, paymentDirection, selectedPage, token) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID } = getState().tag

    const params = {
      payment_direction: paymentDirection,
    }

    if (fromDate) {
      params.from_date = fromDate

      if (toDate) {
        params.to_date = toDate
      }
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const reportName = 'MVC-Transaction-Overview'

    let fileName = reportName

    if (fromDate) {
      fileName += `-${fromDate}`
    }

    if (toDate) {
      fileName += `-${toDate}`
    }

    // {{host}}/tag/174/card/MVC337475/transaction-details/stream?from_date=2023-01-01

    issuingAPI
      .get(`/tag/${tagID}/card/${token}/transaction-details/stream`, {
        responseType: 'arraybuffer',
        headers,
        params,
      })
      .then((response) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT, payload: response.data })
        exportExcelFile(response.data, fileName)
      })
      .catch((error) => {
        console.log(error, 'error')
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
