import { FormControl, FormField, FormItem, FormMessage } from '../../Form'
import Select from 'react-select'
import DropDownStyle from '../../Dropdown/Components/DropDownStyle'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function ChannelField() {
  const { channels } = useSelector((state) => state.loadCard)
  const { t } = useTranslation()

  return (
    <FormField
      name="channel"
      rules={{ required: t('Required') }}
      render={(field) => (
        <FormItem>
          <FormControl>
            <div className="w-full md:w-1/2 xl:w-2/6 2xl:w-2/6">
              <Select
                id="channelDropdown"
                {...field}
                inputRef={field.ref}
                styles={DropDownStyle((option) => option)}
                placeholder={t('Channel')}
                options={channels}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isSearchable={true}
                components={{
                  IndicatorSeparator: null,
                }}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export default ChannelField
