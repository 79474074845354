/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns'
import React, { forwardRef, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { Userpilot } from 'userpilot'
import { RESET_CSV_REPORT } from '../../../../actions/CommonActions'
import {
  exportCSVFeesReport,
  exportExcelFeesReport,
  getFeesOnlineReport,
  PAGINATE_FEES_ONLINE_LIST,
  RESET_FEES_REPORT,
} from '../../../../actions/FeesReportActions'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import { getCalanderLocale } from '../../../../utils/CalanderLocale'
import { GF_DARK_BLUE } from '../../../../utils/Constants'
import FailAlert from '../../../Alert/FailAlert'
import MessageAlert from '../../../Alert/MessageAlert'
import CardInfoWidget from '../../../CardInfoWidget'
import Container from '../../../UIComponents/Container'
import Table from '../../../UIComponents/Table'

export default function FeesReport() {
  const dispatch = useDispatch()
  const [chooseDate, setChooseDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    Userpilot.reload()
    dispatch({ type: RESET_FEES_REPORT })
    dispatch({ type: RESET_CSV_REPORT })

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_FEES_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_FEES_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  const {
    loadingFeesReport,
    receivingFeesReport,
    errorFeesReport,
    populatedList,
    feesReportSummary,
  } = useSelector((state) => state.feesReport)
  const { loadingCSV, errorCSV, loadingExcel, errorExcel } = useSelector(
    (state) => state.commonReducer,
  )
  const { selectedLanguage } = useSelector((state) => state.language)

  getCalanderLocale(selectedLanguage)

  const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
    <input
      id={id}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border cursor-pointer w-full text-center rounded hover:bg-gfPeriwinkle border-gfPeriwinkle px-8 py-2 focus:outline-none"
    />
  ))

  const fromDatePickHandler = (date) => {
    setChooseDate(date)
  }

  const toDatePickHandler = (date) => {
    setEndDate(date)
  }

  const getChartHandler = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(getFeesOnlineReport(from, to, 'Fees Report'))
  }

  const exportCSV = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(exportCSVFeesReport(from, to, 'Fees Report'))
  }

  const exportExcel = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(exportExcelFeesReport(from, to, 'Fees Report'))
  }

  const handlePageClick = (event) => {
    dispatch({ type: PAGINATE_FEES_ONLINE_LIST, payload: event.selected + 1 })
  }

  return (
    <Container title={t('fees-report')}>
      <div className={`grid grid-cols-1 md:grid-cols-3 gap-5 items-end`}>
        <div className="flex flex-col">
          <div className="flex items-center  py-1">{t('Choose-the-date')}:</div>{' '}
          <ReactDatePicker
            id="dateFromInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingFeesReport}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={chooseDate}
            onChange={(date) => fromDatePickHandler(date)}
            locale={selectedLanguage.value}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center py-1">
            {`${t('End-date')}(${t('Optional')})`}
          </div>
          <ReactDatePicker
            id="dateToInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingFeesReport}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => toDatePickHandler(date)}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
          />
        </div>

        <button
          id="goButton"
          disabled={loadingFeesReport}
          onClick={getChartHandler}
          className="h-10 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75  bg-gfCoral font-MulishBold px-4 focus:outline-none"
        >
          {loadingFeesReport && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Go')}!
        </button>
      </div>
      {((receivingFeesReport && receivingFeesReport.length === 0) ||
        errorFeesReport) && (
        <div className="p-5">
          {receivingFeesReport && receivingFeesReport.length === 0 && (
            <MessageAlert
              message={t('There-are-no-information-to-display-for-this-date')}
            />
          )}
          {errorFeesReport && <FailAlert message={errorFeesReport.message} />}
          {errorCSV && <FailAlert message={errorCSV.message} />}
        </div>
      )}

      {receivingFeesReport && receivingFeesReport.length !== 0 && (
        <div className="flex flex-col mt-20 space-y-5">
          <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
            <CardInfoWidget
              id={'numberOfProducts'}
              bottomLabel={t('Number-of-products')}
              isCurrency={false}
              value={feesReportSummary.total_products}
              labelColor={'text-gfDarkBlue'}
              valueColor={'text-gfDarkBlue'}
            />
            <CardInfoWidget
              id={'totalAmount'}
              bottomLabel={t('Total-amount')}
              isCurrency
              value={feesReportSummary.total_amount}
              labelColor={'text-gfLightBlue'}
              valueColor={'text-gfLightBlue'}
            />
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
            <div className="flex flex-row">
              <button
                id="exportCsvButton"
                onClick={exportCSV}
                className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-CSV-file')}
              </button>
              <button
                id="exportExcelButton"
                onClick={exportExcel}
                className="ml-3 px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('export-excel')}
              </button>
            </div>
            {receivingFeesReport.length >
              window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
              <div>
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    receivingFeesReport.length /
                    window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </div>

          <Table>
            <thead className="bg-gfGrey font-MulishBlack text-sm">
              <tr>
                <th
                  scope="col"
                  className="w-40 md:w-48 px-4 py-3 text-center   uppercase tracking-wider"
                >
                  {t('Date')}
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 w-32 text-center   uppercase tracking-wider"
                >
                  {t('Order-Ref')}
                </th>
                <th
                  scope="col"
                  className="w-48 px-2 py-3 text-center   uppercase tracking-wider"
                >
                  {t('Delivery-method')}
                </th>
                <th
                  scope="col"
                  className="w-24 px-2 py-3 text-center   uppercase tracking-wider"
                >
                  {t('Fees-amount')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gfPeriwinkle">
              {receivingFeesReport &&
                populatedList &&
                populatedList.map((product) => (
                  <tr key={product.order_ref}>
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        {product.date_payment}
                      </div>
                    </td>
                    <td className="px-2 py-4 ">
                      <div className="flex justify-center items-center">
                        {product.order_ref}
                      </div>
                    </td>
                    <td className="px-2 py-4">
                      <div className="flex justify-center items-center">
                        {product.home_delivery
                          ? t('Home-delivery')
                          : t('Pick-up')}
                      </div>
                    </td>
                    <td className="px-2 py-4 text-sm ">
                      <div className="flex justify-end text-gfLightBlue font-MulishBold">
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={product.fee_amount}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <span className="ml-2">{` ${product.currency}`}</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  )
}
