import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { checkLoggedInUserPermissions } from '../../utils/Helper'
import FailAlert from '../Alert/FailAlert'

export default function Administration(props) {
  const { isAuthenticated, receivedLoggedInUserTagPermissions } = useSelector(
    (state) => state.auth,
  )
  return (
    <div className="flex justify-center items-center text-gfDarkBlue text-3xl font-MulishBold p-10">
      Welcome to giftify Admin Page
      {isAuthenticated &&
        receivedLoggedInUserTagPermissions !== null &&
        receivedLoggedInUserTagPermissions.length !== 0 &&
        checkLoggedInUserPermissions('admin') && (
          <Redirect push to="/administration" />
        )}
      {isAuthenticated &&
        receivedLoggedInUserTagPermissions !== null &&
        receivedLoggedInUserTagPermissions.length === 0 && (
          <Redirect push to="/administration" />
        )}
      {isAuthenticated &&
        receivedLoggedInUserTagPermissions !== null &&
        receivedLoggedInUserTagPermissions.length === 0 && (
          <FailAlert message={'Can not find any permissions assign to you'} />
        )}
    </div>
  )
}
