import { issuingAPI } from '../services/Axios'
import {
  APP_COUNT,
  RESET_APP_COUNT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
} from './CommonActions'
import { exportExcelFile } from '../utils/Helper'

export const PREFIX = '@cardUsageAtMerchant/'

export const LOADING_CARD_USAGE_MERCHANT = `${PREFIX}LOADING_CARD_USAGE_MERCHANT`
export const RECEIVED_CARD_USAGE_MERCHANT = `${PREFIX}RECEIVED_CARD_USAGE_MERCHANT`
export const ERROR_CARD_USAGE_MERCHANT = `${PREFIX}ERROR_CARD_USAGE_MERCHANT`
export const RESET_CARD_USAGE_MERCHANT = `${PREFIX}RESET_CARD_USAGE_MERCHANT`
export const PAGINATE_CARD_USAGE_MERCHANT = `${PREFIX}PAGINATE_CARD_USAGE_MERCHANT`

export const LOADING_CARD_USAGE_MERCHANT_SUMMARY = `${PREFIX}LOADING_CARD_USAGE_MERCHANT_SUMMARY`
export const RECEIVED_CARD_USAGE_MERCHANT_SUMMARY = `${PREFIX}RECEIVED_CARD_USAGE_MERCHANT_SUMMARY`
export const ERROR_CARD_USAGE_MERCHANT_SUMMARY = `${PREFIX}ERROR_CARD_USAGE_MERCHANT_SUMMARY`

const buildRequestParameters = (
  fromDate,
  toDate,
  selectedMerchant,
  page,
  perPage,
) => {
  const params = {}

  if (selectedMerchant) {
    params.merchant_id = selectedMerchant
  }

  if (fromDate) {
    params.from_date = fromDate

    if (toDate) {
      params.to_date = toDate
    }
  }

  if (page) {
    params.page = page
  }

  if (perPage) {
    params.per_page = process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
  }

  return params
}

export const getCardUsageMerchant =
  (fromDate, toDate, selectedMerchant, page) => (dispatch, getState) => {
    dispatch({ type: PAGINATE_CARD_USAGE_MERCHANT })

    dispatch({ type: APP_COUNT })

    const { tagID } = getState().tag

    const params = buildRequestParameters(
      fromDate,
      toDate,
      selectedMerchant,
      page,
      process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    )

    // {{host}}/tag/{{tag_id}}/merchant-transactions?from_date=2019-08-08&to_date=2022-08-08&merchant_id=2450114

    return issuingAPI
      .get(`/tag/${tagID}/merchant-transactions`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_CARD_USAGE_MERCHANT, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_USAGE_MERCHANT, payload: error })
        dispatch({ type: RESET_APP_COUNT })
        return error
      })
  }

export const getCardUsageMerchantSummary =
  (fromDate, toDate, selectedMerchant) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_USAGE_MERCHANT_SUMMARY })

    dispatch({ type: APP_COUNT })

    const { tagID } = getState().tag

    const params = buildRequestParameters(fromDate, toDate, selectedMerchant)

    // {{host}}/tag/{{tag_id}}/merchant-transactions/summary?from_date=2019-08-08&to_date=2022-08-08&merchant_id=2450114

    return issuingAPI
      .get(`/tag/${tagID}/merchant-transactions/summary`, { params })
      .then((success) => {
        dispatch({
          type: RECEIVED_CARD_USAGE_MERCHANT_SUMMARY,
          payload: success.data,
        })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_USAGE_MERCHANT_SUMMARY, payload: error })
        dispatch({ type: RESET_APP_COUNT })
        return error
      })
  }

export const getCardUsageMerchantsExcelExport =
  (fromDate, toDate, selectedMerchant) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID, selectedTag } = getState().tag

    const params = buildRequestParameters(fromDate, toDate, selectedMerchant)

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const reportName = `cards_usage_at_${selectedTag.name}`

    let fileName = reportName

    if (fromDate) {
      fileName += `-${fromDate}`
    }

    if (toDate) {
      fileName += `-${toDate}`
    }

    // {{host}}/tag/{{tagId}}/merchant-transactions/stream?from_date=2019-08-08&to_date=2019-08-08&merchant_id=2450114

    issuingAPI
      .get(`/tag/${tagID}/merchant-transactions/stream`, {
        responseType: 'arraybuffer',
        headers,
        params,
      })
      .then((response) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT, payload: response.data })
        exportExcelFile(response.data, fileName)
      })
      .catch((error) => {
        console.log(error, 'error')
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
