import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile } from '../utils/Helper'
import {
  ERROR_CSV_REPORT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  ERROR_EXCEL_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
} from './CommonActions'

export const PREFIX = '@expiryReport/'

export const LOADING_EXPIRY_REPORT = `${PREFIX}LOADING_EXPIRY_REPORT`
export const RECEIVED_EXPIRY_REPORT = `${PREFIX}RECEIVED_EXPIRY_REPORT`
export const ERROR_EXPIRY_REPORT = `${PREFIX}ERROR_EXPIRY_REPORT`
export const PAGINATE_EXPIRY_LIST = `${PREFIX}PAGINATE_EXPIRY_LIST`
export const RESET_EXPIRY_REPORT = `${PREFIX}RESET_EXPIRY_REPORT`

export const getExpiryReport = (fromDate, toDate) => (dispatch, getState) => {
  dispatch({ type: LOADING_EXPIRY_REPORT })

  const { tagID, tagCardBrands } = getState().tag
  const params = {
    from_date: fromDate,
    to_date: toDate,
    card_brand_ids: tagCardBrands,
  }

  return issuingAPI
    .get(`tag/${tagID}/expiry`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_EXPIRY_REPORT, payload: success.data })
      return success
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXPIRY_REPORT, payload: error })
      return error
    })
}

export const exportCSVExpiryReport =
  (fromDate, toDate, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { tagID, tagCardBrands } = getState().tag
    const params = {
      from_date: fromDate,
      to_date: toDate,
      card_brand_ids: tagCardBrands,
    }

    const headers = {
      Accept: 'text/csv',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/expiry/stream`, { headers, params, data })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT })
        exportCSVFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const exportExcelExpiryReport =
  (fromDate, toDate, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID, tagCardBrands } = getState().tag
    const params = {
      from_date: fromDate,
      to_date: toDate,
      card_brand_ids: tagCardBrands,
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/expiry/stream`, {
        responseType: 'blob',
        headers,
        params,
        data,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT })
        exportExcelFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
