import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile } from '../utils/Helper'
import {
  ERROR_CSV_REPORT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  ERROR_EXCEL_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
} from './CommonActions'

export const PREFIX = '@tokenizationReport/'

export const LOADING_DIGITISATION_REPORT = `${PREFIX}LOADING_DIGITISATION_REPORT`
export const RECEIVED_DIGITISATION_REPORT = `${PREFIX}RECEIVED_DIGITISATION_REPORT`
export const ERROR_DIGITISATION_REPORT = `${PREFIX}ERROR_DIGITISATION_REPORT`
export const RESET_DIGITISATION_REPORT = `${PREFIX}RESET_DIGITISATION_REPORT`
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`

// https://portalapi.dev.giftify.me/tag/{tagId}/tokenized

export const getTokenizationReport =
  (fromDate, toDate, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_DIGITISATION_REPORT })

    const { tagID } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
      page,
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    }

    return issuingAPI
      .get(`tag/${tagID}/tokenized`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_DIGITISATION_REPORT, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_DIGITISATION_REPORT, payload: error })
        return error
      })
  }

export const getCSVTokenization =
  (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { tagID } = getState().tag
    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    const headers = {
      Accept: 'text/csv',
    }

    const fileName = `Digitisation-Report-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/tokenized/stream`, { headers, params })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT })
        exportCSVFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const getExcelTokenization =
  (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID } = getState().tag
    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const fileName = `Tokenization-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/tokenized/stream`, {
        responseType: 'blob',
        headers,
        params,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT })
        exportExcelFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
