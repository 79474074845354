const { appConfig = {} } = window

export const features = {
  newOrdersScreen: 'REACT_APP_FEATURE_NEW_ORDERS_SCREEN',
  newLoadCardScreen: 'REACT_APP_FEATURE_NEW_LOAD_CARD_SCREEN',
}

function useAppConfig() {
  function isFeatureEnabled(name) {
    return appConfig[name] === 'true'
  }

  return { isFeatureEnabled }
}

export default useAppConfig
