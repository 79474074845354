export const PREFIX = '@commonActions/'

export const LOADING_CSV_REPORT = `${PREFIX}LOADING_CSV_REPORT`

export const LOADING_EXCEL_REPORT = `${PREFIX}LOADING_EXCEL_REPORT`

export const SUCCESS_EXCEL_REPORT = `${PREFIX}SUCCESS_EXCEL_REPORT`
export const ERROR_EXCEL_REPORT = `${PREFIX}ERROR_EXCEL_REPORT`
export const SUCCESS_CSV_REPORT = `${PREFIX}SUCCESS_CSV_REPORT`
export const ERROR_CSV_REPORT = `${PREFIX}ERROR_CSV_REPORT`
export const RESET_CSV_REPORT = `${PREFIX}RESET_CSV_REPORT`
export const RESET_EXCEL_REPORT = `${PREFIX}RESET_EXCEL_REPORT`

export const VALIDATION_ERRORS = `${PREFIX}VALIDATION_ERRORS`

export const RESET_VALIDATION_ERRORS = `${PREFIX}RESET_VALIDATION_ERRORS`

export const APP_COUNT = `${PREFIX}APP_COUNT`
export const RESET_APP_COUNT = `${PREFIX}RESET_APP_COUNT`

export const GET_USE_API_COUNT = `${PREFIX}GET_USE_API_COUNT`
export const GET_DESK_API_COUNT = `${PREFIX}GET_DESK_API_COUNT`
export const GET_CHANNEL_API_COUNT = `${PREFIX}GET_CHANNEL_API_COUNT`
export const GET_SHOP_LIST_API_COUNT = `${PREFIX}GET_SHOP_LIST_API_COUNT`
export const GET_SHOPS_API_COUNT = `${PREFIX}GET_SHOPS_API_COUNT`
