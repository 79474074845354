import React from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import CloseIcon from '../../../../assets/svg/CloseIcon'
import {
  blockCardByToken,
  unblockCardByToken,
} from '../../../../actions/CardInformationActions'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import { GF_DARK_BLUE } from '../../../../utils/Constants'
import SuccessAlert from '../../../Alert/SuccessAlert'
import FailAlert from '../../../Alert/FailAlert'
import ValidationErrors from '../../../Alert/ValidationErrors'

ReactModal.setAppElement('#root')

export default function BlockUnblockCards({
  modalVisibility,
  closeHandler,
  cardObject,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm({
    shouldValidate: true,
    shouldDirty: true,
  })
  const { loadingBlockUnblock, successBlockUnblock, failBlockUnblock } =
    useSelector((state) => state.cardInformation)
  const { validationErrors } = useSelector((state) => state.commonReducer)

  const onSubmit = (data) => {
    if (cardObject.status === 'blocked') {
      dispatch(unblockCardByToken(cardObject.token, data.cardBlockUnblock))
    } else {
      dispatch(blockCardByToken(cardObject.token, data.cardBlockUnblock))
    }
  }

  return (
    <ReactModal
      isOpen={modalVisibility}
      contentLabel="blockUnblockCards"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border rounded max-w-lg shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="flex justify-between items-center mb-5">
          <div className="font-MulishBold">
            {cardObject.status === 'blocked'
              ? `${t('unblock-card')} - ${cardObject.token}`
              : `${t('block-card')} - ${cardObject.token}`}
          </div>
          <button
            className="focus:outline-none"
            onClick={() => {
              closeHandler(!modalVisibility)
            }}
          >
            <CloseIcon />
          </button>
        </div>
        {!successBlockUnblock && (
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-5">
              <div className=" py-2 text-left ">
                {cardObject.status === 'blocked'
                  ? t('reason-unblock-card')
                  : t('reason-block-card')}
              </div>
              <div className="flex flex-col">
                <textarea
                  name="cardBlockUnblock"
                  ref={register({ required: true })}
                  disabled={loadingBlockUnblock}
                  placeholder={t('Your-remark-here')}
                  className="border placeholder-gfGrey text-base rounded p-4 border-gfLightBlue w-full focus:outline-none focus:shadow-outline"
                ></textarea>
                {errors.cardBlockUnblock &&
                  errors.cardBlockUnblock.type === 'required' && (
                    <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                  )}
              </div>
            </div>

            <div className="flex justify-center w-full pt-2">
              <button
                type="submit"
                disabled={loadingBlockUnblock}
                className="modal-close flex justify-center items-center  w-full bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue py-2 font-MulishBold px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {t('Confirm')}
                {loadingBlockUnblock && <ButtonLoading color={GF_DARK_BLUE} />}
              </button>
            </div>
          </form>
        )}

        {successBlockUnblock && (
          <SuccessAlert
            message={
              cardObject.status === 'blocked'
                ? t('The-card-was-successfully-unblocked')
                : t('The-card-was-successfully-blocked')
            }
          />
        )}
        {failBlockUnblock && (
          <div className="py-2">
            {' '}
            <FailAlert message={failBlockUnblock.message} />
          </div>
        )}

        {validationErrors && (
          <div className="py-2">
            <ValidationErrors {...validationErrors} />
          </div>
        )}
      </div>
    </ReactModal>
  )
}
