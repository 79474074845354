import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '../../../assets/svg/CloseIcon'
import ButtonLoading from '../../../assets/svg/ButtonLoading'
import { GF_DARK_BLUE } from '../../../utils/Constants'
import {
  ERROR_CHANGING_ORDER_STATUS,
  RESET_CHANGING_ORDER_STATUS,
  CHANGED_ORDER_STATUS,
  CHANGING_ORDER_STATUS,
} from '../../../actions/ManageOrdersActions'
import FailAlert from '../../Alert/FailAlert'
import ValidationErrors from '../../Alert/ValidationErrors'
import OrderStatus from '../../../enums/orderStatus.enum'
import SuccessAlert from '../../Alert/SuccessAlert'
import DefaultModal from '../../Modal/DefaultModal'
import { issuingAPI } from '../../../services/Axios'
import { generateOperationID } from '../../../utils/Helper'

export default function ConfirmationDeclineOrder({
  isOpen,
  closeHandler,
  globalRef,
  onSuccess,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { changingOrderStatus, changedOrderStatus, errorChangingOrder } =
    useSelector((state) => state.manageOrders)
  const { validationErrors } = useSelector((state) => state.commonReducer)
  const { tagID } = useSelector((state) => state.tag)

  const changeStatusHandler = () => {
    dispatch({ type: CHANGING_ORDER_STATUS })

    const params = {
      status: OrderStatus.DECLINE,
    }

    const headers = {
      'operation-id': generateOperationID(),
    }

    issuingAPI
      .put(
        `tag/${tagID}/global-order/${globalRef}/status`,
        { ...params },
        { headers },
      )
      .then((success) => {
        dispatch({ type: CHANGED_ORDER_STATUS, payload: success.data })
        setTimeout(() => {
          onSuccess()
          closeHandler()
        }, 2000)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CHANGING_ORDER_STATUS, payload: error })
      })
  }

  return (
    <DefaultModal isOpen={isOpen} onClose={closeHandler}>
      <div className="font-MulishBold">
        <div className="flex justify-between items-center mb-5 ">
          <div className="font-MulishBold uppercase">
            {`${t('Order-information')} - ${globalRef}`}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="mb-10">
            {t('manage-orders.decline-confirm-message')}
          </div>

          <div className="flex flex-row gap-5 justify-end items-end">
            <button
              onClick={() => {
                closeHandler(!isOpen)
                dispatch({ type: RESET_CHANGING_ORDER_STATUS })
              }}
              className="py-2 px-8 rounded bg-gfPeriwinkle hover:bg-opacity-50"
            >
              {t('Cancel')}
            </button>
            <button
              onClick={changeStatusHandler}
              disabled={
                !!errorChangingOrder ||
                changingOrderStatus ||
                changedOrderStatus === ''
              }
              className={`py-2 px-4 rounded bg-gfCoral hover:bg-opacity-50 flex flex-row disabled:opacity-50`}
            >
              {changingOrderStatus && <ButtonLoading color={GF_DARK_BLUE} />}
              {t('Confirm')}
            </button>
          </div>
        </div>

        <div className="my-4">
          {errorChangingOrder && (
            <FailAlert message={errorChangingOrder.message} />
          )}
          {validationErrors && <ValidationErrors {...validationErrors} />}
          {changedOrderStatus === '' && <SuccessAlert message={t('Success')} />}
        </div>
      </div>
    </DefaultModal>
  )
}
