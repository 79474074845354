import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_SELECTED_MERCHANT } from '../../../../actions/SalesByMerchantActions'

export default function UnSelectedMerchantList() {
  const { filteredMerchant } = useSelector((state) => state.salesByMerchant)
  const dispatch = useDispatch()

  const toggle = (item) => {
    dispatch({ type: SET_SELECTED_MERCHANT, payload: item })
  }
  return (
    <div className="flex flex-col space-y-1">
      {filteredMerchant.map((item, index) => (
        <div
          key={index}
          className={`flex flex-row p-2 items-center rounded`}
          style={{ backgroundColor: item.color }}
        >
          <input
            readOnly
            type="checkbox"
            checked={item.checked || false}
            onClick={() => toggle(item)}
          />
          <label className="ml-4 font-medium text-xs text-white">
            {item.name}
          </label>
        </div>
      ))}
    </div>
  )
}
