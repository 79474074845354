import {
  ERROR_BLOCKED_CARD_REPORT,
  LOADING_BLOCKED_CARD_REPORT,
  POPULATED_BLOCKED_CARD_REPORT,
  RECEIVED_BLOCKED_CARD_REPORT,
} from '../actions/BlockedCardsReportActions'
import { paginate } from '../utils/Helper'

/* eslint-disable import/no-anonymous-default-export */

const PAGINATE_FIRST_PAGE = 1

const initialState = {
  loadingBlockedCards: false,
  receivedBlockedCards: null,
  errorBlockedCards: null,

  populatedBlockedCards: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BLOCKED_CARD_REPORT:
      return {
        ...state,
        loadingBlockedCards: true,
        receivedBlockedCards: null,
        errorBlockedCards: null,
        populatedBlockedCards: null,
      }

    case RECEIVED_BLOCKED_CARD_REPORT:
      return {
        ...state,
        loadingBlockedCards: false,
        receivedBlockedCards: action.payload.stock.blocked_cards.cards,
        errorBlockedCards: null,
        populatedBlockedCards: paginate(
          action.payload.stock.blocked_cards.cards,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case POPULATED_BLOCKED_CARD_REPORT:
      return {
        ...state,
        populatedBlockedCards: paginate(
          state.receivedBlockedCards,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case ERROR_BLOCKED_CARD_REPORT:
      return {
        ...state,
        loadingBlockedCards: false,
        receivedBlockedCards: null,
        errorBlockedCards: action.payload,
        populatedBlockedCards: null,
      }
    default:
      return state
  }
}
