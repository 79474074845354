import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile } from '../utils/Helper'
import { APP_COUNT } from './CommonActions'

export const PREFIX = '@weeklyBreakdown/'

export const LOADING_WEEKLY_SALES = `${PREFIX}LOADING_WEEKLY_SALES`
export const RECEIVED_WEEKLY_SALES = `${PREFIX}RECEIVED_WEEKLY_SALES`
export const ERROR_WEEKLY_SALES = `${PREFIX}ERROR_WEEKLY_SALES`
export const RESET_WEEKLY_SALES = `${PREFIX}RESET_WEEKLY_SALES`

export const getWeeklySales = (chooseDate, endDate) => (dispatch, getState) => {
  dispatch({ type: LOADING_WEEKLY_SALES })

  dispatch({ type: APP_COUNT })

  const { tagID } = getState().tag

  const params = {
    from_date: chooseDate,
    to_date: endDate === null ? chooseDate : endDate,
  }

  return issuingAPI
    .get(`/tag/${tagID}/weekly-sale`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_WEEKLY_SALES, payload: success.data })
    })
    .catch((error) => {
      dispatch({ type: ERROR_WEEKLY_SALES, payload: error })
      return error
    })
}

export const LOADING_WEEKLY_USAGE = `${PREFIX}LOADING_WEEKLY_USAGE`
export const RECEIVED_WEEKLY_USAGE = `${PREFIX}RECEIVED_WEEKLY_USAGE`
export const ERROR_WEEKLY_USAGE = `${PREFIX}ERROR_WEEKLY_USAGE`
export const RESET_WEEKLY_USAGE = `${PREFIX}RESET_WEEKLY_USAGE`

export const getWeeklyUsage = (chooseDate, endDate) => (dispatch, getState) => {
  dispatch({ type: LOADING_WEEKLY_USAGE })

  const { tagID } = getState().tag

  const params = {
    from_date: chooseDate,
    to_date: endDate === null ? chooseDate : endDate,
  }

  return issuingAPI
    .get(`/tag/${tagID}/weekly-use`, { params })
    .then((success) => {
      console.log(success)
      dispatch({ type: RECEIVED_WEEKLY_USAGE, payload: success.data })
    })
    .catch((error) => {
      dispatch({ type: ERROR_WEEKLY_USAGE, payload: error })
      return error
    })
}

export const LOADING_CSV_REPORT = `${PREFIX}LOADING_CSV_REPORT`
export const LOADING_EXCEL_REPORT = `${PREFIX}LOADING_EXCEL_REPORT`
export const RECEIVED_CSV_REPORT = `${PREFIX}RECEIVED_CSV_REPORT`
export const RECEIVED_EXCEL_REPORT = `${PREFIX}RECEIVED_EXCEL_REPORT`
export const ERROR_CSV_REPORT = `${PREFIX}ERROR_CSV_REPORT`
export const ERROR_EXCEL_REPORT = `${PREFIX}ERROR_EXCEL_REPORT`
export const RESET_CSV_REPORT = `${PREFIX}RESET_CSV_REPORT`

// https://portalapi.dev.giftify.me/tag/{tagId}/weekly-sale/stream

export const getCSVReoprtWeeklySales =
  (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { selectedTag } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    const headers = {
      Accept: 'text/csv',
    }

    const reportName = 'weekly-sales'

    const fileName = `${reportName}-${fromDate}-${toDate}`

    issuingAPI
      .get(`tag/${selectedTag.id}/weekly-sale/stream`, { headers, params })
      .then((response) => {
        dispatch({ type: RECEIVED_CSV_REPORT, payload: response.data })
        exportCSVFile(response.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const getExcelReportWeeklySales =
  (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { selectedTag } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const reportName = 'weekly-sales'

    const fileName = `${reportName}-${fromDate}-${toDate}`

    issuingAPI
      .get(`tag/${selectedTag.id}/weekly-sale/stream`, {
        responseType: 'blob',
        headers,
        params,
      })
      .then((response) => {
        dispatch({ type: RECEIVED_EXCEL_REPORT, payload: response.data })
        exportExcelFile(response.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }

// https://portalapi.dev.giftify.me/tag/{tagId}/weekly-use/stream

export const getCSVReoprtWeeklyUsage =
  (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { selectedTag } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    const headers = {
      Accept: 'text/csv',
    }

    const reportName = 'weekly-usage'

    const fileName = `${reportName}-${fromDate}-${toDate}`

    issuingAPI
      .get(`tag/${selectedTag.id}/weekly-use/stream`, { headers, params })
      .then((response) => {
        dispatch({ type: RECEIVED_CSV_REPORT, payload: response.data })
        exportCSVFile(response.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const getExcelReportWeeklyUsage =
  (fromDate, toDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { selectedTag } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const reportName = 'weekly-usage'

    const fileName = `${reportName}-${fromDate}-${toDate}`

    issuingAPI
      .get(`tag/${selectedTag.id}/weekly-use/stream`, {
        responseType: 'blob',
        headers,
        params,
      })
      .then((response) => {
        dispatch({ type: RECEIVED_EXCEL_REPORT, payload: response.data })
        exportExcelFile(response.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
