import { issuingAPI } from '../services/Axios'
import { exportCSVFile } from '../utils/Helper'
import { getNotificationList } from './NotificationActions'

export const PREFIX = '@cardOrder/'

// GET CARD ORDER LIST
export const FETCH_CARD_ORDERS = `${PREFIX}FETCH_CARD_ORDERS`
export const FETCH_CARD_ORDERS_SUCCESS = `${PREFIX}FETCH_CARD_ORDERS_SUCCESS`
export const FETCH_CARD_ORDERS_ERROR = `${PREFIX}FETCH_CARD_ORDERS_ERROR`

// CREATE CARD ORDER
export const CREATE_CARD_ORDER = `${PREFIX}CREATE_CARD_ORDER`
export const SUCCESS_CREATE_CARD_ORDER = `${PREFIX}SUCCESS_CREATE_CARD_ORDER`
export const FAIL_CREATE_CARD_ORDER = `${PREFIX}FAIL_CREATE_CARD_ORDER`
export const RESET_ORDER_HISTORY = `${PREFIX}RESET_ORDER_HISTORY`

// CANCEL CARD ORDER
export const CANCEL_CARD_ORDER = `${PREFIX}CANCEL_CARD_ORDER`
export const SUCCESS_CANCEL_CARD_ORDER = `${PREFIX}SUCCESS_CANCEL_CARD_ORDER`
export const FAIL_CANCEL_CARD_ORDER = `${PREFIX}FAIL_CANCEL_CARD_ORDER`

export const fetchCardOrders =
  (fromDate, toDate, page, query) => (dispatch, getState) => {
    dispatch({ type: FETCH_CARD_ORDERS })

    const { tagID } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
      page,
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    }

    if (query) {
      Object.assign(params, { query })
    }

    return issuingAPI
      .get(`/tag/${tagID}/promo-card-order`, { params })
      .then((success) => {
        dispatch({ type: FETCH_CARD_ORDERS_SUCCESS, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: FETCH_CARD_ORDERS_ERROR, payload: error })
        return error
      })
  }

export const CARD_ORDER_OPERATION_ID = `${PREFIX}CARD_ORDER_OPERATION_ID`

export const createCardOrder =
  (orders, deliveryAddressID, remarks, layoutID) => (dispatch, getState) => {
    dispatch({ type: CREATE_CARD_ORDER })

    const { cardOrderOperationID } = getState().cardOrders
    const { tagID } = getState().tag

    const headers = {
      'operation-id': cardOrderOperationID,
    }

    return issuingAPI
      .post(
        `/tag/${tagID}/promo-card-order`,
        {
          orders,
          remarks,
          delivery_address_id: deliveryAddressID,
          layout_id: layoutID,
        },
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_CREATE_CARD_ORDER, payload: success.data })
        dispatch(getNotificationList())

        // setTimeout(window.location.reload(), 3000);
        return success
      })
      .catch((error) => {
        dispatch({ type: FAIL_CREATE_CARD_ORDER, payload: error })
        return error
      })
  }

export const cancelOrder = (reference) => (dispatch, getState) => {
  dispatch({ type: CANCEL_CARD_ORDER })

  const { tagID } = getState().tag

  const data = {
    status: 'cancel',
  }

  return issuingAPI
    .patch(`/tag/${tagID}/promo-card-order/${reference}/status`, { ...data })
    .then((success) => {
      dispatch({ type: SUCCESS_CANCEL_CARD_ORDER, payload: success.data })
      return success
    })
    .catch((error) => {
      dispatch({ type: FAIL_CANCEL_CARD_ORDER, payload: error })
      return error
    })
}

// ORDER DETALS
export const LOADING_ORDER_DETAILS = `${PREFIX}LOADING_ORDER_DETAILS`
export const RECEIVED_ORDER_DETAILS = `${PREFIX}RECEIVED_ORDER_DETAILS`
export const ERROR_ORDER_DETAILS = `${PREFIX}ERROR_ORDER_DETAILS`
export const RESET_ORDER_DETAILS = `${PREFIX}RESET_ORDER_DETAILS`

// https://portalapi.dev.giftify.me/tag/{tagId}/promo-card-order-details/{orderRef}
export const getOrderDetails = (reference) => (dispatch, getState) => {
  dispatch({ type: LOADING_ORDER_DETAILS })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/promo-card-order-details/${reference}`)
    .then((success) => {
      dispatch({ type: RECEIVED_ORDER_DETAILS, payload: success.data })
      return success
    })
    .catch((error) => {
      dispatch({ type: ERROR_ORDER_DETAILS, payload: error })
      return error
    })
}

export const LOADING_CSV_REPORT = `${PREFIX}LOADING_CSV_REPORT`
export const RECEIVED_CSV_REPORT = `${PREFIX}RECEIVED_CSV_REPORT`
export const ERROR_CSV_REPORT = `${PREFIX}ERROR_CSV_REPORT`
export const RESET_CSV_REPORT = `${PREFIX}RESET_CSV_REPORT`

// https://portalapi.dev.giftify.me/tag/{tagId}/promo-card-order/{orderRef}/stream
export const getOrderViewCSVExport = (orderRef) => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT })

  const { selectedTag } = getState().tag

  const headers = {
    Accept: 'text/csv',
  }

  const reportName = 'order-view-details'

  const fileName = `${reportName} - ${orderRef}`

  issuingAPI
    .get(`tag/${selectedTag.id}/promo-card-order-details/${orderRef}/stream`, {
      headers,
    })
    .then((response) => {
      dispatch({ type: RECEIVED_CSV_REPORT, payload: response.data })
      exportCSVFile(response.data, fileName)
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error })
      return error
    })
}

export const LOADING_PROMO_CARDS_LAYOUT = `${PREFIX}LOADING_PROMO_CARDS_LAYOUT`
export const RECEIVED_PROMO_CARDS_LAYOUT = `${PREFIX}RECEIVED_PROMO_CARDS_LAYOUT`
export const ERROR_PROMO_CARDS_LAYOUT = `${PREFIX}ERROR_PROMO_CARDS_LAYOUT`

// https://portalapi.dev.giftify.me/tag/{tagId}/promo-card/layouts
export const getPromoCardsLayouts = () => (dispatch, getState) => {
  dispatch({ type: LOADING_PROMO_CARDS_LAYOUT })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/promo-card/layouts`)
    .then((success) => {
      dispatch({
        type: RECEIVED_PROMO_CARDS_LAYOUT,
        payload: success.data.data,
      })
      return success
    })
    .catch((error) => {
      dispatch({ type: ERROR_PROMO_CARDS_LAYOUT, payload: error })
      return error
    })
}
