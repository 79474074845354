import React, { memo, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

export default function CustomScriptComponent() {
  const { selectedLanguage } = useSelector((state) => state.language)

  // just some examples of customizing the literal script definition
  const labelName = 'dataLayer'
  const gtmId = 'ID' // your GTM id

  const scriptToInject = `
        <script>
        (function(){
            StonlyWidget('setWidgetLanguage', '${selectedLanguage.value}')
        })(window,document,'script','${labelName}','${gtmId}');      
        </script>`

  const InjectScript = memo(({ script }) => {
    const divRef = useRef(null)

    useEffect(() => {
      if (divRef.current === null) {
        return
      }
      // create a contextual fragment that will execute the script
      // beware of security concerns!!
      const doc = document.createRange().createContextualFragment(script)

      // clear the div HTML, and append the doc fragment with the script
      divRef.current.innerHTML = ''
      divRef.current.appendChild(doc)
    })

    return <div ref={divRef} />
  })

  return <div>{<InjectScript script={scriptToInject} />}</div>
}
