/* eslint-disable import/no-anonymous-default-export */

import {
  ERROR_TRANSACTION_OVERVIEW,
  LOADING_TRANSACTION_OVERVIEW,
  PAGINATE_TRANSACTION_OVERVIEW,
  RECEIVED_TRANSACTION_OVERVIEW,
  RESET_TRANSACTION_OVERVIEW,
} from '../actions/TransactionOverviewActions'

const initialState = {
  loadingTransactionOverview: false,
  receivedTransactionOverview: null,
  errorTransactionOverView: null,
  metaTransactionOverview: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TRANSACTION_OVERVIEW:
      return {
        ...state,
        loadingTransactionOverview: true,
        receivedTransactionOverview: null,
        errorTransactionOverView: null,
        metaTransactionOverview: null,
      }

    case RECEIVED_TRANSACTION_OVERVIEW:
      return {
        ...state,
        loadingTransactionOverview: false,
        receivedTransactionOverview: action.payload.data,
        errorTransactionOverView: null,
        metaTransactionOverview: action.payload.meta,
      }

    case ERROR_TRANSACTION_OVERVIEW:
      return {
        ...state,
        loadingTransactionOverview: false,
        receivedTransactionOverview: null,
        errorTransactionOverView: action.payload,
        metaTransactionOverview: null,
      }

    case PAGINATE_TRANSACTION_OVERVIEW:
      return {
        ...state,
        loadingTransactionOverview: true,
      }

    case RESET_TRANSACTION_OVERVIEW:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}
