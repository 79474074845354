/* eslint-disable react-hooks/exhaustive-deps */
import format from 'date-fns/format'
import React, { forwardRef, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Userpilot } from 'userpilot'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import FailAlert from '../../../Alert/FailAlert'
import MessageAlert from '../../../Alert/MessageAlert'
import CardInfoWidget from '../../../CardInfoWidget'
import Container from '../../../UIComponents/Container'
import { GF_DARK_BLUE } from '../../../../utils/Constants'
import Table from '../../../UIComponents/Table'
import { getCalanderLocale } from '../../../../utils/CalanderLocale'
import {
  getCSVTokenization,
  getTokenizationReport,
  RESET_DIGITISATION_REPORT,
} from '../../../../actions/DigitisationActions'

export default function DigitisationReport() {
  const {
    loadingDigitisationReport,
    receivingDigitisationReport,
    errorDigitisationReport,
    digitisationReportSummary,
    devicesList,
  } = useSelector((state) => state.tokenizationReport)

  const { selectedLanguage } = useSelector((state) => state.language)
  const { loadingCSV } = useSelector((state) => state.commonReducer)

  useEffect(() => {
    Userpilot.reload()
    dispatch({ type: RESET_DIGITISATION_REPORT })

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_DIGITISATION_REPORT })
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_DIGITISATION_REPORT })
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  getCalanderLocale(selectedLanguage)

  const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
    <input
      id={id}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border cursor-pointer text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
    />
  ))

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [chooseDate, setChooseDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [page, setPage] = useState(1)

  const fromDatePickHandler = (date) => {
    setChooseDate(date)
  }

  const toDatePickHandler = (date) => {
    setEndDate(date)
  }

  const getChartHandler = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(getTokenizationReport(from, to, page))
  }

  const handlePageClick = (event) => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(getTokenizationReport(from, to, event.selected + 1))
  }

  const exportCSV = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(getCSVTokenization(from, to))
  }

  return (
    <Container title={t('digitisation-report')}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-5 items-end">
        <div className="flex flex-col">
          <div className="flex items-center   py-1">
            {t('Choose-the-date')}:
          </div>{' '}
          <ReactDatePicker
            id="fromDateInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingDigitisationReport}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={chooseDate}
            onChange={(date) => fromDatePickHandler(date)}
            locale={selectedLanguage.value}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center   py-1">
            {`${t('End-date')}(${t('Optional')})`}
          </div>
          <ReactDatePicker
            id="toDateInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingDigitisationReport}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => toDatePickHandler(date)}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
          />
        </div>

        <button
          id="goButton"
          disabled={loadingDigitisationReport}
          onClick={getChartHandler}
          className="h-10 py-2 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
        >
          {loadingDigitisationReport && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Go')}!
        </button>
      </div>

      {((receivingDigitisationReport &&
        receivingDigitisationReport.length === 0) ||
        errorDigitisationReport) && (
        <div className="mt-10">
          {receivingDigitisationReport &&
            receivingDigitisationReport.length === 0 && (
              <MessageAlert
                message={t('There-are-no-information-to-display-for-this-date')}
              />
            )}
          {errorDigitisationReport && (
            <FailAlert message={errorDigitisationReport.message} />
          )}
        </div>
      )}

      {receivingDigitisationReport &&
        receivingDigitisationReport.length !== 0 && (
          <div className="flex flex-col mt-20 space-y-5">
            <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
              <CardInfoWidget
                bottomLabel={t('digitised-cards')}
                isCurrency={false}
                value={digitisationReportSummary.total_entries}
                labelColor={'text-gfDarkBlue'}
                valueColor={'text-gfDarkBlue'}
              />
              <CardInfoWidget
                bottomLabel={t('digitised')}
                isCurrency
                value={digitisationReportSummary.total_fees}
                labelColor={'text-gfDarkBlue'}
                valueColor={'text-gfDarkBlue'}
              />
              {devicesList && devicesList.length !== 0 && (
                <div className="flex flex-col rounded border-b-4 px-4 py-2 bg-kadozLightGray border border-gfPeriwinkle">
                  {devicesList.map((entry, index) => (
                    <div key={index} className="flex flex-row">
                      <div className="font-MulishBold capitalize text-lg">
                        {entry.device === 'apple'
                          ? t('apple-wallet')
                          : t('google-wallet')}
                      </div>
                      :
                      <div className=" ml-2 font-MulishBlack capitalize text-xl">
                        {entry.count}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
              {receivingDigitisationReport.length >
                window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                <div>
                  <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={
                      receivingDigitisationReport.length /
                      window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                    }
                    marginPagesDisplayed={4}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-row justify-end items-center">
              <button
                id="exportCsvButton"
                onClick={exportCSV}
                className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-CSV-file')}
              </button>
            </div>

            <Table>
              <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                <tr>
                  <th
                    scope="col"
                    className="3/12 px-4 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('digitisation-date')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('physical-token')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('digital-token')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('amount-digitised')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('wallet-provisionning-date')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('mobile-wallet')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gfPeriwinkle">
                {receivingDigitisationReport &&
                  receivingDigitisationReport.length !== 0 &&
                  receivingDigitisationReport.map((tr) => (
                    <tr key={tr.ticket_number}>
                      <td className="px-4 py-4">
                        <div className="flex justify-center items-center">
                          {tr.transaction_date_time}
                        </div>
                      </td>

                      <td className="px-2 py-4">
                        {tr.entries.map((en) => {
                          if (en.is_unload)
                            return (
                              <div
                                key={en.token}
                                className="flex justify-center items-center space-x-2"
                              >
                                <Link
                                  className="underline"
                                  to={`/gift-cards/card-information?token=${en.token}`}
                                >
                                  {en.token}
                                </Link>
                              </div>
                            )
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {tr.entries.map((en) => {
                          if (!en.is_unload)
                            return (
                              <div
                                key={en.token}
                                className="flex justify-center items-center space-x-2"
                              >
                                <Link
                                  className="underline"
                                  to={`/gift-cards/card-information?token=${en.token}`}
                                >
                                  {en.token}
                                </Link>
                              </div>
                            )
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {tr.entries.map((en) => {
                          if (!en.is_unload)
                            return (
                              <div
                                key={en.token}
                                className="flex justify-center items-center space-x-2"
                              >
                                <div className="flex flex-row font-MulishBold  text-gfLightBlue ">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={en.amount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-1 ">{`${String(tr.currency).toUpperCase()}`}</span>
                                </div>
                              </div>
                            )
                        })}
                      </td>

                      <td className="px-4 py-4">
                        {tr.provision.date ? (
                          <div className="flex justify-center items-center">
                            {tr.provision.date}
                          </div>
                        ) : (
                          <div className="flex justify-center items-center">
                            -
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-4">
                        {tr.provision.wallet &&
                        tr.provision.wallet === 'ANDROID' ? (
                          <div className=" uppercase flex justify-center items-center">
                            {t('google')}
                          </div>
                        ) : tr.provision.wallet &&
                          tr.provision.wallet === 'APPLE' ? (
                          <div className="uppercase flex justify-center items-center">
                            {t('apple')}
                          </div>
                        ) : (
                          <div className="flex justify-center items-center">
                            -
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
    </Container>
  )
}
