import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import TokenField from '../../../Input/TokenField'
import { Form, FormControl, FormField, FormItem } from '../../../Form'
import { InputGroup, InputGroupText } from '../../../InputGroup'
import TextField from '../../../Input/TextField'
import useTokenValidity from '../../../../hooks/useTokenValidity'

export default function TokenForm({
  onAdd,
  tokens,
  onSetReset,
  onRequestingTokenRange = () => {},
}) {
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      fromToken: '',
      toToken: '',
      amount: '',
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValid, touched },
  } = methods
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag)
  const { validateToken, getTokenRange } = useTokenValidity({
    tokens,
  })

  useEffect(() => {
    if (typeof reset === 'function') {
      onSetReset(reset)
    }
  }, [])

  async function onAddCard(data) {
    const amount = parseFloat(data.amount).toFixed(2)

    onRequestingTokenRange()

    if (data.fromToken && data.toToken && data.fromToken !== data.toToken) {
      const tokenRange = await getTokenRange(data.fromToken, data.toToken)
      const validTokens = tokenRange.filter((token) => token.valid)
      const invalidTokens = tokenRange.filter((token) => !token.valid)

      onAdd(
        validTokens.map((token) => ({ token: token.token, amount })),
        invalidTokens,
      )
      reset()

      return
    }

    onAdd(
      [
        {
          token: data.fromToken,
          amount,
        },
      ],
      [],
    )
    reset()
  }

  return (
    <Form {...methods}>
      <div className="flex flex-col">
        <div className="flex flex-row gap-2 justify-start items-center">
          <FormField
            name="fromToken"
            rules={{
              required: t('Required'),
              validate: async (value) => validateToken(value),
            }}
            render={(field, fieldState) => (
              <FormItem className="w-32">
                <FormControl>
                  <TokenField
                    {...field}
                    invalid={fieldState.invalid}
                    valid={touched.fromToken && !fieldState.invalid}
                    placeholder={t('TOKEN')}
                    name="fromToken"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <span className={'text-sm'}>{t('to')}</span>
          <FormField
            name="toToken"
            rules={{
              validate: async (value) => {
                if (value === '') {
                  return true
                }

                return validateToken(value)
              },
            }}
            render={(field, fieldState) => (
              <FormItem className="w-32">
                <FormControl>
                  <TokenField
                    {...field}
                    invalid={fieldState.invalid}
                    valid={touched.fromToken && !fieldState.invalid}
                    placeholder={t('TOKEN')}
                    name="toToken"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <span className={'truncate text-sm'}>{t('with amount')}</span>
          <FormField
            name="amount"
            rules={{
              required: t('Required'),
              min: {
                value: selectedTag.min_load,
                message: t(
                  'Cards-are-loadable-between-Please-select-another-amount-and-try-again',
                  {
                    min: selectedTag.min_load,
                    max: selectedTag.max_load,
                    currency: tagCurrency,
                  },
                ),
              },
              max: {
                value: selectedTag.max_load,
                message: t(
                  'Cards-are-loadable-between-Please-select-another-amount-and-try-again',
                  {
                    min: selectedTag.min_load,
                    max: selectedTag.max_load,
                    currency: tagCurrency,
                  },
                ),
              },
            }}
            render={(field) => (
              <FormItem className="w-32">
                <InputGroup>
                  <FormControl>
                    <TextField
                      placeholder={`100`}
                      {...field}
                      className="pr-12 text-right"
                      type="number"
                    />
                  </FormControl>
                  <InputGroupText position="right">
                    {tagCurrency}
                  </InputGroupText>
                </InputGroup>
              </FormItem>
            )}
          />
          <div>
            <button
              id="add-card"
              onClick={handleSubmit(onAddCard)}
              disabled={isSubmitting || !isValid}
              className="bg-gfPeriwinkle border border-gfPeriwinkle font-MulishBold text-gfDarkBlue rounded text-sm py-2 px-3 focus:outline-none disabled:opacity-50"
            >
              {t('Add-a-card')}
            </button>
          </div>
        </div>
        <div className={'text-gfCoral'}>
          <div>{errors.fromToken?.message ?? ''}</div>
          <div>{errors.toToken?.message ?? ''}</div>
          <div>{errors.amount?.message ?? ''}</div>
        </div>
      </div>
    </Form>
  )
}
