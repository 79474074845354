import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile } from '../utils/Helper'
import {
  APP_COUNT,
  ERROR_CSV_REPORT,
  ERROR_EXCEL_REPORT,
  GET_CHANNEL_API_COUNT,
  GET_USE_API_COUNT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
} from './CommonActions'
import {
  CONFIRMING_PAN,
  GET_TICKET_EMAIL_HISTORY_LOADING,
  getTicketEmailHistory,
  PAN_CONFIRM_ERROR,
  PAN_CONFIRMED,
  TICKET_EMAIL_HISTORY_RECEIVE_ERROR,
  TICKET_EMAIL_HISTORY_RECEIVED,
  EMAIL_SENT_ERROR,
  EMAIL_SENT_SUCCESS,
  SENDING_TICKET_BY_EMAIL,
} from './DailyReportActions'

export const PREFIX = '@reportByChannel/'

export const LOADING_CHANNEL_LIST = `${PREFIX}LOADING_CHANNEL_LIST`
export const RECEIVED_CHANNEL_LIST = `${PREFIX}RECEIVED_CHANNEL_LIST`
export const ERROR_CHANNEL_LIST = `${PREFIX}ERROR_CHANNEL_LIST`

export const LOADING_REPORT_BY_CHANNEL = `${PREFIX}LOADING_REPORT_BY_CHANNEL`
export const RECEIVED_REPORT_BY_CHANNEL = `${PREFIX}RECEIVED_REPORT_BY_CHANNEL`
export const ERROR_REPORT_BY_CHANNEL = `${PREFIX}ERROR_REPORT_BY_CHANNEL`
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`
export const RESET_REPORT_BY_CHANNELS = `${PREFIX}RESET_REPORT_BY_CHANNELS`

export const LOADING_USER_LIST = `${PREFIX}LOADING_USER_LIST`
export const RECEIVED_USER_LIST = `${PREFIX}RECEIVED_USER_LIST`
export const ERROR_USER_LIST = `${PREFIX}ERROR_USER_LIST`

export const getChannelList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CHANNEL_LIST })

  const { tagCardBrands } = getState().tag

  dispatch({ type: GET_CHANNEL_API_COUNT })

  return issuingAPI
    .get(`card-brand/${tagCardBrands[0]}/shop`)
    .then((success) => {
      dispatch({ type: RECEIVED_CHANNEL_LIST, payload: success.data })
      return success
    })
    .catch((error) => {
      dispatch({ type: ERROR_CHANNEL_LIST, payload: error })
      return error
    })
}

export const getUserList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_USER_LIST })

  const { tagID } = getState().tag

  dispatch({ type: GET_USE_API_COUNT })

  return issuingAPI
    .get(`tag/${tagID}/user`)
    .then((success) => {
      dispatch({ type: RECEIVED_USER_LIST, payload: success.data })
      return success
    })
    .catch((error) => {
      dispatch({ type: ERROR_USER_LIST, payload: error })
      return error
    })
}

export const getReportByChannels =
  (fromDate, toDate, selectedChannelID, userIDs) => (dispatch, getState) => {
    dispatch({ type: LOADING_REPORT_BY_CHANNEL })

    const { tagID } = getState().tag

    dispatch({ type: APP_COUNT })

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    if (selectedChannelID !== -1) {
      Object.assign(params, { channel_ids: [selectedChannelID] })
    }

    if (userIDs !== -1) {
      Object.assign(params, { user_ids: [userIDs] })
    }

    return issuingAPI
      .get(`tag/${tagID}/channel-loads`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_REPORT_BY_CHANNEL, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_REPORT_BY_CHANNEL, payload: error })
        return error
      })
  }

export const confirmPanByToken = (pan, token) => (dispatch, getState) => {
  dispatch({ type: CONFIRMING_PAN })

  const { tagID } = getState().tag

  return issuingAPI
    .post(`/tag/${tagID}/card/${token}/verify-pan`, {
      pan,
    })

    .then((response) => {
      dispatch({ type: PAN_CONFIRMED, payload: response })
      dispatch({ type: GET_TICKET_EMAIL_HISTORY_LOADING })
      dispatch(getCardEmailHistory(tagID, token))
    })
    .catch((error) => {
      dispatch({ type: PAN_CONFIRM_ERROR, payload: error })
      return error
    })
}

export const getCardEmailHistory = (tagID, token) => (dispatch) => {
  return issuingAPI
    .get(`/tag/${tagID}/card/${token}/sent-via-email`)
    .then((response) => {
      dispatch({ type: TICKET_EMAIL_HISTORY_RECEIVED, payload: response })
    })
    .catch((error) => {
      dispatch({ type: TICKET_EMAIL_HISTORY_RECEIVE_ERROR, payload: error })
      return error
    })
}

export const sendCardByEmail =
  (email, token, selectedLanguage, ticketNumber) => (dispatch, getState) => {
    dispatch({ type: SENDING_TICKET_BY_EMAIL })

    const { tagID } = getState().tag

    return issuingAPI
      .post(`/tag/${tagID}/card/${token}/send-via-email`, {
        email,
        lang: selectedLanguage.value,
        ticket: ticketNumber,
      })

      .then((response) => {
        dispatch({ type: EMAIL_SENT_SUCCESS })
        document.body.dispatchEvent(
          new Event('clear_email_load_card_ticket', {
            bubbles: true,
            cancelable: true,
          }),
        )
        setTimeout(function () {
          dispatch({ type: GET_TICKET_EMAIL_HISTORY_LOADING })
          dispatch(getCardEmailHistory(tagID, token))
        }, 3000)
      })
      .catch((error) => {
        dispatch({ type: EMAIL_SENT_ERROR, payload: error })
        return error
      })
  }

export const exportCSVReportByChannels =
  (fromDate, toDate, selectedChannelID, userIDs, reportName) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { tagID } = getState().tag
    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    if (selectedChannelID !== -1) {
      Object.assign(params, { channel_ids: [selectedChannelID] })
    }

    if (userIDs !== -1) {
      Object.assign(params, { user_ids: [userIDs] })
    }

    const headers = {
      Accept: 'text/csv',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/channel-loads/stream`, { headers, params, data })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT })
        exportCSVFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const exportExcelReportByChannels =
  (fromDate, toDate, selectedChannelID, userIDs, reportName) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID } = getState().tag
    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    if (selectedChannelID !== -1) {
      Object.assign(params, { channel_ids: [selectedChannelID] })
    }

    if (userIDs !== -1) {
      Object.assign(params, { user_ids: [userIDs] })
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/channel-loads/stream`, {
        responseType: 'blob',
        headers,
        params,
        data,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT })
        exportExcelFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
