import React, { useRef, useState, useMemo } from 'react'
import DefaultModal from './DefaultModal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import {
  OPEN_TICKET_PRINT,
  sendTicketByEmail,
} from '../../actions/LoadCardActions'
import LoadCardReceipt from '../LoadCard/LoadCardReceipt'
import ButtonLoading from '../../assets/svg/ButtonLoading'
import { GF_DARK_BLUE } from '../../utils/Constants'
import FailAlert from '../Alert/FailAlert'
import SuccessAlert from '../Alert/SuccessAlert'
import { checkLoggedInUserPermissions } from '../../utils/Helper'

const isSendTicketViaEmailEnabled =
  window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL === 'true'

export default function LoadCardTicketModal({ isOpen, onClose }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedLanguage } = useSelector((state) => state.language)
  const {
    receivedTicketInfo,
    loadingTicketInfo,
    loadingLoadCards,
    errorLoadCards,
    sendingTicketViaEmail,
    emailSuccess,
    emailError,
  } = useSelector((state) => state.loadCard)
  const [email, setEmail] = useState('')
  const componentRef = useRef()
  const printTicket = useReactToPrint({
    content: () => componentRef.current,
  })

  const sendEmail = () => {
    dispatch(
      sendTicketByEmail(
        email,
        receivedTicketInfo.ticket_number,
        selectedLanguage,
      ),
    )
  }

  const reloadPage = () => {
    if (errorLoadCards) {
      onClose()
    } else {
      window.location.reload()
    }
  }

  window.addEventListener(OPEN_TICKET_PRINT, () => {
    printTicket()
  })

  const canSendReceiptByEmail = useMemo(() => {
    return (
      isSendTicketViaEmailEnabled &&
      checkLoggedInUserPermissions('send-ticket-via-email')
    )
  }, [isSendTicketViaEmailEnabled, checkLoggedInUserPermissions])

  return (
    <DefaultModal isOpen={isOpen} onClose={reloadPage}>
      <>
        <div className="flex justify-between items-center divide-y">
          <div className="font-MulishBold">{t('gift-card-load-receipt')}</div>
        </div>
        {loadingTicketInfo ||
          (loadingLoadCards && (
            <div className="mx-auto py-7">
              <div className="py-7 mx-auto text-center">
                <div className={'flex justify-center'}>
                  <ButtonLoading color={GF_DARK_BLUE} />
                </div>
                {loadingTicketInfo && (
                  <div
                    className={'text-center'}
                  >{`${t('Getting Ticket infos')}...`}</div>
                )}
                {loadingLoadCards && (
                  <div
                    className={'text-center'}
                  >{`${t('Cards are loading')}...`}</div>
                )}
              </div>
            </div>
          ))}
        {errorLoadCards && (
          <div className="py-4 space-y-5">
            {errorLoadCards && <FailAlert message={errorLoadCards.message} />}
          </div>
        )}
        {receivedTicketInfo && (
          <div className="py-4 space-y-5">
            {canSendReceiptByEmail && (
              <>
                <div className="flex flex-col justify-start">
                  <hr className="h-1 w-full my-2"></hr>
                  <div className="mb-1 text-left">
                    {t('Send the receipt by email')}
                  </div>
                  <div className="flex flex-row gap-5">
                    <input
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder={t('type-in-email')}
                      autoComplete="false"
                      className="h-10 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                    />
                    <button
                      onClick={sendEmail}
                      className={
                        (sendingTicketViaEmail || email === ''
                          ? 'opacity-50 pointer-events-none '
                          : '') +
                        'border rounded  py-2 px-8 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium'
                      }
                    >
                      {sendingTicketViaEmail && (
                        <ButtonLoading color={GF_DARK_BLUE} />
                      )}
                      {t(sendingTicketViaEmail ? 'Sending' : 'Send')}
                    </button>
                  </div>
                  <div className={'mt-2'}>
                    {emailSuccess && (
                      <SuccessAlert
                        title="Success"
                        message={t('Email-was-sent-successfully') + ' ' + email}
                      />
                    )}
                    {emailError && <FailAlert message={emailError.message} />}
                  </div>
                </div>
                <hr className="h-1 w-full my-8"></hr>
              </>
            )}

            <div className="flex flex-row justify-between space-x-5">
              <button
                onClick={reloadPage}
                className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
              >
                {t('Load-another-card')}
              </button>
              <button
                onClick={printTicket}
                className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
              >
                {t('Print')}
              </button>
            </div>
            <div className={'h-56 overflow-y-scroll'}>
              <LoadCardReceipt componentRef={componentRef} />
            </div>
          </div>
        )}
      </>
    </DefaultModal>
  )
}
