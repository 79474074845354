import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultModal from './DefaultModal'
import PrepareCards from '../../modules/card/components/PrepareCards'
import OrderDetails from '../../modules/card/components/OrderDetails'

export default function OrderDetailsModal({
  isOpen,
  orderDetails,
  contentLabel,
  style,
  onClose,
  onPrepare,
}) {
  const [isPreparing, setIsPreparing] = useState(false)

  const listCardsFormatted = useMemo(() => {
    const orders = orderDetails?.orders
    if (!orders?.length) {
      return []
    }

    const cardsSortedByAmount = []

    for (const order of orders) {
      cardsSortedByAmount[order.amount] = cardsSortedByAmount.hasOwnProperty(
        order.amount,
      )
        ? cardsSortedByAmount[order.amount] + 1
        : 1
    }

    return cardsSortedByAmount
  }, [orderDetails?.orders])

  if (!orderDetails) {
    return <></>
  }

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={() => {
        setIsPreparing(false)
        onClose()
      }}
      classNames={isPreparing ? 'max-w-6xl' : ''}
    >
      {isPreparing ? (
        <PrepareCards
          orderDetails={orderDetails}
          onPrepare={onPrepare}
          listCardsFormatted={listCardsFormatted}
          onClose={() => {
            setIsPreparing(false)
            onClose()
          }}
        />
      ) : (
        <OrderDetails
          orderDetails={orderDetails}
          onPrepare={onPrepare}
          listCardsFormatted={listCardsFormatted}
          setIsPreparing={setIsPreparing}
          onClose={() => {
            setIsPreparing(false)
            onClose()
          }}
        />
      )}
    </DefaultModal>
  )
}
