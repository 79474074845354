import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CardHolderDetails({ holder }) {
  const { t } = useTranslation()

  return (
    <div>
      <h2
        className={'font-bold border-b border-gfDarkBlue pb-0.5 inline text-lg'}
      >
        {t('Cardholder Information')}
      </h2>
      <div className={'flex mt-4'}>
        <div className={'font-semibold truncate w-48 flex flex-col'}>
          <span>{t('Full Name')}:</span>
          <span>{t('Phone Number')}:</span>
        </div>
        <div className={'flex flex-col'}>
          <p>{`${holder.first_name} ${holder.last_name}`}</p>
          <p>{`${holder.phone_number || '-'}`}</p>
        </div>
      </div>
    </div>
  )
}
