import React from 'react'

export default function Table({ children }) {
  return (
    <div className=" overflow-x-auto sm:overflow-x-auto rounded border ">
      <div className="align-middle inline-block min-w-full  ">
        <div className="shadow overflow-hidden  ">
          <table className="min-w-full divide-y divide-gfPeriwinkle">
            {children}
          </table>
        </div>
      </div>
    </div>
  )
}
