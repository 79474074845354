import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { sumBy } from 'lodash'
import useAdditionalProducts from './useAdditionalProducts'

export default function useLoadCard({
  cards,
  additionalProducts,
  paymentMethods,
  onRemoveCard,
}) {
  const { t } = useTranslation()
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag)
  const { findById } = useAdditionalProducts()

  const hasPurchaseFee = useMemo(
    () => selectedTag.purchase_fee_enabled,
    [selectedTag],
  )

  const purchaseFee = useMemo(
    () => selectedTag.purchase_fee.amount_with_vat ?? 0,
    [selectedTag],
  )

  const selectedProducts = useMemo(
    () =>
      additionalProducts
        .filter((product) => product.product && product.quantity > 0)
        .map(({ product, quantity }) => ({
          product: findById(product),
          quantity: parseInt(quantity),
        })),
    [additionalProducts, findById],
  )

  const lineItems = useMemo(() => {
    let items = []

    items = cards.map((card, index) => ({
      name: `${t('Card')} (${card.token})`,
      amount: card.amount,
      currency: tagCurrency,
      onRemove: () => onRemoveCard(index),
    }))

    for (const selectedProduct of selectedProducts) {
      const amount =
        selectedProduct.product.total_price * selectedProduct.quantity

      items.push({
        name: `${selectedProduct.product.name} (x${selectedProduct.quantity})`,
        amount,
        currency: selectedProduct.product.currency,
      })
    }

    if (hasPurchaseFee) {
      items.push({
        name: `${t('place-b2b-order.purchase-fee.line-item.name')} (x${cards.length})`,
        amount: purchaseFee * cards.length,
        currency: tagCurrency,
        description: t('place-b2b-order.purchase-fee.line-item.description'),
      })
    }

    return items
  }, [
    cards,
    selectedProducts,
    tagCurrency,
    hasPurchaseFee,
    purchaseFee,
    t,
    onRemoveCard,
  ])

  const totalAmount = useMemo(
    () => sumBy(lineItems, (item) => parseFloat(item.amount)),
    [lineItems],
  )
  const sumOfPayments = useMemo(() => {
    if (paymentMethods.length === 1) {
      return totalAmount
    }

    return sumBy(paymentMethods, (method) => parseFloat(method.amount))
  }, [paymentMethods, totalAmount])

  const isTotalAmountCovered = useMemo(
    () => totalAmount === sumOfPayments,
    [totalAmount, sumOfPayments],
  )

  return {
    lineItems,
    totalAmount,
    isTotalAmountCovered,
    hasPurchaseFee,
    purchaseFee,
  }
}
