import { format, subMonths } from 'date-fns'
import React, { useState, useEffect, forwardRef } from 'react'
import CurrencyFormat from 'react-currency-format'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { Userpilot } from 'userpilot'
import { Link } from 'react-router-dom'
import { getCardInformation } from '../../../../actions/CardInformationActions'
import {
  exportCSVCardTransferReport,
  exportExcelCardTransferReport,
  getTransfers,
  getTransfersPaginate,
  getUserList,
  RESET_TRANSFER,
} from '../../../../actions/CardTransferActions'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import FailAlert from '../../../Alert/FailAlert'
import MessageAlert from '../../../Alert/MessageAlert'
import CardInfoWidget from '../../../CardInfoWidget'
import CardTransferDeskDropDown from '../../../Dropdown/CardTransferDeskDropDown'
import CardTransferUsersDropdown from '../../../Dropdown/CardTransferUsersDropdown'
import CardInfo from './CardInfo'
import PrintPreview from './PrintPreview'
import Container from '../../../UIComponents/Container'
import Button from '../../../UIComponents/Button'
import Table from '../../../UIComponents/Table'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import { GF_DARK_BLUE } from '../../../../utils/Constants'
import { getCalanderLocale } from '../../../../utils/CalanderLocale'
import { RESET_APP_COUNT } from '../../../../actions/CommonActions'

export default function CardTransferList() {
  const { appCount, loadingCSV, loadingExcel, errorCSV } = useSelector(
    (state) => state.commonReducer,
  )

  useEffect(() => {
    Userpilot.reload()
    if (appCount === 0) {
      dispatch({ type: RESET_TRANSFER })
      dispatch(getUserList())
    }

    window.addEventListener(TAG_CHANGED, () => {
      setChooseDate(subMonths(new Date(), 3))
      setEndDate(new Date())
      setPage(1)
      setUser({ id: -1, firstName: t('All'), lastName: '' })
      setDesk({ id: -1, name: t('All') })
      dispatch({ type: RESET_TRANSFER })
      dispatch(getUserList())
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      setChooseDate(subMonths(new Date(), 3))
      setEndDate(new Date())
      setPage(1)
      setUser({ id: -1, firstName: t('All'), lastName: '' })
      setDesk({ id: -1, name: t('All') })
      dispatch({ type: RESET_TRANSFER })
      dispatch(getUserList())
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [chooseDate, setChooseDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false)
  const [printDetails, setPrintDetails] = useState({})
  const [cardInfoVisibility, setCardInfoVisibility] = useState(false)
  const [token, setToken] = useState('')
  const [user, setUser] = useState({
    id: -1,
    firstName: t('All'),
    lastName: '',
  })
  const [desk, setDesk] = useState({ id: -1, name: t('All') })
  const [page, setPage] = useState(1)

  const {
    loadingTransferUserList,
    receivedTransferUserList,
    errorTransferUserList,

    loadingTransferDeskList,
    receivedTransferDeskList,
    errorTransferDeskList,

    loadingCardTransferList,
    receivedCardTransferList,
    errorCardTransferList,
    metaCardTransferList,
    summaryCardTransferList,
  } = useSelector((state) => state.cardTransfer)
  const { selectedLanguage } = useSelector((state) => state.language)

  getCalanderLocale(selectedLanguage)

  const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
    <input
      id={id}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border text-center cursor-pointer w-full rounded  hover:bg-gfPeriwinkle border-gfPeriwinkle px-8 h-10 focus:outline-none"
    />
  ))

  const fromDatePickHandler = (date) => {
    setChooseDate(date)
  }

  const toDatePickHandler = (date) => {
    setEndDate(date)
  }

  const getTransferList = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(
      getTransfers(
        from,
        to,
        user.id,
        receivedTransferDeskList && receivedTransferDeskList.length > 1
          ? desk.id
          : receivedTransferDeskList[0].id,
        page,
      ),
    )
  }

  const handlePageClick = (event) => {
    setPage(event.selected + 1)

    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(
      getTransfersPaginate(
        from,
        to,
        user.id,
        receivedTransferDeskList && receivedTransferDeskList.length > 1
          ? desk.id
          : receivedTransferDeskList[0].id,
        event.selected + 1,
      ),
    )
  }

  const printPreview = (details) => {
    setPrintDetails((prev) => Object.assign(prev, details))
    setPrintPreviewVisibility(!printPreviewVisibility)
  }

  const printPreviewClose = () => {
    setPrintPreviewVisibility(!printPreviewVisibility)
  }

  const viewCardInfo = (event, token) => {
    event.persist()
    setToken(token)
    setCardInfoVisibility(!cardInfoVisibility)
    dispatch(getCardInformation(token))
  }

  const cardInfoCloseHandler = () => {
    setPrintDetails({})
    setCardInfoVisibility(!cardInfoVisibility)
  }

  const userOnChanged = (event) => {
    if (event.id === -1) {
      setUser({ id: -1, firstName: t('All'), lastName: '' })
    } else {
      setUser(event)
    }

    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(
      getTransfers(
        from,
        to,
        event.id,
        receivedTransferDeskList && receivedTransferDeskList.length > 1
          ? desk.id
          : receivedTransferDeskList[0].id,
        page,
      ),
    )
  }

  const deskOnChanged = (event) => {
    setDesk(event)
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(
      getTransfers(
        from,
        to,
        user.id,
        receivedTransferDeskList && receivedTransferDeskList.length > 1
          ? event.id
          : receivedTransferDeskList[0].id,
        page,
      ),
    )
  }

  const exportCSV = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(
      exportCSVCardTransferReport(
        from,
        to,
        receivedTransferDeskList && receivedTransferDeskList.length > 1
          ? desk.id
          : receivedTransferDeskList[0].id,
        user.id,
        'Card Transfer Report',
      ),
    )
  }

  const exportExcel = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(
      exportExcelCardTransferReport(
        from,
        to,
        receivedTransferDeskList && receivedTransferDeskList.length > 1
          ? desk.id
          : receivedTransferDeskList[0].id,
        user.id,
        'Card Transfer Report',
      ),
    )
  }

  const linkOnClickHandler = () => {
    dispatch({ type: RESET_APP_COUNT })
  }

  return (
    <Container
      title={t('list-of-card-transfer')}
      loading={loadingTransferUserList || loadingTransferDeskList}
    >
      <div
        className={`grid grid-cols-1 ${
          receivedTransferDeskList && receivedTransferDeskList.length > 1
            ? 'md:grid-cols-5'
            : 'md:grid-cols-4'
        } gap-5 items-end`}
      >
        <div className="flex flex-col">
          <div className="flex items-center  font-medium py-1">
            {t('Choose-the-date')}:
          </div>{' '}
          <ReactDatePicker
            id="fromDateInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingCardTransferList}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={chooseDate}
            onChange={(date) => fromDatePickHandler(date)}
            locale={selectedLanguage.value}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center  font-medium py-1">
            {`${t('End-date')}(${t('Optional')})`}
          </div>
          <ReactDatePicker
            id="toDateInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingCardTransferList}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => toDatePickHandler(date)}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
          />
        </div>

        <div className="flex flex-col w-full">
          <div className="flex items-center  font-medium py-1">
            {t('User')}:{' '}
          </div>{' '}
          <CardTransferUsersDropdown
            id={'userDropdown'}
            value={user}
            onChangeHandler={userOnChanged}
            options={receivedTransferUserList}
          />
        </div>

        {receivedTransferDeskList && receivedTransferDeskList.length > 1 && (
          <div className="flex flex-col w-64">
            <div className="flex items-center  font-medium">{t('Desk')}: </div>{' '}
            <CardTransferDeskDropDown
              id={'deskDropdown'}
              value={desk}
              onChangeHandler={deskOnChanged}
              options={receivedTransferDeskList}
            />
          </div>
        )}

        <Button
          id={'goButton'}
          disabled={loadingCardTransferList}
          onClick={getTransferList}
          loading={loadingCardTransferList}
          label={t('Go')}
        />
      </div>

      <div className="my-10 lg:px-40 md:px-40 px-3">
        {receivedCardTransferList && receivedCardTransferList.length === 0 && (
          <MessageAlert
            message={t('There-are-no-information-to-display-for-this-date')}
          />
        )}
        {errorCardTransferList && (
          <FailAlert message={errorCardTransferList.message} />
        )}
        {errorTransferDeskList && (
          <FailAlert message={errorTransferDeskList.message} />
        )}
        {errorTransferUserList && (
          <FailAlert message={errorTransferUserList.message} />
        )}
        {errorCSV && <FailAlert message={errorCSV.message} />}
      </div>

      {summaryCardTransferList &&
        receivedCardTransferList &&
        receivedCardTransferList.length !== 0 && (
          <div className="flex flex-col mt-20 space-y-5">
            <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
              <CardInfoWidget
                bottomLabel={t('Number-of-transactions')}
                value={summaryCardTransferList.total_entries}
                isCurrency={false}
                valueColor={'text-gfDarkBlue'}
                labelColor={'text-gfDarkBlue'}
              />
              <CardInfoWidget
                bottomLabel={t('Total-fees')}
                isCurrency
                value={summaryCardTransferList.total_fees}
                valueColor={'text-gfLightBlue'}
                labelColor={'text-gfLightBlue'}
              />
            </div>

            <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
              <div className="flex flex-row">
                <button
                  id="exportCsv"
                  onClick={exportCSV}
                  className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                >
                  {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                  {t('Export-as-a-CSV-file')}
                </button>

                <button
                  id="exportExcel"
                  onClick={exportExcel}
                  className="ml-3 px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                >
                  {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                  {t('export-excel')}
                </button>
              </div>

              {receivedCardTransferList &&
                metaCardTransferList &&
                metaCardTransferList.total >
                  window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                  <div>
                    <ReactPaginate
                      previousLabel={'prev'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={
                        metaCardTransferList.total /
                        window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                      }
                      marginPagesDisplayed={4}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </div>
                )}
            </div>

            <Table>
              <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                <tr>
                  <th
                    scope="col"
                    className="3/12 px-4 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Date')}
                  </th>
                  <th
                    scope="col"
                    className="1/12 px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('User')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Debited-card')}
                  </th>
                  <th
                    scope="col"
                    className="1/12 px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Fee')}
                  </th>
                  <th
                    scope="col"
                    className="3/12 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Credited-card')}
                  </th>
                  <th
                    scope="col"
                    className="1/12 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gfPeriwinkle">
                {receivedCardTransferList &&
                  receivedCardTransferList.map((tr) => (
                    <tr key={tr.ticket_number}>
                      <td className="px-4 py-4">
                        <div className="flex justify-center items-center">
                          {tr.transaction_date_time}
                        </div>
                      </td>
                      <td className="px-2 py-4 ">
                        <div className="flex justify-start items-center">{`${tr.user.first_name} ${tr.user.last_name}`}</div>
                      </td>
                      <td className="px-2 py-4">
                        {tr.entries.map((en) => {
                          if (en.is_unload)
                            return (
                              <div
                                key={en.token}
                                className="flex justify-center items-center space-x-2"
                              >
                                <Link
                                  className="underline"
                                  onClick={linkOnClickHandler}
                                  to={`/gift-cards/card-information?token=${en.token}`}
                                >
                                  {en.token}
                                </Link>
                                <div className="flex flex-row font-MulishBold  text-gfLightBlue ">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={en.amount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-1 ">{`${String(
                                    tr.currency,
                                  ).toUpperCase()}`}</span>
                                </div>
                              </div>
                            )
                        })}
                      </td>
                      <td className="px-2 py-4">
                        <div className="flex justify-center items-center font-MulishBold  text-gfLightBlue">
                          <CurrencyFormat
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={tr.fees}
                            displayType={'text'}
                            thousandSeparator={true}
                          />
                          <span className="ml-1">{`${String(tr.currency).toUpperCase()}`}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {tr.entries.map((en) => {
                          if (!en.is_unload)
                            return (
                              <div
                                key={en.token}
                                className="flex justify-center items-center space-x-2"
                              >
                                <Link
                                  onClick={linkOnClickHandler}
                                  className="underline"
                                  to={`/gift-cards/card-information?token=${en.token}`}
                                >
                                  {en.token}
                                </Link>
                                <div className="flex flex-row font-MulishBold  text-gfLightBlue ">
                                  <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={en.amount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                  <span className="ml-1 ">{`${String(
                                    tr.currency,
                                  ).toUpperCase()}`}</span>
                                </div>
                              </div>
                            )
                        })}
                      </td>
                      <td className="px-2 py-4 text-sm font-medium">
                        <div className="flex justify-center items-center">
                          <button
                            onClick={() => {
                              printPreview(tr)
                            }}
                            className="text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                          >
                            {t('Print-ticket')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}

      <PrintPreview
        modalVisibility={printPreviewVisibility}
        closeHandler={printPreviewClose}
        transferDetails={printDetails}
      />
      <CardInfo
        modalVisibility={cardInfoVisibility}
        closeHandler={cardInfoCloseHandler}
        mvcToken={token}
      />
    </Container>
  )
}
