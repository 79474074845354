import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from '../../utils/Constants'
import DropdownIndicator from './Components/DropdownIndicator'

export default function ChannelListDropdown({
  options,
  onChangeHandler,
  value,
  id,
}) {
  const { t } = useTranslation()
  const customStyles = (value) => ({
    placeholder: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      minWidth: '230px',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
        borderColor: GF_PERIWINKLE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: '#F7F7F8',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.id === value.id
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      padding: 8,
      fontSize: '16px',
      color:
        state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
      '&:hover': {
        background: '#FF666E',
        color: '#FFF',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '18px',
    }),
  })

  const defaultValue = {
    card_brands: [],
    id: -1,
    name: t('All'),
    terminals: [],
  }

  return (
    <div>
      <Select
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        placeholder={false}
        styles={customStyles(value)}
        options={options && options.constructor === Array ? options : []}
        getOptionLabel={(option) => `${option.name}`}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
      />
    </div>
  )
}
