/* eslint-disable react-hooks/exhaustive-deps */
import format from 'date-fns/format'
import React, { forwardRef, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Userpilot } from 'userpilot'
import {
  getUserList,
  getDeskList,
  getProductReport,
  POPULATED_LIST,
  RESET_DAILY_REPORT,
  getCSV,
  getExcel,
} from '../../../../actions/DailyReportActions'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import FailAlert from '../../../Alert/FailAlert'
import MessageAlert from '../../../Alert/MessageAlert'
import CardInfoWidget from '../../../CardInfoWidget'
import CardTransferDeskDropDown from '../../../Dropdown/CardTransferDeskDropDown'
import CardTransferUsersMultiDropdown from '../../../Dropdown/CardTransferUsersMultiDropdown'
import PrintPreview from './PrintPreview'
import Container from '../../../UIComponents/Container'
import { GF_DARK_BLUE } from '../../../../utils/Constants'
import Table from '../../../UIComponents/Table'
import {
  checkLoggedInUserPermissions,
  randomString,
} from '../../../../utils/Helper'
import {
  RESET_APP_COUNT,
  RESET_CSV_REPORT,
} from '../../../../actions/CommonActions'
import ChevronDown from '../../../../assets/svg/ChevronDown'
import ChevronUp from '../../../../assets/svg/ChevronUp'
import Print from '../../../../assets/svg/Print'
import PrintOne from './PrintOne'
import { getCalanderLocale } from '../../../../utils/CalanderLocale'
import PrintEntireReport from './PrintEntireReport'
import DailyReportDeskMultiDropDown from '../../../Dropdown/DailyReportDeskMultiDropDown'

export default function DailyReport() {
  const {
    loadingUserList,
    receivedUserList,
    errorUserList,

    receivingDeskList,
    errorDeskList,

    defaultDesk,

    loadingDailyReport,
    receivingDailyReport,
    errorDailyReport,
    populatedList,
    dailyReportSummary,
  } = useSelector((state) => state.dailyReport)

  const { selectedLanguage } = useSelector((state) => state.language)

  const {
    appCount,
    userApiCount,
    deskApiCount,
    loadingCSV,
    errorCSV,
    loadingExcel,
    errorExcel,
  } = useSelector((state) => state.commonReducer)

  useEffect(() => {
    Userpilot.reload()

    if (userApiCount === 0) dispatch(getUserList())
    if (deskApiCount === 0) dispatch(getDeskList())

    if (appCount === 0) {
      dispatch({ type: RESET_DAILY_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
      getDataFirstCall()
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_DAILY_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
      dispatch(getUserList())
      dispatch(getDeskList())
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_DAILY_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
      dispatch(getUserList())
      dispatch(getDeskList())
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  getCalanderLocale(selectedLanguage)

  const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
    <input
      id={id}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border cursor-pointer text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
    />
  ))

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [chooseDate, setChooseDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [user, setUser] = useState([
    { id: -1, firstName: t('All'), lastName: '' },
  ])
  const [desk, setDesk] = useState(defaultDesk || [{ id: -1, name: t('All') }])
  const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false)
  const [printDetails, setPrintDetails] = useState({})
  const [showMore, setShowMore] = useState(false)
  const [selectedID, setSelectedID] = useState('')
  const [entireReportVisibility, setEntireReportVisibility] = useState(false)

  const [printOneVisibility, setPrintOneVisibility] = useState(false)
  const [token, setToken] = useState('')

  const fromDatePickHandler = (date) => {
    setChooseDate(date)
  }

  const toDatePickHandler = (date) => {
    setEndDate(date)
  }

  const getDataFirstCall = () => {
    dispatch(
      getProductReport(
        format(new Date(), 'yyyy-MM-dd'),
        format(new Date(), 'yyyy-MM-dd'),
        -1,
        -1,
      ),
    )
  }

  const getChartHandler = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    const users = (!Array.isArray(user) ? [user] : user).map(({ id }) => {
      return id
    })

    const desks = (!Array.isArray(desk) ? [desk] : desk).map(({ id }) => {
      return id
    })

    const hasUserAllValue = users.includes(-1)
    const hasDeskAllValue = desks.includes(-1)

    dispatch(
      getProductReport(
        from,
        to,
        receivedUserList && receivedUserList.length !== 0
          ? hasUserAllValue
            ? -1
            : users
          : -1,
        receivingDeskList && receivingDeskList.length > 1
          ? hasDeskAllValue
            ? -1
            : desks
          : [receivingDeskList[0].id],
      ),
    )
  }

  const handlePageClick = (event) => {
    dispatch({ type: POPULATED_LIST, payload: event.selected + 1 })
  }

  const userOnChanged = (event) => {
    if (event.id === -1) {
      setUser({ id: -1, firstName: t('All'), lastName: '' })
    } else {
      setUser(event)
    }

    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    const users = (!Array.isArray(event) ? [event] : event).map(({ id }) => {
      return id
    })

    const desks = (!Array.isArray(desk) ? [desk] : desk).map(({ id }) => {
      return id
    })
    const hasUserAllValue = users.includes(-1)
    const hasDeskAllValue = desks.includes(-1)

    dispatch(
      getProductReport(
        from,
        to,
        hasUserAllValue ? -1 : users,
        receivingDeskList && receivingDeskList.length > 1
          ? hasDeskAllValue
            ? -1
            : desks
          : [receivingDeskList[0].id],
      ),
    )
  }

  const deskOnChanged = (event) => {
    setDesk(event)

    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    // localStorage.setItem("desk-id", event.id);
    const users = (!Array.isArray(user) ? [user] : user).map(({ id }) => {
      return id
    })
    const desks = (!Array.isArray(event) ? [event] : event).map(({ id }) => {
      return id
    })
    const hasUserAllValue = users.includes(-1)
    const hasDeskAllValue = desks.includes(-1)
    dispatch(
      getProductReport(
        from,
        to,
        hasUserAllValue ? -1 : users,
        receivingDeskList && receivingDeskList.length > 1
          ? hasDeskAllValue
            ? -1
            : desks
          : [receivingDeskList[0].id],
      ),
    )
  }

  const exportCSV = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    const users = (!Array.isArray(user) ? [user] : user).map(({ id }) => {
      return id
    })
    const desks = (!Array.isArray(desk) ? [desk] : desk).map(({ id }) => {
      return id
    })
    const hasUserAllValue = users.includes(-1)
    const hasDeskAllValue = desks.includes(-1)
    dispatch(
      getCSV(
        from,
        to,
        hasUserAllValue ? -1 : users,
        receivingDeskList && receivingDeskList.length > 1
          ? hasDeskAllValue
            ? -1
            : desks
          : [receivingDeskList[0].id],
        'Daily Report',
      ),
    )
  }

  const exportExcel = () => {
    const from = format(chooseDate, 'yyyy-MM-dd')
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    const users = (!Array.isArray(user) ? [user] : user).map(({ id }) => {
      return id
    })
    const desks = (!Array.isArray(desk) ? [desk] : desk).map(({ id }) => {
      return id
    })
    const hasUserAllValue = users.includes(-1)
    const hasDeskAllValue = desks.includes(-1)

    dispatch(
      getExcel(
        from,
        to,
        hasUserAllValue ? -1 : users,
        receivingDeskList && receivingDeskList.length > 1
          ? hasDeskAllValue
            ? -1
            : desks
          : [receivingDeskList[0].id],
        'Daily Report',
      ),
    )
  }

  const printPreview = (details) => {
    setPrintDetails((prev) => Object.assign(prev, details))
    setPrintPreviewVisibility(!printPreviewVisibility)
    document.body.style.overflow = 'hidden'
  }

  const printPreviewClose = () => {
    setPrintDetails({})
    setPrintPreviewVisibility(!printPreviewVisibility)
    document.body.style.overflow = 'unset'
  }

  const showHideMoreCards = (event, data) => {
    if (event.currentTarget.id === data.ticket_number) {
      setShowMore(!showMore)
      setSelectedID(data.ticket_number)
    }
  }

  const printOneTicket = (details, token) => {
    setPrintDetails((prev) => Object.assign(prev, details))
    console.log(token)
    setToken(token)
    setPrintOneVisibility(!printOneVisibility)
  }

  const closePrintOneTicket = () => {
    setPrintDetails({})
    setToken('')
    setPrintOneVisibility(!printOneVisibility)
  }

  const entireReportPreview = () => {
    setEntireReportVisibility(!entireReportVisibility)
    document.body.style.overflow = 'hidden'
  }

  const closeEntireReportPreview = () => {
    setEntireReportVisibility(!entireReportVisibility)
    document.body.style.overflow = 'unset'
  }

  const linkOnClickHandler = () => {
    dispatch({ type: RESET_APP_COUNT })
  }

  return (
    <Container title={t('daily-report')} loading={loadingUserList}>
      <div
        className={`grid grid-cols-1 ${
          receivingDeskList && receivingDeskList.length > 1
            ? 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
            : 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4'
        } gap-5 items-end `}
      >
        <div className="flex flex-col">
          <div className="flex items-center   py-1">
            {t('Choose-the-date')}:
          </div>{' '}
          <ReactDatePicker
            id="fromDateInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingDailyReport}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={chooseDate}
            onChange={(date) => fromDatePickHandler(date)}
            locale={selectedLanguage.value}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center   py-1">
            {`${t('End-date')}(${t('Optional')})`}
          </div>
          <ReactDatePicker
            id="toDateInput"
            showMonthDropdown
            showYearDropdown
            disabled={loadingDailyReport}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => toDatePickHandler(date)}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
          />
        </div>

        {receivedUserList && receivedUserList.length !== 0 && (
          <div className="flex flex-col w-full">
            <div className="flex items-center   py-1">{t('User')}: </div>{' '}
            <CardTransferUsersMultiDropdown
              value={user}
              onChangeHandler={userOnChanged}
              options={receivedUserList}
            />
          </div>
        )}

        {receivingDeskList && receivingDeskList.length > 1 && (
          <div className="flex flex-col w-full">
            <div className="flex items-center  ">{t('Desk')}: </div>{' '}
            <DailyReportDeskMultiDropDown
              value={desk}
              onChangeHandler={deskOnChanged}
              options={receivingDeskList}
            />
          </div>
        )}

        <button
          id="goButton"
          disabled={loadingDailyReport}
          onClick={getChartHandler}
          className="h-10 py-2 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
        >
          {loadingDailyReport && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Go')}!
        </button>
      </div>

      {((receivingDailyReport && receivingDailyReport.length === 0) ||
        errorDailyReport ||
        errorUserList ||
        errorDeskList) && (
        <div className="mt-10">
          {receivingDailyReport && receivingDailyReport.length === 0 && (
            <MessageAlert
              message={t('There-are-no-information-to-display-for-this-date')}
            />
          )}
          {errorDailyReport && <FailAlert message={errorDailyReport.message} />}
          {errorUserList && <FailAlert message={errorUserList.message} />}
          {errorDeskList && <FailAlert message={errorDeskList.message} />}
          {errorCSV && <FailAlert message={errorCSV.message} />}
        </div>
      )}

      {receivingDailyReport && receivingDailyReport.length !== 0 && (
        <div className="flex flex-col mt-20 space-y-5">
          <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
            <CardInfoWidget
              bottomLabel={t('Number-of-transactions')}
              isCurrency={false}
              value={dailyReportSummary.total_transactions}
              labelColor={'text-gfDarkBlue'}
              valueColor={'text-gfDarkBlue'}
            />
            <CardInfoWidget
              bottomLabel={t('loaded-cards')}
              isCurrency={false}
              value={dailyReportSummary.total_cards}
              labelColor={'text-gfDarkBlue'}
              valueColor={'text-gfDarkBlue'}
            />
            <CardInfoWidget
              bottomLabel={t('Total-amount')}
              isCurrency
              value={dailyReportSummary.total_loads_amount}
              labelColor={'text-gfLightBlue'}
              valueColor={'text-gfLightBlue'}
            />
          </div>

          <div className="flex flex-col">
            <div className="font-MulishBlack mb-2 ">{t('Payment-methods')}</div>
            <div className="flex flex-wrap -mx-6 overflow-hidden sm:-mx-1 md:-mx-4 lg:-mx-8 xl:-mx-2 border rounded bg-gfSand">
              {dailyReportSummary.payment_method_summary.map((item) => (
                <div
                  key={`${item.name}-${randomString(5)}`}
                  data-testid={`${item.name}`}
                  className="my-6 px-6 w-1/6 overflow-hidden sm:my-1 sm:px-1 sm:w-1/3 md:my-4 md:px-4 md:w-1/2 lg:my-8 lg:px-8 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/6"
                >
                  <div className="flex flex-col">
                    <span className="font-MulishBold ">{item.name}</span>
                    <div className="flex flex-row">
                      {t('Transactions')}: &nbsp;
                      <span className="text-gfDarkBlue font-MulishBold">
                        {item.total_transactions}
                      </span>
                    </div>
                    <div className="flex flex-row">
                      {t('Total')}: &nbsp;
                      <div className="flex justify-start text-gfLightBlue font-MulishBold">
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={item.total_amount}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <span className="ml-2">{` ${item.currency}`}</span>
                      </div>
                    </div>
                    <div className="flex flex-row ">
                      {t('loaded-cards')}: &nbsp;
                      <span className="text-gfDarkBlue font-MulishBold">
                        {item.total_load_card}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
            <div className="flex flex-row">
              <button
                id="exportCsvButton"
                onClick={exportCSV}
                className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-CSV-file')}
              </button>
              <button
                id="exportExcelButton"
                onClick={exportExcel}
                className="ml-3 px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-Excel-file')}
              </button>
              <button
                id="printReportButton"
                onClick={entireReportPreview}
                className="ml-3 px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {t('Print-Report')}
              </button>
            </div>
            {receivingDailyReport.length >
              window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
              <div>
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    receivingDailyReport.length /
                    window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </div>

          <Table>
            <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3 text-left   uppercase tracking-wider"
                >
                  {t('Date')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 w-32 text-left   uppercase tracking-wider"
                >
                  {t('Ticket-number')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left   uppercase tracking-wider"
                >
                  {t('User')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left   uppercase tracking-wider"
                >
                  {t('Card')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left   uppercase tracking-wider"
                >
                  {t('Payment-method')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left   uppercase tracking-wider"
                >
                  {t('Additional-product')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-right   uppercase tracking-wider"
                >
                  {t('Amount')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-center   uppercase tracking-wider"
                >
                  {t('Action')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
              {receivingDailyReport &&
                populatedList &&
                populatedList.map((report) => (
                  <tr
                    key={report.ticket_number}
                    className={`${report.no_of_entries > 1 ? 'shadow-md' : ''}`}
                  >
                    <td className="px-4 py-4" data-testid="date">
                      <div className="flex justify-start items-center">
                        {report.transaction_date_time}
                      </div>
                      {report.no_of_entries > 1 && (
                        <div
                          className={`${
                            showMore && selectedID === report.ticket_number
                              ? ' flex flex-col invisible'
                              : 'hidden'
                          }`}
                        >
                          {report.entries.map((entry) => (
                            <div
                              key={entry.token}
                              className="flex flex-row py-1"
                            >
                              <div>20 EUR</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-4" data-testid="ticketNumber">
                      <div className="flex justify-start items-center">
                        {report.ticket_number}
                      </div>
                      {report.no_of_entries > 1 && (
                        <div
                          className={`${
                            showMore && selectedID === report.ticket_number
                              ? ' flex flex-col invisible'
                              : 'hidden'
                          }`}
                        >
                          {report.entries.map((entry) => (
                            <div
                              key={entry.token}
                              className="flex flex-row py-1"
                            >
                              <div>20 EUR</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-4" data-testid="user">
                      {report.user && (
                        <div className="flex justify-start items-center">{`${report.user.first_name} ${report.user.last_name}`}</div>
                      )}
                      {report.no_of_entries > 1 && (
                        <div
                          className={`${
                            showMore && selectedID === report.ticket_number
                              ? ' flex flex-col invisible'
                              : 'hidden'
                          }`}
                        >
                          {report.entries.map((entry) => (
                            <div
                              key={entry.token}
                              className="flex flex-row py-1"
                            >
                              <div>20 EUR</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-4" data-testid="card">
                      {report.no_of_entries > 1 ? (
                        <div>
                          <div className="flex justify-start items-center">{`x${report.no_of_entries}`}</div>
                          <div
                            className={`${
                              showMore && selectedID === report.ticket_number
                                ? 'flex flex-col'
                                : 'hidden'
                            }`}
                          >
                            {report.entries.map((entry) => (
                              <div
                                key={entry.token}
                                className="flex flex-row py-1"
                              >
                                <div className="flex justify-start items-center text-gfLightBlue">
                                  {checkLoggedInUserPermissions(
                                    'card-information',
                                  ) ? (
                                    <div className="flex flex-row items-center justify-between">
                                      <Link
                                        onClick={linkOnClickHandler}
                                        className="underline"
                                        to={`/gift-cards/card-information?token=${entry.token}`}
                                      >
                                        {entry.token}
                                      </Link>
                                      <button
                                        onClick={() =>
                                          printOneTicket(report, entry.token)
                                        }
                                        className="ml-4"
                                      >
                                        <Print />
                                      </button>
                                    </div>
                                  ) : (
                                    entry.token
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-start items-center  text-gfLightBlue">
                          {checkLoggedInUserPermissions('card-information') ? (
                            <Link
                              onClick={linkOnClickHandler}
                              className="underline"
                              to={`/gift-cards/card-information?token=${report.entries[0].token}`}
                            >
                              {report.entries[0].token}
                            </Link>
                          ) : (
                            report.entries[0].token
                          )}
                        </div>
                      )}
                    </td>

                    <td className="px-4 py-4" data-testid="paymentMethod">
                      <div className="flex flex-col justify-start items-start">
                        {report.payments.length !== 0 &&
                          report.payments.map((payment) => (
                            <div className="flex flex-row" key={payment.id}>
                              <span>{`${payment.name} `}</span>
                              <span>{`( `}</span>
                              <div className="flex justify-end">
                                <CurrencyFormat
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  value={payment.total_amount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                />
                                <span className="ml-2">{` ${report.currency}`}</span>
                                <span>{` )`}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                      {report.no_of_entries > 1 && (
                        <div
                          className={`${
                            showMore && selectedID === report.ticket_number
                              ? ' flex flex-col invisible'
                              : 'hidden'
                          }`}
                        >
                          {report.entries.map((entry) => (
                            <div
                              key={entry.token}
                              className="flex flex-row py-1"
                            >
                              <div>20 EUR</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>

                    <td className="px-4 py-4" data-testid="additionalProduct">
                      <div className="flex flex-col justify-start items-start">
                        {report.additional_products.length !== 0
                          ? report.additional_products.map((product) => (
                              <span
                                key={product.product.id}
                              >{`${product.quantity} x ${
                                product.product ? product.product.name : '-'
                              } `}</span>
                            ))
                          : '-'}
                      </div>
                      {report.no_of_entries > 1 && (
                        <div
                          className={`${
                            showMore && selectedID === report.ticket_number
                              ? ' flex flex-col invisible'
                              : 'hidden'
                          }`}
                        >
                          {report.entries.map((entry) => (
                            <div
                              key={entry.token}
                              className="flex flex-row py-1"
                            >
                              <div>20 EUR</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>

                    <td className="px-4 py-4 text-sm" data-testid="amount">
                      <div className="flex justify-end text-gfLightBlue font-MulishBold">
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={report.payments.reduce((sum, item) => {
                            sum += item.total_amount
                            return sum
                          }, 0)}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <span className="ml-2">{` ${report.currency}`}</span>
                      </div>
                      {report.no_of_entries > 1 && (
                        <div
                          className={`${
                            showMore && selectedID === report.ticket_number
                              ? 'flex flex-col'
                              : 'hidden'
                          }`}
                        >
                          {report.payments.map((entry) => (
                            <div
                              key={entry.token}
                              className="flex flex-row py-1"
                            >
                              <div className="flex justify-end text-gfLightBlue">
                                <CurrencyFormat
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  value={entry.total_amount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                />
                                <span className="ml-2">{` ${report.currency}`}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </td>

                    <td className="px-4 py-4 text-sm" data-testid="action">
                      <div className="flex justify-between items-center">
                        <button
                          onClick={() => {
                            printPreview(report)
                          }}
                          className="text-gfLightBlue text-opacity-75 focus:outline-none hover:underline"
                        >
                          {t('view-ticket')}
                        </button>
                        {report.no_of_entries > 1 && (
                          <button
                            className="focus:outline-none"
                            id={report.ticket_number}
                            onClick={(event) =>
                              showHideMoreCards(event, report)
                            }
                          >
                            {showMore && selectedID === report.ticket_number ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}

      <PrintPreview
        modalVisibility={printPreviewVisibility}
        closeHandler={printPreviewClose}
        transferDetails={printDetails}
        invoiceTicketFooterEnable={true}
      />

      <PrintOne
        modalVisibility={printOneVisibility}
        transferDetails={printDetails}
        closeHandler={closePrintOneTicket}
        token={token}
      />

      <PrintEntireReport
        modalVisibility={entireReportVisibility}
        closeHandler={closeEntireReportPreview}
        fromDate={chooseDate}
        toDate={endDate !== null ? endDate : new Date()}
      />
    </Container>
  )
}
