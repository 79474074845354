import { issuingAPI } from '../services/Axios'
import { exportCSVFile } from '../utils/Helper'
import { APP_COUNT } from './CommonActions'

export const PREFIX = '@salesByMerchant/'

export const LOADING_MERCHANT_LIST = `${PREFIX}LOADING_MERCHANT_LIST`
export const RECEIVED_MERCHANT_LIST = `${PREFIX}RECEIVED_MERCHANT_LIST`
export const ERROR_MERCHANT_LIST = `${PREFIX}ERROR_MERCHANT_LIST`

export const LOADING_MERCHANT_SALES = `${PREFIX}LOADING_MERCHANT_SALES`
export const RECEIVED_MERCHANT_SALES = `${PREFIX}RECEIVED_MERCHANT_SALES`
export const ERROR_MERCHANT_SALES = `${PREFIX}ERROR_MERCHANT_SALES`
export const SELECTED_MERCHANTS = `${PREFIX}SELECTED_MERCHANTS`

export const SET_MERCHANTS = `${PREFIX}SET_MERCHANTS`
export const RESET_MERCHANT_LIST = `${PREFIX}RESET_MERCHANT_LIST`

export const SET_SELECTED_MERCHANT = `${PREFIX}SET_SELECTED_MERCHANT`
export const SET_UN_SELECTED_MERCHANT = `${PREFIX}SET_UN_SELECTED_MERCHANT`

export const LOOK_FOR_A_MERCHANT = `${PREFIX}LOOK_FOR_A_MERCHANT`

export const SET_FILTER_BY = `${PREFIX}SET_FILTER_BY`

export const getMerchantList =
  (chooseDate, endDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANT_LIST })

    dispatch({ type: APP_COUNT })

    const { tagCardBrands } = getState().tag
    const cardBrandId = tagCardBrands.length !== 0 ? tagCardBrands[0] : 0
    const params = {
      from_date: chooseDate,
      to_date: endDate,
    }

    return issuingAPI
      .get(`card-brand/${cardBrandId}/merchant`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_MERCHANT_LIST, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: RECEIVED_MERCHANT_LIST, payload: error })
        return error
      })
  }

export const getMerchantSales =
  (chooseDate, endDate, groupBy) => (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANT_SALES })

    const { tagCardBrands } = getState().tag
    const cardBrandId = tagCardBrands.length !== 0 ? tagCardBrands[0] : 0
    const { selectedMerchants } = getState().salesByMerchant
    const selectedIds = selectedMerchants.map((item) => item.id)
    const params = {
      from_date: chooseDate,
      to_date: endDate,
      merchant_ids: selectedIds,
      group_by: groupBy,
    }

    return issuingAPI
      .get(`card-brand/${cardBrandId}/merchant-sale`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_MERCHANT_SALES, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_MERCHANT_SALES, payload: error })
        return error
      })
  }

export const LOADING_CSV_REPORT = `${PREFIX}LOADING_CSV_REPORT`
export const RECEIVED_CSV_REPORT = `${PREFIX}RECEIVED_CSV_REPORT`
export const ERROR_CSV_REPORT = `${PREFIX}ERROR_CSV_REPORT`

// https://portalapi.dev.giftify.me/card-brand/{cardBrandId}/merchant-sale/stream
export const getCSVReoprt =
  (fromDate, toDate, groupBy) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { tagCardBrands } = getState().tag
    const cardBrandId = tagCardBrands.length !== 0 ? tagCardBrands[0] : 0
    const { selectedMerchants } = getState().salesByMerchant
    const selectedIds = selectedMerchants.map((item) => item.id)

    const params = {
      from_date: fromDate,
      to_date: toDate,
      merchant_ids: selectedIds,
      group_by: groupBy,
    }

    const headers = {
      Accept: 'text/csv',
    }

    const reportName = 'SalesByMerchant'

    const fileName = `${reportName}-${fromDate}-${toDate}`

    issuingAPI
      .get(`card-brand/${cardBrandId}/merchant-sale/stream`, {
        headers,
        params,
      })
      .then((response) => {
        dispatch({ type: RECEIVED_CSV_REPORT, payload: response.data })
        exportCSVFile(response.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }
