import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './store/store'
import { Provider } from 'react-redux'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
//import * as Sentry from "@sentry/react";
//import { Integrations } from "@sentry/tracing";
import './assets/main.css'
import './assets/react-datepicker.css'
import App from './App'
import { Userpilot } from 'userpilot'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en', // use en if detected lng is not available

    detection: {
      order: [
        'localStorage',
        'querystring',
        'cookie',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/my-portal.json',
    },

    supportedLngs: [
      'en',
      'cs',
      'de',
      'es',
      'fr',
      'hu',
      'pt',
      'ro',
      'nl',
      'sk',
      'it',
      'fi',
      'pl',
      'lt',
      'hr',
      'sr',
    ],
    //react: { useSuspense: true }
  })

// Initialize Userpilot
Userpilot.initialize('NX-8145e814')

// Sentry.init({
//     dsn: window?.appConfig.REACT_APP_SENTRY_DSN,
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

const loadingMarkup = (
  <div className="w-full py-8 mx-auto">
    <h3>Loading..</h3>
  </div>
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root'),
)
