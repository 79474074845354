import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { isSmallScreen } from '../../../../utils/Helper'

ReactModal.setAppElement('#root')

export default function B2BPrepareOrderSuccess({
  isOpen,
  data,
  closeHandler,
  selectedOrder,
  successHandler,
}) {
  const { t } = useTranslation()

  const successOKHandler = () => {
    window.location.reload()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: !isSmallScreen() ? '50px' : '0px',
          left: !isSmallScreen() ? '100px' : '0px',
          right: !isSmallScreen() ? '100px' : '0px',
          bottom: !isSmallScreen() ? '100px' : '0px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: !isSmallScreen() ? '50px' : '0px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border max-w-lg rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="text-kadozGreen font-MulishBold my-3">
          Your order has been prepared successfully !
        </div>

        <button
          id="B2BOKButton"
          onClick={() => successOKHandler()}
          className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
        >
          {t('Ok').toUpperCase()}
        </button>
      </div>
    </ReactModal>
  )
}
