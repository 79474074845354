/* eslint-disable import/no-anonymous-default-export */
import {
  ERROR_WEEKLY_SALES,
  ERROR_WEEKLY_USAGE,
  LOADING_WEEKLY_SALES,
  LOADING_WEEKLY_USAGE,
  RECEIVED_WEEKLY_SALES,
  RECEIVED_WEEKLY_USAGE,
  RESET_WEEKLY_SALES,
  RESET_WEEKLY_USAGE,
} from '../actions/WeeklyBreakdownActions'

const initialState = {
  loadingWeeklyBreakdown: false,
  receivedWeeklyBreakdown: null,
  errorWeeklyBreakdown: null,
  summary: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_WEEKLY_SALES:
      return {
        ...state,
        loadingWeeklyBreakdown: true,
        receivedWeeklyBreakdown: null,
        errorWeeklyBreakdown: null,
      }

    case RECEIVED_WEEKLY_SALES:
      return {
        ...state,
        loadingWeeklyBreakdown: false,
        receivedWeeklyBreakdown: action.payload.data,
        errorWeeklyBreakdown: null,
        summary: action.payload.summary,
      }

    case ERROR_WEEKLY_SALES:
      return {
        ...state,
        loadingWeeklyBreakdown: false,
        receivedWeeklyBreakdown: null,
        errorWeeklyBreakdown: action.payload,
      }

    case RESET_WEEKLY_SALES:
      return {
        ...state,
        ...initialState,
      }

    case LOADING_WEEKLY_USAGE:
      return {
        ...state,
        loadingWeeklyBreakdown: true,
        receivedWeeklyBreakdown: null,
        errorWeeklyBreakdown: null,
      }

    case RECEIVED_WEEKLY_USAGE:
      return {
        ...state,
        loadingWeeklyBreakdown: false,
        receivedWeeklyBreakdown: action.payload.data,
        errorWeeklyBreakdown: null,
        summary: action.payload.summary,
      }

    case ERROR_WEEKLY_USAGE:
      return {
        ...state,
        loadingWeeklyBreakdown: false,
        receivedWeeklyBreakdown: null,
        errorWeeklyBreakdown: action.payload,
      }

    case RESET_WEEKLY_USAGE:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}
