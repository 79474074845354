import { issuingAPI } from '../services/Axios'
import {
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
} from './CommonActions'
import { exportExcelFile } from '../utils/Helper'

export const PREFIX = '@performanceReport/'

export const LOADING_PERFORMANCE_REPORT = `${PREFIX}LOADING_PERFORMANCE_REPORT`
export const RECEIVED_PERFORMANCE_REPORT = `${PREFIX}RECEIVED_PERFORMANCE_REPORT`
export const ERROR_PERFORMANCE_REPORT = `${PREFIX}ERROR_PERFORMANCE_REPORT`
export const RESET_PERFORMANCE_REPORT = `${PREFIX}RESET_PERFORMANCE_REPORT`

export const LOADING_CSV_REPORT = `${PREFIX}LOADING_CSV_REPORT`
export const RECEIVED_CSV_REPORT = `${PREFIX}RECEIVED_CSV_REPORT`
export const ERROR_CSV_REPORT = `${PREFIX}ERROR_CSV_REPORT`
export const RESET_CSV_REPORT = `${PREFIX}RESET_CSV_REPORT`

export const getPerformanceReport =
  (reportType, viewType, yearPicker, monthPicker) => (dispatch, getState) => {
    dispatch({ type: LOADING_PERFORMANCE_REPORT })

    const { tagID } = getState().tag

    const params = {
      year: yearPicker,
    }

    // {{host}}/tag/{{tag_id}}/management-report/type/monthly?year=2023
    // {{host}}/tag/{{tag_id}}/management-report/type/ytd/group?year=2022

    let url = ''

    if (viewType === 'group') {
      Object.assign(params, { month: monthPicker })
      url = `/tag/${tagID}/management-report/type/${reportType}/group`
    } else {
      url = `/tag/${tagID}/management-report/type/${reportType}`
    }

    return issuingAPI
      .get(url, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_PERFORMANCE_REPORT, payload: success.data })
        return success
      })
      .catch((error) => {
        dispatch({ type: ERROR_PERFORMANCE_REPORT, payload: error })
        return error
      })
  }

export const getPerformanceReportExcel =
  (reportType, viewType, yearPicker, monthPicker) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })
    // {{host}}/tag/{{tag_id}}/management-report/type/monthly/group/stream?year=2024&month=1
    // {{host}}/tag/{{tag_id}}/management-report/type/ytd/group/stream?year=2024&month=1
    // {{host}}/tag/{{tag_id}}/management-report/type/r12m/group/stream?year=2020&month=6

    // {{host}}/tag/{{tag_id}}/management-report/type/monthly/stream?year=2020
    // {{host}}/tag/{{tag_id}}/management-report/type/ytd/stream?year=2020
    // {{host}}/tag/{{tag_id}}/management-report/type/r12m/stream?year=2020

    const { tagID } = getState().tag

    const params = {
      year: yearPicker,
    }

    let url = ''

    if (viewType === 'group') {
      Object.assign(params, { month: monthPicker })
      url = `/tag/${tagID}/management-report/type/${reportType}/group/stream`
    } else {
      url = `/tag/${tagID}/management-report/type/${reportType}/stream`
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const fileName = `Management Performance Report-${yearPicker}-${reportType}`

    return issuingAPI
      .get(url, { responseType: 'blob', headers, params })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT })
        exportExcelFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
