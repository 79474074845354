/* eslint-disable import/no-anonymous-default-export */
import {
  ERROR_TAG_LIST,
  FAIL_UPDATING_TAG,
  LOADING_TAG_LIST,
  RECEIVED_TAG_LIST,
  REMOVE_HAS_CB_ALERT,
  RESET_UPDATING_TAG,
  SELECTED_TAG,
  SUCCESS_UPDATING_TAG,
  UPDATED_LOGO,
  UPDATED_LOGO_BW,
  UPDATING_TAG,
} from '../actions/TagActions'

const initialState = {
  loadingTagList: false,
  receivedTagList: null,
  errorTagList: null,

  selectedTag: null,

  tagID: null,
  tagCurrency: null,
  tagCardBrands: null,
  hasCardBrandIds: false,

  updatingTag: false,
  successUpdatingTag: null,
  failUpdatingTag: null,

  updatedLogo: null,
  updatedLogoBW: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TAG_LIST:
      return {
        ...state,
        loadingTagList: true,
        receivedTagList: null,
        errorTagList: null,

        selectedTag: null,

        tagID: null,
        tagCurrency: null,
        tagCardBrands: null,
        hasCardBrandIds: true, // to stop message when loading
      }

    case RECEIVED_TAG_LIST:
      const newList = action.payload.tags
      const tagId = localStorage.getItem('tg-id')
      const currentReferenceID = action.payload.reference
      const localReferenceID = parseInt(localStorage.getItem('reference'))
      let selected

      if (localReferenceID === null) {
        if (tagId === null) {
          selected = newList[0]
          localStorage.setItem('tg-id', selected.id)
        } else {
          selected = newList.find((tag) => parseInt(tag.id) === parseInt(tagId))
        }
      } else if (localReferenceID === currentReferenceID) {
        selected = newList.find((tag) => parseInt(tag.id) === parseInt(tagId))
      } else {
        selected = newList[0]
        localStorage.setItem('tg-id', selected.id)
      }

      let cbIds = false
      if (
        selected.card_brand_ids === '' ||
        selected.card_brand_ids === null ||
        selected.card_brand_ids.length === 0 ||
        (selected.card_brand_ids.length === 1 &&
          selected.card_brand_ids[0] === 0)
      ) {
        cbIds = false
      } else {
        cbIds = true
      }

      return {
        ...state,
        loadingTagList: false,
        receivedTagList: newList,
        errorTagList: null,

        selectedTag: selected,

        tagID: selected.id,
        tagCurrency: selected.currency,
        tagCardBrands: selected.card_brand_ids,

        hasCardBrandIds: cbIds,
      }

    case SELECTED_TAG:
      const newlySelected = action.payload
      localStorage.setItem('tg-id', newlySelected.id)

      let newCbIds = false
      if (
        newlySelected.card_brand_ids === '' ||
        newlySelected.card_brand_ids === null ||
        newlySelected.card_brand_ids.length === 0 ||
        (newlySelected.card_brand_ids.length === 1 &&
          newlySelected.card_brand_ids[0] === 0)
      ) {
        newCbIds = false
      } else {
        newCbIds = true
      }

      return {
        ...state,
        selectedTag: newlySelected,

        tagID: newlySelected.id,
        tagCurrency: newlySelected.currency,
        tagCardBrands: newlySelected.card_brand_ids,

        hasCardBrandIds: newCbIds,
      }

    case REMOVE_HAS_CB_ALERT:
      return {
        ...state,
        hasCardBrandIds: action.payload,
      }

    case ERROR_TAG_LIST:
      return {
        ...state,
        loadingTagList: false,
        receivedTagList: null,
        errorTagList: action.payload,

        selectedTag: null,

        tagID: null,
        tagCurrency: null,
        tagCardBrands: null,

        hasCardBrandIds: false,
      }

    case UPDATING_TAG:
      return {
        ...state,
        updatingTag: true,
        successUpdatingTag: null,
        failUpdatingTag: null,
      }

    case SUCCESS_UPDATING_TAG:
      return {
        ...state,
        updatingTag: false,
        successUpdatingTag: action.payload,
        failUpdatingTag: null,
      }

    case FAIL_UPDATING_TAG:
      return {
        ...state,
        updatingTag: false,
        successUpdatingTag: null,
        failUpdatingTag: action.payload,
      }

    case RESET_UPDATING_TAG:
      return {
        ...state,
        updatingTag: false,
        successUpdatingTag: null,
        failUpdatingTag: null,
      }

    case UPDATED_LOGO:
      return {
        ...state,
        updatedLogo: action.payload,
      }

    case UPDATED_LOGO_BW:
      return {
        ...state,
        updatedLogoBW: action.payload,
      }

    default:
      return state
  }
}
