import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export default function DeleteConfirmation({
  isOpen,
  isDefault,
  noOption,
  yesOption,
}) {
  const { t } = useTranslation()

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
        className="bg-gray-700 z-50 pointer-events-auto backdrop-blur-md"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(23, 35, 82, 0.6)',
            zIndex: 9999,
            backdropFilter: 'blur(8px)',
          },
          content: {
            position: 'absolute',
            top: '160px',
            left: '100px',
            right: '100px',
            bottom: '100px',

            backgroundColor: 'rgba(23, 35, 82, 0.0)',
            overflow: 'hidden',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '50px',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          },
        }}
      >
        {!isDefault && (
          <div className="border-gfPeriwinkle border rounded bg-white flex flex-col w-5/12 mx-auto p-5 text-gfDarkBlue font-MulishBold">
            <div>
              {t(
                'This-action-is-irreversible-are-you-sure-you-want-to-delete-this-address',
              )}
            </div>
            <div className="flex flex-row justify-center space-x-20 py-5">
              <button
                className="py-2 px-4 bg-gfCoral focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                onClick={yesOption}
              >
                {t('Yes')}
              </button>
              <button
                className="py-2 px-4 bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                onClick={noOption}
              >
                {t('No')}
              </button>
            </div>
          </div>
        )}

        {isDefault && (
          <div className="border-gfPeriwinkle border rounded bg-white flex flex-col w-5/12 mx-auto p-5 text-gfDarkBlue font-MulishBold">
            <div>{t('This-address-cannot-be-deleted')}</div>
            <div className="flex flex-row justify-center space-x-20 py-5">
              <button
                className="py-2 px-4 bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
                onClick={noOption}
              >
                {t('Ok')}
              </button>
            </div>
          </div>
        )}
      </ReactModal>
    </div>
  )
}
