import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Userpilot } from 'userpilot'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import {
  fetchDeliveryAddresses,
  SET_SELECTED_ADDRESS,
} from '../../../../actions/DeliveryAddressActions'
import AddressListDropdown from '../../../Dropdown/AddressListDropdown'
import {
  CARD_ORDER_OPERATION_ID,
  createCardOrder,
  getPromoCardsLayouts,
} from '../../../../actions/CardOrderActions'
import MessageAlert from '../../../Alert/MessageAlert'
import FailAlert from '../../../Alert/FailAlert'
import SuccessAlert from '../../../Alert/SuccessAlert'
import Container from '../../../UIComponents/Container'
import { GF_DARK_BLUE } from '../../../../utils/Constants'

export default function OrderCards() {
  const dispatch = useDispatch()
  const PAGE_NAME = 'orderCards'
  const { t } = useTranslation()

  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
  const [remarks, setRemarks] = useState('')
  const [canSend, setSend] = useState(false)

  const { appCount } = useSelector((state) => state.commonReducer)

  useEffect(() => {
    Userpilot.reload()
    if (appCount === 0) {
      dispatch(fetchDeliveryAddresses(PAGE_NAME))
      dispatch(getPromoCardsLayouts())
      dispatch({ type: CARD_ORDER_OPERATION_ID })
    }
  }, [])

  const {
    loadingDeliveryAddresses,
    errorDeliveryAddresses,
    orderCardsAddressList,
    selectedAddress,
  } = useSelector((state) => state.deliveryAddress)
  const {
    currency,
    id: TagID,
    promo_card_amounts: cardAmounts,
    promo_card_order_min_total_value: minimumOrder,
  } = useSelector((state) => state.tag.selectedTag)
  const {
    creatingCardOrder,
    successCreateCardOrder,
    failCreateCardOrder,
    receivedPromoCardsLayout,
    errorPromoCardsLayout,
    defaultLayout,
  } = useSelector((state) => state.cardOrders)

  const defaultValues = {
    selectedLayout: defaultLayout ? String(defaultLayout.id) : '',
  }

  const { register, handleSubmit, reset } = useForm({
    reValidateMode: 'onChange',
    defaultValues,
  })

  const onSubmit = (data) => {
    let layoutID = ''
    if (
      receivedPromoCardsLayout.length !== 0 &&
      receivedPromoCardsLayout.length === 1
    ) {
      layoutID = receivedPromoCardsLayout[0].id
    } else if (receivedPromoCardsLayout.length === 0) {
      layoutID = ''
    } else {
      layoutID = data.selectedLayout
    }

    const filtered = selectedPaymentMethods.filter(function (el) {
      return el.quantity !== ''
    })

    const tot = filtered
      .map((item) => item.amount_per_card * item.quantity)
      .reduce((prev, next) => prev + next)

    if (minimumOrder <= tot) {
      setSend(false)
      dispatch(createCardOrder(filtered, selectedAddress.id, remarks, layoutID))
    } else {
      setSend(true)
    }
  }

  const quantityChanged = (event, value, index) => {
    event.persist()
    setSelectedPaymentMethods((selectedPaymentMethodsOld) => {
      const exists = selectedPaymentMethodsOld.filter(
        (item) => item.amount_per_card === getValue(index),
      )
      if (exists.length) {
        return selectedPaymentMethodsOld.map((prod) => {
          if (prod.amount_per_card === getValue(index)) {
            return { ...prod, quantity: event.target.value }
          }
          return prod
        })
      }
      return [
        ...selectedPaymentMethodsOld,
        { amount_per_card: getValue(index), quantity: event.target.value },
      ]
    })
  }

  const getValue = (index) => {
    const amount = cardAmounts[index]
    return amount
  }

  const addressChanged = (event) => {
    dispatch({ type: SET_SELECTED_ADDRESS, payload: event })
  }
  return (
    <Container
      title={t('order-promo-cards')}
      loading={loadingDeliveryAddresses}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mx-auto justify-center items-center">
          {orderCardsAddressList && selectedAddress && (
            <div className=" w-full flex flex-row justify-center items-center mb-10">
              <div className="flex md:flex-row justify-center items-center flex-col bg-kadozLightGray rounded border text-sm w-full space-x-10">
                <div className="flex flex-col">
                  <span className=" text-sm mb-2">
                    {t('Select-another-delivery-address')}
                  </span>
                  <AddressListDropdown
                    id={'addressDropdown'}
                    options={orderCardsAddressList}
                    onChangeHandler={addressChanged}
                  />
                </div>

                <div className="flex flex-col p-2">
                  <span className="text-sm">{t('Delivery-addresses')}:</span>
                  <div className="flex flex-col justify-start   mt-1">
                    <span>{`${selectedAddress.delivery_name}`}</span>
                    <span>{`${selectedAddress.street}, ${selectedAddress.street_number}`}</span>
                    <span>{`${selectedAddress.zip_code}, ${selectedAddress.city} (${selectedAddress.country_code})`}</span>
                  </div>
                </div>
                <div className="flex flex-col p-2">
                  <span className="text-sm ">{t('Contact-person')}:</span>
                  <div className=" flex flex-col justify-start mt-1">
                    <span>{`${selectedAddress.contact_name}`}</span>
                    <span>{`${selectedAddress.contact_email}`}</span>
                    <span>{`${selectedAddress.contact_phone}`}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {errorDeliveryAddresses && (
            <FailAlert message={errorDeliveryAddresses.message} />
          )}

          {errorPromoCardsLayout && (
            <FailAlert message={errorPromoCardsLayout.message} />
          )}

          {receivedPromoCardsLayout && receivedPromoCardsLayout.length > 1 && (
            <div className="flex md:flex-row flex-col bg-kadozLightGray rounded border text-sm w-full space-x-10 py-4 mb-10">
              <div className=" flex flex-row space-x-10 mx-auto">
                {receivedPromoCardsLayout.map((layout, index) => (
                  <div>
                    <div className="flex flex-col justify-center items-center">
                      <label className="py-2" htmlFor={layout.name}>
                        <input
                          ref={register({ required: true })}
                          type="radio"
                          value={layout.id}
                          id={layout.name}
                          name="selectedLayout"
                        />
                      </label>
                      <img
                        className="object-contain rounded border-gfPeriwinkle border h-24 w-28 hover:object-scale-up"
                        alt="img"
                        src={`${window?.appConfig.REACT_APP_IMAGE_URL}/promocard/layout/${layout.img_file_recto}`}
                      />
                      <div className="truncate w-28 hover:text-clip py-4">
                        {layout.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10">
            {cardAmounts !== 0 &&
              cardAmounts.map((value, index) => (
                <div
                  key={index}
                  className="flex flex-col border rounded p-2 justify-center items-center"
                >
                  <div className="flex flex-row mb-2 text-gfLightBlue font-MulishBold">
                    <span className="text-3xl  tracking-wider">{value}</span>
                    <span className=" tracking-wider">{currency}</span>
                  </div>
                  <input
                    id={`quantity${value}Input`}
                    autoFocus={index === 0}
                    ref={register}
                    name={`quantity${index}`}
                    onChange={(event) => quantityChanged(event, value, index)}
                    className="text-center border rounded w-20 font-semibold text-2xl px-2 py-1 focus:outline-none focus:border-kadozGray"
                    type="number"
                    maxLength="4"
                    onWheel={(e) => e.target.blur()}
                  />
                  <span className="tracking-wider">{t('Quantity')}</span>
                </div>
              ))}
          </div>
          <div className="flex flex-col justify-center items-center p-10">
            <div className="flex flex-row items-center  text-sm mb-3">
              <span className="">
                {t('The-minimum-total-amount-to-place-an-order-is')}
              </span>
              <div className="px-2 flex justify-end text-gfLightBlue font-MulishBold">
                <CurrencyFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={minimumOrder}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                <span className="ml-2">{` ${currency}`}</span>
              </div>
            </div>
            <textarea
              id="remarkTextarea"
              onChange={(event) => setRemarks(event.target.value)}
              className="w-full p-4 h-24 border rounded focus:outline-none focus:border-kadozSemiGray"
              placeholder={t('Your-remark-here')}
            ></textarea>
          </div>

          <div className="py-5">
            {canSend && (
              <MessageAlert
                message={`Please place the minimum total amount for the order ${minimumOrder.toFixed(
                  2,
                )} ${currency}`}
              />
            )}
            {failCreateCardOrder && (
              <FailAlert message={failCreateCardOrder.message} />
            )}
            {successCreateCardOrder && (
              <SuccessAlert
                title={t('Done')}
                message={t('Order-Successfully-Created')}
              />
            )}
          </div>
          <div className="flex flex-row justify-between space-x-10">
            <button
              id="orderButton"
              disabled={creatingCardOrder}
              type="submit"
              className="w-full flex flex-row border rounded px-4 py-2 justify-center items-center flex-no-wrap font-MulishBold bg-gfCoral focus:outline-none focus:border-gfCoral hover:bg-opacity-75  cursor-pointer"
            >
              {creatingCardOrder && <ButtonLoading color={GF_DARK_BLUE} />}
              {t('Order')}
            </button>
            <input
              id="resetButton"
              disabled={creatingCardOrder}
              onClick={() => reset()}
              type="button"
              value={t('Reset')}
              className="w-full border rounded px-4 py-2  font-MulishBold focus:outline-none hover:bg-opacity-75 bg-gfPeriwinkle cursor-pointer"
            />
          </div>
        </div>
      </form>
    </Container>
  )
}
