const OrderStatus = Object.freeze({
  ACTIVE: 'active',
  TO_PICK_UP: 'to_pick_up',
  PARTIALLY_PICKED_UP: 'partially_picked_up',
  PARTIALLY_PREPARED: 'partially_prepared',
  PICKED_UP: 'picked_up',
  PAID: 'paid',
  DECLINE: 'decline',
  ALL: 'all',
  AWAITING_PAYMENT: 'awaiting_payment',
  PREPARED: 'prepared',
})

export default OrderStatus
