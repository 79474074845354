/* eslint-disable import/no-anonymous-default-export */
import {
  RESET_ORDER_HISTORY,
  CREATE_CARD_ORDER,
  FAIL_CREATE_CARD_ORDER,
  FETCH_CARD_ORDERS,
  FETCH_CARD_ORDERS_ERROR,
  FETCH_CARD_ORDERS_SUCCESS,
  SUCCESS_CREATE_CARD_ORDER,
  CANCEL_CARD_ORDER,
  SUCCESS_CANCEL_CARD_ORDER,
  FAIL_CANCEL_CARD_ORDER,
  LOADING_ORDER_DETAILS,
  RECEIVED_ORDER_DETAILS,
  ERROR_ORDER_DETAILS,
  RESET_ORDER_DETAILS,
  CARD_ORDER_OPERATION_ID,
  LOADING_PROMO_CARDS_LAYOUT,
  RECEIVED_PROMO_CARDS_LAYOUT,
  ERROR_PROMO_CARDS_LAYOUT,
} from '../actions/CardOrderActions'
import { randomString } from '../utils/Helper'

const INITIAL_STATE = {
  cardOrderList: null,
  loadingCardOrderList: false,
  errorOrderList: null,
  metaCardOrderList: null,

  creatingCardOrder: false,
  successCreateCardOrder: null,
  failCreateCardOrder: null,

  loadingCancelOrder: false,
  successCancelOrder: null,
  failCancelOrder: null,

  loadingOrderDetails: false,
  orderDetails: null,
  errorOrderDetails: null,

  cardOrderOperationID: null,

  loadingPromoCardsLayout: false,
  receivedPromoCardsLayout: null,
  errorPromoCardsLayout: null,
  defaultLayout: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CARD_ORDERS:
      return {
        ...state,
        loadingCardOrderList: true,
        cardOrderList: false,
        errorOrderList: null,
      }

    case FETCH_CARD_ORDERS_SUCCESS:
      return {
        ...state,
        cardOrderList: action.payload.data,
        loadingCardOrderList: false,
        metaCardOrderList: action.payload.meta,
      }

    case FETCH_CARD_ORDERS_ERROR:
      return {
        ...state,
        cardOrderList: null,
        loadingCardOrderList: false,
        errorOrderList: action.payload,
      }

    case CARD_ORDER_OPERATION_ID:
      return {
        ...state,
        cardOrderOperationID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
      }

    case CREATE_CARD_ORDER:
      return {
        ...state,
        creatingCardOrder: true,
        successCreateCardOrder: null,
        failCreateCardOrder: null,
      }

    case SUCCESS_CREATE_CARD_ORDER:
      return {
        ...state,
        creatingCardOrder: false,
        successCreateCardOrder: action.payload,
        failCreateCardOrder: null,
      }

    case FAIL_CREATE_CARD_ORDER:
      return {
        ...state,
        creatingCardOrder: false,
        successCreateCardOrder: null,
        failCreateCardOrder: action.payload,
      }

    case RESET_ORDER_HISTORY:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    case CANCEL_CARD_ORDER:
      return {
        ...state,
        loadingCancelOrder: true,
        successCancelOrder: null,
        failCancelOrder: null,
      }

    case SUCCESS_CANCEL_CARD_ORDER:
      return {
        ...state,
        loadingCancelOrder: false,
        successCancelOrder: action.payload,
        failCancelOrder: null,
      }

    case FAIL_CANCEL_CARD_ORDER:
      return {
        ...state,
        loadingCancelOrder: false,
        successCancelOrder: null,
        failCancelOrder: action.payload,
      }

    case LOADING_ORDER_DETAILS:
      return {
        ...state,
        loadingOrderDetails: true,
        orderDetails: null,
        errorOrderDetails: null,
      }

    case RECEIVED_ORDER_DETAILS:
      return {
        ...state,
        loadingOrderDetails: false,
        orderDetails: action.payload,
        errorOrderDetails: null,
      }

    case ERROR_ORDER_DETAILS:
      return {
        ...state,
        loadingOrderDetails: false,
        orderDetails: null,
        errorOrderDetails: action.payload,
      }

    case RESET_ORDER_DETAILS:
      return {
        ...state,
        loadingOrderDetails: false,
        orderDetails: null,
        errorOrderDetails: null,
      }

    case LOADING_PROMO_CARDS_LAYOUT:
      return {
        ...state,
        loadingPromoCardsLayout: true,
        receivedPromoCardsLayout: null,
        defaultLayout: null,
        errorPromoCardsLayout: null,
      }

    case RECEIVED_PROMO_CARDS_LAYOUT:
      const temp = action.payload
      let difLayout = null
      if (temp.length !== 0) {
        difLayout = temp[0]
      }

      return {
        ...state,
        loadingPromoCardsLayout: false,
        receivedPromoCardsLayout: action.payload,
        defaultLayout: difLayout,
        errorPromoCardsLayout: null,
      }

    case ERROR_PROMO_CARDS_LAYOUT:
      return {
        ...state,
        loadingPromoCardsLayout: false,
        receivedPromoCardsLayout: null,
        defaultLayout: null,
        errorPromoCardsLayout: action.payload,
      }

    default:
      return state
  }
}
