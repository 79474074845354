import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Userpilot } from 'userpilot'
import Container from '../../UIComponents/Container'
import Table from '../../UIComponents/Table'
import {
  POPULATED_BLOCKED_CARD_REPORT,
  getBlockedCards,
  getBlockedCardsReportCSV,
  getBlockedCardsReportExcel,
} from '../../../actions/BlockedCardsReportActions'
import ButtonLoading from '../../../assets/svg/ButtonLoading'
import { GF_DARK_BLUE } from '../../../utils/Constants'
import { TAG_CHANGED } from '../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../actions/TerminalActions'
import MessageAlert from '../../Alert/MessageAlert'
import FailAlert from '../../Alert/FailAlert'
import { RESET_APP_COUNT } from '../../../actions/CommonActions'

export default function BlockedCardsReport() {
  useEffect(() => {
    Userpilot.reload()

    dispatch(getBlockedCards())
    if (appCount === 0) {
      dispatch(getBlockedCards())
    }

    window.addEventListener(TAG_CHANGED, () => {})

    window.addEventListener(TERMINAL_CHANGED, () => {})

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    loadingBlockedCards,
    receivedBlockedCards,
    errorBlockedCards,
    populatedBlockedCards,
  } = useSelector((state) => state.blockedCards)
  const { loadingCSV, loadingExcel, errorCSV, appCount } = useSelector(
    (state) => state.commonReducer,
  )

  const handlePageClick = (event) => {
    dispatch({
      type: POPULATED_BLOCKED_CARD_REPORT,
      payload: event.selected + 1,
    })
  }

  const exportCSV = () => {
    dispatch(getBlockedCardsReportCSV())
  }

  const exportExcel = () => {
    dispatch(getBlockedCardsReportExcel())
  }

  const linkOnClickHandler = () => {
    dispatch({ type: RESET_APP_COUNT })
  }

  return (
    <Container title={t('blocked-cards-report')} loading={loadingBlockedCards}>
      {receivedBlockedCards && receivedBlockedCards.length !== 0 && (
        <div className="flex flex-col space-y-5">
          <div className="flex flex-row justify-end items-center gap-3">
            {receivedBlockedCards &&
              receivedBlockedCards.length >
                window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                <div>
                  <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={
                      receivedBlockedCards.length /
                      window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                    }
                    marginPagesDisplayed={4}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                </div>
              )}

            <button
              onClick={exportExcel}
              className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
            >
              {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
              {t('Export-as-a-Excel-file')}
            </button>

            <button
              onClick={exportCSV}
              className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
            >
              {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
              {t('Export-as-a-CSV-file')}
            </button>
          </div>

          <Table>
            <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
              <tr>
                <th
                  scope="col"
                  className="3/12 px-4 py-3  text-center  uppercase tracking-wider"
                >
                  {t('Date')}
                </th>
                <th
                  scope="col"
                  className="1/12 px-2 py-3  text-center  uppercase tracking-wider"
                >
                  {t('Token')}
                </th>
                <th
                  scope="col"
                  className="3/12 px-2 py-3  text-center  uppercase tracking-wider"
                >
                  {t('User')}
                </th>
                <th
                  scope="col"
                  className="3/12 px-2 py-3  text-center  uppercase tracking-wider"
                >
                  {t('reason-for-block')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gfPeriwinkle">
              {receivedBlockedCards &&
                populatedBlockedCards.length !== 0 &&
                populatedBlockedCards.map((blockedCard) => (
                  <tr key={blockedCard.CMAPI_public_token}>
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        {blockedCard.blocking_date}
                      </div>
                    </td>

                    <td className="px-2 py-4">
                      {/* <Link onClick={ linkOnClickHandler}
                      className="underline flex justify-center items-center"
                      to={ `/gift-cards/card-information?token=${blockedCard.CMAPI_public_token}` }
                    >
                      { blockedCard.CMAPI_public_token }
                    </Link> */}
                      <div className="flex justify-center items-center">
                        {blockedCard.CMAPI_public_token}
                      </div>
                    </td>
                    <td className="px-2">
                      {blockedCard.description !== null ? (
                        <div className="flex justify-center items-center">{`${blockedCard.description.user !== '' ? blockedCard.description.user : '-'}`}</div>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td className="px-2 py-4 flex justify-center items-center">
                      {blockedCard.description !== null ? (
                        <div className="">{`${blockedCard.description.desc !== '' ? blockedCard.description.desc : '-'}`}</div>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}

      {receivedBlockedCards && receivedBlockedCards.length === 0 && (
        <MessageAlert message={t('there-are-no-information-for-this-center')} />
      )}
      {errorBlockedCards && <FailAlert message={errorBlockedCards.message} />}
      {errorCSV && <FailAlert message={errorCSV.message} />}
    </Container>
  )
}
