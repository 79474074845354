import { issuingAPI } from '../services/Axios'
import { APP_COUNT } from './CommonActions'

export const PREFIX = '@manageOrders/'

export const LOADING_MANAGE_ORDERS = `${PREFIX}LOADING_MANAGE_ORDERS`
export const RECEIVED_MANAGE_ORDERS = `${PREFIX}RECEIVED_MANAGE_ORDERS`
export const ERROR_MANAGE_ORDERS = `${PREFIX}ERROR_MANAGE_ORDERS`
export const RESET_MANAGE_ORDERS = `${PREFIX}RESET_MANAGE_ORDERS`

export const CHANGING_ORDER_STATUS = `${PREFIX}CHANGING_ORDER_STATUS`
export const CHANGED_ORDER_STATUS = `${PREFIX}CHANGED_ORDER_STATUS`
export const ERROR_CHANGING_ORDER_STATUS = `${PREFIX}ERROR_CHANGING_ORDER_STATUS`
export const RESET_CHANGING_ORDER_STATUS = `${PREFIX}RESET_CHANGING_ORDER_STATUS`

export const ORDER_PREPARING = `${PREFIX}ORDER_PREPARING`
export const ORDER_PREPARED = `${PREFIX}ORDER_PREPARED`
export const ORDER_PREPARING_ERROR = `${PREFIX}ORDER_PREPARING_ERROR`
export const ORDER_PREPARE_RESET = `${PREFIX}ORDER_PREPARE_RESET`

export const MANAGE_ORDERS_OPERATION_ID = `${PREFIX}MANAGE_ORDERS_OPERATION_ID`
export const MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID = `${PREFIX}MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID`

export const RESET_ADD_CARD_FIELDS = `${PREFIX}RESET_ADD_CARD_FIELDS`

export const getManageOrdersByDeliveryMethod =
  (searchTerms, pageNumber, fromDate = null, toDate = null, status = null) =>
  (dispatch, getState) => {
    dispatch({ type: APP_COUNT })

    dispatch({ type: LOADING_MANAGE_ORDERS })

    const { tagID } = getState().tag
    const params = {
      search_terms: searchTerms,
      from_date: fromDate,
      to_date: toDate,
      status,
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    }

    if (pageNumber !== 0) {
      Object.assign(params, { page: pageNumber })
    }
    issuingAPI
      .get(`tag/${tagID}/orders`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_MANAGE_ORDERS, payload: success.data })
        dispatch({ type: MANAGE_ORDERS_OPERATION_ID })
      })
      .catch((error) => {
        dispatch({ type: ERROR_MANAGE_ORDERS, payload: error })
        dispatch({ type: MANAGE_ORDERS_OPERATION_ID })
      })
  }

// https://portalapi.testing.giftify.tech/tag/{tagId}/global-order/{orderRef}/status
export const changeOrderStatus =
  (orderStatus, orderRef) => (dispatch, getState) => {
    dispatch({ type: CHANGING_ORDER_STATUS })

    dispatch({ type: APP_COUNT })

    const { tagID } = getState().tag
    const { manageOrderChangeStatusOperationID } = getState().manageOrders

    const params = {
      status: orderStatus,
    }

    const headers = {
      'operation-id': manageOrderChangeStatusOperationID,
    }

    return issuingAPI
      .put(
        `tag/${tagID}/global-order/${orderRef}/status`,
        { ...params },
        { headers },
      )
      .then((success) => {
        dispatch({ type: CHANGED_ORDER_STATUS, payload: success.data })
      })
      .catch((error) => {
        dispatch({ type: ERROR_CHANGING_ORDER_STATUS, payload: error })
      })
      .finally(() => {
        dispatch({ type: MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID })
      })
  }
