import React, { useMemo } from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from '../../../utils/Constants'
import DropdownIndicator from '../Components/DropdownIndicator'

export default function MerchantListDropdown({ value, onChangeHandler, id }) {
  const { t } = useTranslation()

  const { loadingMerchantList, receivedMerchantList } = useSelector(
    (state) => state.salesByMerchant,
  )

  const options = useMemo(
    () => [{ id: null, name: t('All') }, ...(receivedMerchantList ?? [])],
    [receivedMerchantList, t],
  )

  const customStyles = (value) => ({
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.id === value.id
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      color:
        state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '18px',
    }),
  })

  return (
    <div data-testid={id}>
      <Select
        data-id={id}
        id={id}
        value={value}
        isLoading={loadingMerchantList}
        isDisabled={loadingMerchantList}
        defaultValue={{ id: null, name: t('All') }}
        onChange={onChangeHandler}
        placeholder={'Merchants'}
        styles={customStyles(value)}
        options={options}
        getOptionLabel={(option) => `${option.name}`}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
      />
    </div>
  )
}
