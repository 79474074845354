import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import { Link } from 'react-router-dom'
import DefaultModal from './DefaultModal'
import OrderPurchaserDetails from '../../modules/orders/OrderPurchaserDetails'
import {
  checkValidityOfFromToken,
  CLEAR_CARD_ERRORS,
  FROM_TOKEN_TEXT_COLOR,
  INITIAL_LOAD_CARD,
  RECEIVED_FROM_TOKEN_VALIDITY,
} from '../../actions/LoadCardActions'
import {
  checkLoggedInUserPermissions,
  generateOperationID,
  isNumeric,
} from '../../utils/Helper'
import { issuingAPI } from '../../services/Axios'
import OrderStatusEnum from '../../enums/orderStatus.enum'
import {
  RESET_APP_COUNT,
  RESET_VALIDATION_ERRORS,
} from '../../actions/CommonActions'
import {
  ORDER_PREPARED,
  ORDER_PREPARE_RESET,
  ORDER_PREPARING,
  ORDER_PREPARING_ERROR,
  getManageOrdersByDeliveryMethod,
} from '../../actions/ManageOrdersActions'
import FailAlert from '../Alert/FailAlert'
import SuccessAlert from '../Alert/SuccessAlert'
import ValidationErrors from '../Alert/ValidationErrors'
import CardHolderDetails from '../../modules/orders/CardHolderDetails'
import ButtonLoading from '../../assets/svg/ButtonLoading'
import { GF_DARK_BLUE } from '../../utils/Constants'

export default function CardDetailsModal({
  isOpen,
  cardDetails,
  contentLabel,
  style,
  onClose,
  onPrepare,
}) {
  const { t } = useTranslation()
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag)
  const { fromTokenTextColor, fromTokenValidity } = useSelector(
    (state) => state.loadCard,
  )
  const [token, setToken] = useState('')
  const dispatch = useDispatch()
  const { orderPreparing, orderPrepared, orderPreparingError } = useSelector(
    (state) => state.manageOrders,
  )
  const canAccessCardInformation = useMemo(
    () => checkLoggedInUserPermissions('card-information'),
    [checkLoggedInUserPermissions],
  )
  const { validationErrors } = useSelector((state) => state.commonReducer)
  const canUpdateOrderStatus = useMemo(
    () => checkLoggedInUserPermissions('update-order-status'),
    [checkLoggedInUserPermissions],
  )

  useEffect(() => {
    setToken('')
    dispatch({ type: ORDER_PREPARE_RESET })
    dispatch({ type: RESET_VALIDATION_ERRORS })
    dispatch({ type: INITIAL_LOAD_CARD })
  }, [])

  const handlePrepareOrder = () => {
    if (!(fromTokenValidity && token && cardDetails?.reference)) {
      return
    }

    dispatch({ type: ORDER_PREPARING })

    const headers = {
      'operation-id': generateOperationID(),
    }

    issuingAPI
      .post(
        `tag/${selectedTag.id}/order/${cardDetails.reference}/prepare`,
        { token },
        { headers },
      )
      .then((res) => {
        dispatch({ type: ORDER_PREPARED, payload: res.data })
        onPrepare()
        setToken('')
        setTimeout(() => {
          onClose()
        }, 3000)
      })
      .catch((error) => {
        dispatch({ type: INITIAL_LOAD_CARD })
        setToken('')
        dispatch({ type: ORDER_PREPARING_ERROR, payload: error })
      })
  }

  const gotoCardInfoPage = () => {
    dispatch({ type: RESET_APP_COUNT })
  }

  const tokenChangeHandler = ({ currentTarget }) => {
    const { value } = currentTarget
    if (value !== '') {
      dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' })
      dispatch({ type: CLEAR_CARD_ERRORS })
      setToken(value)
      if (isNumeric(value) && value.length === 9) {
        dispatch(checkValidityOfFromToken(value))
      }
    } else {
      dispatch({ type: RECEIVED_FROM_TOKEN_VALIDITY, payload: false })
    }
  }

  if (!cardDetails) {
    return <></>
  }

  return (
    <DefaultModal isOpen={isOpen} onClose={onClose}>
      <>
        <header className={'border-gray-200 relative mb-5'}>
          <div className={'flex justify-between items-center'}>
            <h1 className={'uppercase font-bold text-md'}>
              {`${t('Card information')} - ${cardDetails.reference}`}
            </h1>
          </div>
        </header>
        <section className={'text-left py-4'}>
          {cardDetails.purchaser && (
            <OrderPurchaserDetails purchaser={cardDetails.purchaser} />
          )}

          {cardDetails.holder && (
            <CardHolderDetails holder={cardDetails.holder} />
          )}

          {cardDetails && (
            <>
              <div className={'mt-6'}>
                <h2
                  className={
                    'font-bold border-b border-gfDarkBlue pb-0.5 inline text-lg'
                  }
                >{`${t('Card')}`}</h2>
                <div className={'flex mt-4'}>
                  <div className={'font-semibold truncate w-48 flex flex-col'}>
                    <span>{t('Gift card value')}:</span>
                    <span className="uppercase">{t('Token')}:</span>
                  </div>
                  <div className={'flex flex-col'}>
                    <p>
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={cardDetails.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        suffix={` ${tagCurrency}`}
                      />
                    </p>
                    <div>
                      {cardDetails.status === OrderStatusEnum.PAID ? (
                        <input
                          type="text"
                          maxLength="9"
                          className={`p-2 border rounded h-10 border-gfPeriwinkle focus:outline-none ${fromTokenTextColor}`}
                          placeholder={t('Enter token')}
                          value={token}
                          onChange={tokenChangeHandler}
                        />
                      ) : (cardDetails.status === OrderStatusEnum.PREPARED ||
                          cardDetails.status === OrderStatusEnum.PICKED_UP) &&
                        cardDetails.token !== '' ? (
                        canAccessCardInformation && (
                          <Link
                            onClick={gotoCardInfoPage}
                            className="underline"
                            to={`/gift-cards/card-information?token=${cardDetails.token}`}
                          >
                            {cardDetails.token}
                          </Link>
                        )
                      ) : (
                        <div>{'-'}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="my-5">
            {orderPreparingError && (
              <FailAlert message={orderPreparingError.message} />
            )}
            {orderPrepared !== null && <SuccessAlert message={t('Success')} />}
            {validationErrors && (
              <div className="py-2">
                <ValidationErrors {...validationErrors} />
              </div>
            )}
          </div>
          {cardDetails.status === OrderStatusEnum.PAID &&
          canUpdateOrderStatus ? (
            <button
              onClick={handlePrepareOrder}
              disabled={!fromTokenValidity}
              className={`${
                fromTokenValidity
                  ? 'bg-opacity-100'
                  : 'pointer-events-none bg-opacity-50'
              } transition bg-gfCoral p-2 px-28 rounded flex flex-row text-center mx-auto mt-6 font-semibold`}
            >
              {orderPreparing && <ButtonLoading color={GF_DARK_BLUE} />}
              {t('Prepare card')}
            </button>
          ) : (
            <button
              onClick={onClose}
              className={
                ' bg-gfPeriwinkle uppercase block py-2 px-28 rounded text-center mx-auto mt-6 font-MulishBold'
              }
            >
              {t('Close')}
            </button>
          )}
        </section>
      </>
    </DefaultModal>
  )
}
