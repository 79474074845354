/* eslint-disable import/no-anonymous-default-export */
import {
  checkEmptyArraysOfSalesByMerchant,
  searchMerchants,
} from '../../utils/Helper'

import {
  SET_MERCHANTS,
  ERROR_MERCHANT_LIST,
  ERROR_MERCHANT_SALES,
  LOADING_MERCHANT_LIST,
  LOADING_MERCHANT_SALES,
  RECEIVED_MERCHANT_LIST,
  RECEIVED_MERCHANT_SALES,
  RESET_MERCHANT_LIST,
  SET_SELECTED_MERCHANT,
  SET_UN_SELECTED_MERCHANT,
  LOOK_FOR_A_MERCHANT,
  SET_FILTER_BY,
} from '../../actions/SalesByMerchantActions'
import { GF_GREY } from '../../utils/Constants'

const initialState = {
  loadingMerchantList: false,
  receivedMerchantList: null,
  errorSalesMerchantList: null,

  loadingSalesByMerchant: false,
  receivedSalesByMerchant: null,
  errorSalesByMerchant: null,

  dates: null,

  selectedMerchants: null,
  unSelectedMerchants: null,
  filteredMerchant: null,

  filterBy: 'month',
  checkSalesEmpty: false,
}

const colors = ['#00adb5', '#766161', '#02475e', '#ff6701', '#206a5d']

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_MERCHANT_LIST:
      return {
        ...state,
        loadingMerchantList: true,
        receivedMerchantList: null,
        errorSalesMerchantList: null,
      }

    case RECEIVED_MERCHANT_LIST:
      const tempList = action.payload
      const tempSelected = tempList.length > 5 ? tempList.slice(0, 5) : tempList // .///map((obj, index) => ({ ...obj, checked: true, color: colors[index] })) : tempList.map((obj, index) => ({ ...obj, checked: true, color: colors[index] }));
      const tempUnSelected =
        tempList.length > 5 ? tempList.slice(5, tempList.length) : [] // .map((obj) => ({ ...obj, checked: false, color: '#393e46' })) : [];
      return {
        ...state,
        loadingMerchantList: false,
        receivedMerchantList: tempList,
        errorSalesMerchantList: null,
        selectedMerchants: tempSelected.map((obj, index) => ({
          ...obj,
          checked: true,
          color: colors[index],
        })),
        unSelectedMerchants: tempUnSelected.map((obj) => ({
          ...obj,
          checked: false,
          color: GF_GREY,
        })),

        filteredMerchant: tempUnSelected.map((obj) => ({
          ...obj,
          checked: false,
          color: GF_GREY,
        })),
      }

    case SET_FILTER_BY:
      return {
        ...state,
        filterBy: action.payload,
      }

    case ERROR_MERCHANT_LIST:
      return {
        ...state,
        loadingMerchantList: false,
        receivedMerchantList: null,
        errorSalesMerchantList: action.payload,
      }

    case LOADING_MERCHANT_SALES:
      return {
        ...state,
        loadingSalesByMerchant: true,
        receivedSalesByMerchant: null,
        errorSalesByMerchant: null,
      }

    case RECEIVED_MERCHANT_SALES:
      const tempSales = action.payload.data
      const hasSales = checkEmptyArraysOfSalesByMerchant(tempSales)
      let uniqueDates = []

      if (hasSales) {
        const datesArray = []

        // get all the dates to an array
        tempSales.forEach(function (item) {
          item.sales?.forEach(function (list) {
            const ob = { date: list.date }
            datesArray.push(ob)
          })
        })

        // filter unique dates from the dates array
        uniqueDates = Object.values(
          datesArray.reduce((a, c) => {
            a[`${a.date}|${c.date}`] = c
            return a
          }, {}),
        )

        // sort the dates array
        uniqueDates.sort((a, b) => new Date(a.date) - new Date(b.date))

        state.selectedMerchants.forEach(function (list) {
          tempSales.forEach(function (sale) {
            if (sale.merchant_id === list.id) {
              sale.color = list.color
            }
          })
        })
      }

      return {
        ...state,
        loadingSalesByMerchant: false,
        receivedSalesByMerchant: tempSales,
        errorSalesByMerchant: null,
        checkSalesEmpty: hasSales,
        dates: uniqueDates,
      }

    case ERROR_MERCHANT_SALES:
      return {
        ...state,
        loadingSalesByMerchant: false,
        receivedSalesByMerchant: null,
        errorSalesByMerchant: action.payload,
      }

    case RESET_MERCHANT_LIST:
      return {
        ...state,
        ...initialState,
      }

    case SET_MERCHANTS:
      return {
        ...state,
        selectedMerchants: action.payload,
      }

    case SET_SELECTED_MERCHANT:
      const selectedItem = action.payload
      let modifiedSelectedItem
      let modifiedLastItem
      let removeUnselected = state.unSelectedMerchants.filter(function (item) {
        return item.id !== selectedItem.id
      })
      if (state.selectedMerchants.length === 5) {
        const lastItem = state.selectedMerchants.pop()
        modifiedSelectedItem = {
          ...selectedItem,
          checked: true,
          color: lastItem.color,
        }
        modifiedLastItem = { ...lastItem, checked: false, color: '#393e46' }
        removeUnselected = [...removeUnselected, modifiedLastItem]
      } else {
        modifiedSelectedItem = {
          ...selectedItem,
          checked: true,
          color: colors[state.selectedMerchants.length],
        }
      }

      return {
        ...state,
        selectedMerchants: [...state.selectedMerchants, modifiedSelectedItem],
        unSelectedMerchants: removeUnselected,
        filteredMerchant: removeUnselected,
      }

    case SET_UN_SELECTED_MERCHANT:
      const unSelectedItem = action.payload
      const modifiedUnSelectedItem = {
        ...unSelectedItem,
        checked: false,
        color: '#393e46',
      }
      const removeSelected = state.selectedMerchants.filter(function (item) {
        return item.id !== unSelectedItem.id
      })

      return {
        ...state,
        selectedMerchants: removeSelected,
        unSelectedMerchants: [
          ...state.unSelectedMerchants,
          modifiedUnSelectedItem,
        ],
        filteredMerchant: [
          ...state.unSelectedMerchants,
          modifiedUnSelectedItem,
        ],
      }

    case LOOK_FOR_A_MERCHANT:
      const fil = searchMerchants(state.unSelectedMerchants, action.payload)

      return {
        ...state,
        filteredMerchant: fil,
      }

    default:
      return state
  }
}
