import {
  enGB,
  de,
  es,
  fr,
  hu,
  pt,
  ro,
  nl,
  sk,
  it,
  fi,
  pl,
  cs,
  lt,
  hr,
} from 'date-fns/locale'
import { registerLocale } from 'react-datepicker'

export function getCalanderLocale(lan) {
  switch (lan.value) {
    case 'en':
      return registerLocale('en', enGB)
    case 'de':
      return registerLocale('de', de)
    case 'fr':
      return registerLocale('fr', fr)
    case 'es':
      return registerLocale('es', es)
    case 'hu':
      return registerLocale('hu', hu)
    case 'pt':
      return registerLocale('pt', pt)
    case 'ro':
      return registerLocale('ro', ro)
    case 'nl':
      return registerLocale('nl', nl)
    case 'sk':
      return registerLocale('sk', sk)
    case 'it':
      return registerLocale('it', it)
    case 'fi':
      return registerLocale('fi', fi)
    case 'pl':
      return registerLocale('pl', pl)
    case 'cs':
      return registerLocale('cs', cs)
    case 'lt':
      return registerLocale('lt', lt)
    case 'hr':
      return registerLocale('hr', hr)
    default:
      break
  }
}

export function getDateLocale(lan) {
  switch (lan.value) {
    case 'en':
      return enGB
    case 'de':
      return de
    case 'fr':
      return fr
    case 'es':
      return es
    case 'hu':
      return hu
    case 'pt':
      return pt
    case 'ro':
      return ro
    case 'nl':
      return nl
    case 'sk':
      return sk
    case 'it':
      return it
    case 'fi':
      return fi
    case 'pl':
      return pl
    case 'cs':
      return cs
    case 'lt':
      return lt
    case 'hr':
      return hr
    default:
      break
  }
}
