import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile } from '../utils/Helper'
import {
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  ERROR_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
} from './CommonActions'

export const PREFIX = '@productReport/'

export const LOADING_PRODUCT_REPORT = `${PREFIX}LOADING_PRODUCT_REPORT`
export const RECEIVED_PRODUCT_REPORT = `${PREFIX}RECEIVED_PRODUCT_REPORT`
export const ERROR_PRODUCT_REPORT = `${PREFIX}ERROR_PRODUCT_REPORT`
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`

export const RESET_PRODUCT_REPORT = `${PREFIX}RESET_PRODUCT_REPORT`

export const getProductReport = (fromDate, toDate) => (dispatch, getState) => {
  dispatch({ type: LOADING_PRODUCT_REPORT })

  const { tagID } = getState().tag
  const params = {
    from: fromDate,
    to: toDate,
  }

  return issuingAPI
    .get(`tag/${tagID}/product`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_PRODUCT_REPORT, payload: success.data })
      return success
    })
    .catch((error) => {
      dispatch({ type: ERROR_PRODUCT_REPORT, payload: error })
      return error
    })
}

export const exportCSVProductReport =
  (fromDate, toDate, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { tagID } = getState().tag
    const params = {
      from: fromDate,
      to: toDate,
    }

    const headers = {
      Accept: 'text/csv',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/product/stream`, { headers, params, data })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT })
        exportCSVFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const exportExcelProductReport =
  (fromDate, toDate, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID } = getState().tag
    const params = {
      from: fromDate,
      to: toDate,
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/product/stream`, {
        responseType: 'blob',
        headers,
        params,
        data,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT })
        exportExcelFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }
