import format from 'date-fns/format'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { Userpilot } from 'userpilot'
import { RESET_CSV_REPORT } from '../../../../actions/CommonActions'
import {
  exportCSVExpiryReport,
  exportExcelExpiryReport,
  getExpiryReport,
  PAGINATE_EXPIRY_LIST,
  RESET_EXPIRY_REPORT,
} from '../../../../actions/ExpiryReportActions'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import { getCalanderLocale } from '../../../../utils/CalanderLocale'
import { GF_DARK_BLUE } from '../../../../utils/Constants'
import FailAlert from '../../../Alert/FailAlert'
import MessageAlert from '../../../Alert/MessageAlert'
import CardInfoWidget from '../../../CardInfoWidget'
import Container from '../../../UIComponents/Container'
import Table from '../../../UIComponents/Table'

export default function ExpiryReport() {
  const dispatch = useDispatch()
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    Userpilot.reload()
    dispatch({ type: RESET_EXPIRY_REPORT })
    dispatch({ type: RESET_CSV_REPORT })

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_EXPIRY_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_EXPIRY_REPORT })
      dispatch({ type: RESET_CSV_REPORT })
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  const {
    loadingExpiryReport,
    receivingExpiryReport,
    errorExpiryReport,
    populatedList,
    summary,
  } = useSelector((state) => state.expiryReport)
  const { tagCurrency } = useSelector((state) => state.tag)
  const { loadingCSV, errorCSV, loadingExcel } = useSelector(
    (state) => state.commonReducer,
  )
  const { selectedLanguage } = useSelector((state) => state.language)

  getCalanderLocale(selectedLanguage)

  const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
    <input
      id={id}
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border cursor-pointer text-center w-full rounded hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 focus:outline-none"
    />
  ))

  const fromDatePickHandler = (date) => {
    setFromDate(date)
  }

  const toDatePickHandler = (date) => {
    setToDate(date)
  }

  const getChartHandler = () => {
    const from = format(fromDate, 'yyyy-MM-dd')
    let to
    if (toDate !== null) {
      to = format(toDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(getExpiryReport(from, to))
  }

  const exportCSV = () => {
    const from = format(fromDate, 'yyyy-MM-dd')
    let to
    if (toDate !== null) {
      to = format(toDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(exportCSVExpiryReport(from, to, 'Expiry Report'))
  }

  const exportExcel = () => {
    const from = format(fromDate, 'yyyy-MM-dd')
    let to
    if (toDate !== null) {
      to = format(toDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(exportExcelExpiryReport(from, to, 'Expiry Report'))
  }

  const handlePageClick = (event) => {
    dispatch({ type: PAGINATE_EXPIRY_LIST, payload: event.selected + 1 })
  }

  return (
    <Container title={t('expiry-report')}>
      <div className="grid grid-cols-1 md:grid-cols-3 items-end gap-5">
        <div className="flex flex-col">
          <div className="flex items-center  py-1">{t('Choose-the-date')}:</div>{' '}
          <ReactDatePicker
            showMonthDropdown
            showYearDropdown
            disabled={loadingExpiryReport}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={fromDate}
            onChange={(date) => fromDatePickHandler(date)}
            locale={selectedLanguage.value}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center  py-1">
            {`${t('End-date')}(${t('Optional')})`}:
          </div>{' '}
          <ReactDatePicker
            showMonthDropdown
            showYearDropdown
            disabled={loadingExpiryReport}
            dateFormat="dd/MM/yyyy"
            selected={toDate}
            onChange={(date) => toDatePickHandler(date)}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
          />
        </div>

        <button
          id="goButton"
          disabled={loadingExpiryReport}
          onClick={getChartHandler}
          className="h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
        >
          {loadingExpiryReport && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Go')}!
        </button>
      </div>
      {((receivingExpiryReport && receivingExpiryReport.length === 0) ||
        errorExpiryReport) && (
        <div className="p-5">
          {receivingExpiryReport && receivingExpiryReport.length === 0 && (
            <MessageAlert
              message={t('There-are-no-information-to-display-for-this-date')}
            />
          )}
          {errorExpiryReport && (
            <FailAlert message={errorExpiryReport.message} />
          )}
          {errorCSV && <FailAlert message={errorCSV.message} />}
        </div>
      )}

      {receivingExpiryReport && receivingExpiryReport.length !== 0 && (
        <div className="flex flex-col mt-20 space-y-5">
          <div className="grid grid-cols-1  md:grid-cols-2 gap-5 md:gap-20">
            <CardInfoWidget
              bottomLabel={t('Number-of-cards')}
              isCurrency={false}
              value={summary.total_cards}
              labelColor={'text-gfDarkBlue'}
              valueColor={'text-gfDarkBlue'}
            />
            <CardInfoWidget
              bottomLabel={t('Breakage')}
              isCurrency
              value={summary.total_breakage}
              labelColor={'text-gfLightBlue'}
              valueColor={'text-gfLightBlue'}
            />
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
            <div className="flex flex-row">
              <button
                id="exportCsvButton"
                onClick={exportCSV}
                className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-CSV-file')}
              </button>

              <button
                id="exportExcelButton"
                onClick={exportExcel}
                className="ml-3 px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
              >
                {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('export-excel')}
              </button>
            </div>
            {receivingExpiryReport.length >
              window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
              <div>
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    receivingExpiryReport.length /
                    window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </div>

          <Table>
            <thead className="bg-gfGrey font-MulishBlack text-gfDarkBlue text-sm">
              <tr>
                <th
                  scope="col"
                  className="md:w-48 px-4 py-3  text-center  uppercase tracking-wider"
                >
                  {t('Token')}
                </th>
                <th
                  scope="col"
                  className="md:w-48 px-2 py-3 w-32  text-center  uppercase tracking-wider"
                >
                  {t('Expiry-date')}
                </th>

                <th
                  scope="col"
                  className="md:w-48 px-2 py-3  text-right  uppercase tracking-wider"
                >
                  {t('Breakage')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gfPeriwinkle">
              {receivingExpiryReport &&
                populatedList &&
                populatedList.map((product) => (
                  <tr key={product.token}>
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        {product.token}
                      </div>
                    </td>
                    <td className="px-2 py-4 ">
                      <div className="flex justify-center items-center">
                        {product.expiration_date}
                      </div>
                    </td>
                    <td className="px-2 py-4 text-sm ">
                      <div className="flex justify-end text-gfLightBlue font-MulishBold">
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={product.balance_available}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <span className="ml-2">{` ${tagCurrency}`}</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  )
}
