import i18n from 'i18next'
import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile, randomString } from '../utils/Helper'
import {
  APP_COUNT,
  ERROR_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  SUCCESS_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
} from './CommonActions'

export const PREFIX = '@cardTransfer/'
// USER LIST
export const LOADING_TRANSFER_USER_LIST = `${PREFIX}LOADING_TRANSFER_USER_LIST`
export const RECEIVED_TRANSFER_USER_LIST = `${PREFIX}RECEIVED_TRANSFER_USER_LIST`
export const ERROR_TRANSFER_USER_LIST = `${PREFIX}ERROR_TRANSFER_USER_LIST`

// CARD TRANSFER LIST
export const LOADING_CARD_TRANSFER_LIST = `${PREFIX}LOADING_CARD_TRANSFER_LIST`
export const RECEIVED_CARD_TRANSFER_LIST = `${PREFIX}RECEIVED_CARD_TRANSFER_LIST`
export const ERROR_CARD_TRANSFER_LIST = `${PREFIX}ERROR_CARD_TRANSFER_LIST`
export const PAGINATE_TRANSFER_LIST = `${PREFIX}PAGINATE_TRANSFER_LIST`

// CARD TRANSFER DESK LIST
export const LOADING_CARD_TRANSFER_DESK_LIST = `${PREFIX}LOADING_CARD_TRANSFER_DESK_LIST`
export const RECEIVED_CARD_TRANSFER_DESK_LIST = `${PREFIX}RECEIVED_CARD_TRANSFER_DESK_LIST`
export const ERROR_CARD_TRANSFER_DESK_LIST = `${PREFIX}ERROR_CARD_TRANSFER_DESK_LIST`

// FROM TOKEN
export const LOADING_FROM_TOKEN = `${PREFIX}LOADING_FROM_TOKEN`
export const RECEIVED_FROM_TOKEN = `${PREFIX}RECEIVED_FROM_TOKEN`
export const ERROR_FROM_TOKEN = `${PREFIX}ERROR_FROM_TOKEN`

export const RESET_FROM_TOKEN = `${PREFIX}RESET_FROM_TOKEN`

// TO TOKEN
export const LOADING_TO_TOKEN = `${PREFIX}LOADING_TO_TOKEN`
export const RECEIVED_TO_TOKEN = `${PREFIX}RECEIVED_TO_TOKEN`
export const ERROR_TO_TOKEN = `${PREFIX}ERROR_TO_TOKEN`

export const RESET_TO_TOKEN = `${PREFIX}RESET_TO_TOKEN`

// TRANSFER & TERMINATE
export const SENDING_TRANSFER_AND_TERMINATE_CARD = `${PREFIX}SENDING_TRANSFER_AND_TERMINATE_CARD`
export const SUCCESS_TRANSFER_AND_TERMINATE_CARD = `${PREFIX}SUCCESS_TRANSFER_AND_TERMINATE_CARD`
export const ERROR_TRANSFER_AND_TERMINATE_CARD = `${PREFIX}ERROR_TRANSFER_AND_TERMINATE_CARD`

export const RESET_TRANSFER = 'RESET_TRANSFER'

export const getTransfers =
  (fromDate, toDate, userIds, deskIds, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_TRANSFER_LIST })

    const { tagID } = getState().tag

    const params = {
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      page,
      from_date: fromDate,
    }

    if (toDate !== null) {
      Object.assign(params, { to_date: toDate })
    }

    if (userIds !== -1) {
      Object.assign(params, { user_ids: userIds })
    }

    if (deskIds !== -1) {
      Object.assign(params, { desk_ids: deskIds })
    }

    return issuingAPI
      .get(`/tag/${tagID}/transfer`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_CARD_TRANSFER_LIST, payload: success.data })
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_TRANSFER_LIST, payload: error })
        return error
      })
  }

export const getTransfersPaginate =
  (fromDate, toDate, userIds, deskIds, page) => (dispatch, getState) => {
    dispatch({ type: PAGINATE_TRANSFER_LIST })

    const { tagID } = getState().tag

    const params = {
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      page,
      from_date: fromDate,
      to_date: toDate,
    }

    if (userIds !== -1) {
      Object.assign(params, { user_ids: userIds })
    }

    if (deskIds !== -1) {
      Object.assign(params, { desk_ids: deskIds })
    }

    return issuingAPI
      .get(`/tag/${tagID}/transfer`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_CARD_TRANSFER_LIST, payload: success.data })
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_TRANSFER_LIST, payload: error })
        return error
      })
  }

export const getUserList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_TRANSFER_USER_LIST })

  dispatch({ type: APP_COUNT })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/user`)
    .then((success) => {
      success.data = [
        ...success.data,
        { id: -1, firstName: i18n.t('All'), lastName: '' },
      ]
      dispatch({ type: RECEIVED_TRANSFER_USER_LIST, payload: success.data })
      dispatch(getDeskList())
    })
    .catch((error) => {
      console.log(error)
      dispatch({ type: ERROR_TRANSFER_USER_LIST, payload: error })
      return error
    })
}

export const getDeskList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CARD_TRANSFER_DESK_LIST })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/desk`)
    .then((success) => {
      dispatch({
        type: RECEIVED_CARD_TRANSFER_DESK_LIST,
        payload: success.data,
      })
    })
    .catch((error) => {
      dispatch({ type: ERROR_CARD_TRANSFER_DESK_LIST, payload: error })
      return error
    })
}

export const getFromToken = (token) => (dispatch, getState) => {
  dispatch({ type: LOADING_FROM_TOKEN })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/card/${token}`)
    .then((success) => {
      dispatch({ type: RECEIVED_FROM_TOKEN, payload: success.data })
    })
    .catch((error) => {
      dispatch({ type: ERROR_FROM_TOKEN, payload: error })
      return error
    })
}

export const getToToken = (token) => (dispatch, getState) => {
  dispatch({ type: LOADING_TO_TOKEN })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/card/${token}`)
    .then((success) => {
      dispatch({ type: RECEIVED_TO_TOKEN, payload: success.data })
    })
    .catch((error) => {
      dispatch({ type: ERROR_TO_TOKEN, payload: error })
      return error
    })
}

export const OPEN_CARD_TRANSFER_TICKET_PRINT = `${PREFIX}OPEN_CARD_TRANSFER_TICKET_PRINT`
export const TRANSFER_AND_TERMINATE_OPERATION_ID = `${PREFIX}TRANSFER_AND_TERMINATE_OPERATION_ID`

export const transferAndTerminateCard =
  (fromToken, toToken, applyFees, description) => (dispatch, getState) => {
    dispatch({ type: SENDING_TRANSFER_AND_TERMINATE_CARD })

    const { tagID } = getState().tag
    const { transferAndTerminateCardOperationID } = getState().cardTransfer

    const headers = { 'operation-id': transferAndTerminateCardOperationID }

    return issuingAPI
      .post(
        `/tag/${tagID}/card/${fromToken}/terminate/${toToken}`,
        {
          apply_fees: applyFees,
          description,
        },
        { headers },
      )
      .then((success) => {
        dispatch({
          type: SUCCESS_TRANSFER_AND_TERMINATE_CARD,
          payload: success.data,
        })
        dispatch({ type: TRANSFER_AND_TERMINATE_OPERATION_ID })
        const evt = new CustomEvent(OPEN_CARD_TRANSFER_TICKET_PRINT, {
          detail: success.data,
        })
        window.dispatchEvent(evt)
      })
      .catch((error) => {
        dispatch({ type: ERROR_TRANSFER_AND_TERMINATE_CARD, payload: error })
        dispatch({ type: TRANSFER_AND_TERMINATE_OPERATION_ID })
        return error
      })
  }

export const exportCSVCardTransferReport =
  (fromDate, toDate, deskID, userID, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT })

    const { tagID } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    if (userID !== -1) {
      Object.assign(params, { user_ids: userID })
    }

    if (deskID !== -1) {
      Object.assign(params, { user_ids: deskID })
    }

    const headers = {
      Accept: 'text/csv',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/transfer/stream`, { headers, params, data })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT })
        exportCSVFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error })
        return error
      })
  }

export const exportExcelCardTransferReport =
  (fromDate, toDate, deskID, userID, reportName) => (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT })

    const { tagID } = getState().tag

    const params = {
      from_date: fromDate,
      to_date: toDate,
    }

    if (userID !== -1) {
      Object.assign(params, { user_ids: userID })
    }

    if (deskID !== -1) {
      Object.assign(params, { user_ids: deskID })
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    const data = null

    const fileName = `${reportName}-${fromDate}-${toDate}`

    return issuingAPI
      .get(`tag/${tagID}/transfer/stream`, {
        responseType: 'blob',
        headers,
        params,
        data,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT })
        exportExcelFile(success.data, fileName)
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
        return error
      })
  }

// https://portalapi.dev.giftify.me/tag/{tagId}/transfer/stream
