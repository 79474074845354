/* eslint-disable import/no-anonymous-default-export */
import {
  ACQUIRING_AUTHENTICATION,
  AUTHENTICATION_ERROR,
  CLEAR_LOCAL_STORAGE,
  CREATED_LOGIN_USER_SETTINGS,
  CREATING_LOGIN_USER_SETTINGS,
  ERROR_ACCESS_TOKEN,
  ERROR_LOGIN_USER_SETTINGS,
  ERROR_LOGIN_USER_TAG_PERMISSION,
  ERROR_USER,
  FAIL_CREATING_LOGIN_USER_SETTINGS,
  LOADING_ACCESS_TOKEN,
  LOADING_LOGIN_USER_SETTINGS,
  LOADING_LOGIN_USER_TAG_PERMISSION,
  LOADING_USER,
  RECEIVED_ACCESS_TOKEN,
  RECEIVED_LOGIN_USER_SETTINGS,
  RECEIVED_LOGIN_USER_TAG_PERMISSION,
  RECEIVED_USER,
  RESET_CREATING_LOGIN_USER_SETTINGS,
  TIMEOUT_LOGOUT,
  USER_LOGOUT,
} from '../actions/AuthActions'
import { checkAdminPermissions, checkUserHasAdminView } from '../utils/Helper'

const initialState = {
  loadingAuthentication: false,
  authenticationError: null,

  receivedAccessTokenObject: null,
  errorAccessToken: null,

  isAuthenticated: null,

  user: null,
  errorUser: null,
  userPermissions: null,

  isTimeoutLogout: false,

  hasAdminPrivilege: false,

  loadingLoggedInUserTagPermissions: false,
  receivedLoggedInUserTagPermissions: null,
  errorLoggedInUserTagPermissions: null,

  addUserPermissions: null,
  editUserPermissions: null,

  isSuperAdmin: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ACQUIRING_AUTHENTICATION:
    case LOADING_ACCESS_TOKEN:
      localStorage.setItem('auth_token', action.payload)
      return {
        ...state,
        loadingAuthentication: true,
        receivedAccessTokenObject: null,
        errorAccessToken: null,
      }

    case RECEIVED_ACCESS_TOKEN:
      localStorage.setItem('access_token', action.payload.access_token)
      return {
        ...state,
        receivedAccessTokenObject: action.payload,
        errorAccessToken: null,
      }

    case ERROR_ACCESS_TOKEN:
      return {
        ...state,
        loadingAuthentication: false,
        receivedAccessTokenObject: null,
        errorAccessToken: action.payload,
      }

    case LOADING_USER:
      return {
        ...state,
        loadingAuthentication: true,
        user: null,
        errorUser: null,
      }

    case RECEIVED_USER:
      return {
        ...state,
        isAuthenticated: true,
        loadingAuthentication: false,
        user: action.payload,
        userPermissions: action.payload.permissions,
        isSuperAdmin: action.payload.super_admin,
        errorUser: null,
      }

    case LOADING_LOGIN_USER_TAG_PERMISSION:
      return {
        ...state,
        loadingLoggedInUserTagPermissions: true,
        receivedLoggedInUserTagPermissions: null,
        errorLoggedInUserTagPermissions: null,
      }

    case RECEIVED_LOGIN_USER_TAG_PERMISSION:
      const temp = action.payload

      temp.forEach((permission) => {
        permission.list.forEach((item) => {
          item.isChecked = false
          item.disabled = true
        })
      })

      return {
        ...state,
        loadingLoggedInUserTagPermissions: false,
        receivedLoggedInUserTagPermissions: action.payload,
        hasAdminPrivilege: checkUserHasAdminView(action.payload),
        addUserPermissions: temp,
        editUserPermissions: temp,
        errorLoggedInUserTagPermissions: null,
      }

    case ERROR_LOGIN_USER_TAG_PERMISSION:
      return {
        ...state,
        loadingLoggedInUserTagPermissions: false,
        receivedLoggedInUserTagPermissions: null,
        errorLoggedInUserTagPermissions: action.payload,
      }

    case ERROR_USER:
      localStorage.removeItem('access_token')
      localStorage.removeItem('auth_token')
      return {
        ...state,
        isAuthenticated: false,
        loadingAuthentication: false,
        user: null,
        userPermissions: null,
        errorUser: action.payload,
      }

    case AUTHENTICATION_ERROR:
      localStorage.removeItem('access_token')
      localStorage.removeItem('auth_token')
      localStorage.removeItem('view')
      // localStorage.removeItem("i18nextLng");
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loadingAuthentication: false,
        userPermissions: null,
        authenticationError: action.payload,
      }

    case USER_LOGOUT:
      localStorage.removeItem('access_token')
      localStorage.removeItem('auth_token')
      localStorage.removeItem('view')
      localStorage.setItem('path', action.payload)
      // localStorage.removeItem("i18nextLng");
      return {
        ...state,
        loadingAuthentication: false,
        authenticationError: null,

        receivedAccessTokenObject: null,
        errorAccessToken: null,

        isAuthenticated: null,

        user: null,
        errorUser: null,
        userPermissions: null,
      }

    case TIMEOUT_LOGOUT:
      return {
        ...state,
        isTimeoutLogout: action.payload,
      }

    case CLEAR_LOCAL_STORAGE:
      localStorage.removeItem('access_token')
      localStorage.removeItem('auth_token')
      localStorage.removeItem('view')
      localStorage.removeItem('path')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('expires_in')
      localStorage.removeItem('refresh_in')
      localStorage.removeItem('i18nextLng')
      localStorage.removeItem('tl-id')
      localStorage.removeItem('tg-id')
      localStorage.removeItem('reference')
      return {
        ...state,
        ...initialState,
      }

    case LOADING_LOGIN_USER_SETTINGS:
      return {
        ...state,
        loadingUserSettings: true,
        userSettings: null,
        errorUserSettings: null,
      }

    case RECEIVED_LOGIN_USER_SETTINGS:
      return {
        ...state,
        loadingUserSettings: false,
        userSettings: action.payload.settings,
        errorUserSettings: null,
      }

    case ERROR_LOGIN_USER_SETTINGS:
      return {
        ...state,
        loadingUserSettings: false,
        userSettings: null,
        errorUserSettings: action.payload,
      }

    case CREATING_LOGIN_USER_SETTINGS:
      return {
        ...state,
        creatingUserSettings: true,
        successUserSettings: null,
        failUserSettings: null,
      }

    case CREATED_LOGIN_USER_SETTINGS:
      return {
        ...state,
        creatingUserSettings: false,
        successUserSettings: action.payload,
        failUserSettings: null,
      }

    case FAIL_CREATING_LOGIN_USER_SETTINGS:
      return {
        ...state,
        creatingUserSettings: false,
        successUserSettings: null,
        failUserSettings: action.payload,
      }

    case RESET_CREATING_LOGIN_USER_SETTINGS:
      return {
        ...state,
        creatingUserSettings: false,
        successUserSettings: null,
        failUserSettings: null,
      }

    case CLEAR_LOCAL_STORAGE:
      localStorage.removeItem('access_token')
      localStorage.removeItem('auth_token')
      localStorage.removeItem('view')
      localStorage.removeItem('path')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('expires_in')
      localStorage.removeItem('refresh_in')
      localStorage.removeItem('i18nextLng')
      localStorage.removeItem('tl-id')
      localStorage.removeItem('tg-id')
      localStorage.removeItem('reference')
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}
