import React, { forwardRef } from 'react'
import Select from 'react-select'
import DropDownStyle from '../Dropdown/Components/DropDownStyle'

const SelectField = forwardRef(function ({ ...props }, ref) {
  return (
    <Select
      inputRef={ref}
      styles={DropDownStyle((option) => option)}
      components={{
        IndicatorSeparator: null,
      }}
      {...props}
    />
  )
})

export default SelectField
