import { issuingAPI } from '../services/Axios'
import { exportCSVFile, exportExcelFile } from '../utils/Helper'
import {
  APP_COUNT,
  ERROR_CSV_REPORT,
  ERROR_EXCEL_REPORT,
  LOADING_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  SUCCESS_CSV_REPORT,
} from './CommonActions'

export const PREFIX = '@blockedCardsReport/'

export const LOADING_BLOCKED_CARD_REPORT = `${PREFIX}LOADING_BLOCKED_CARD_REPORT`
export const RECEIVED_BLOCKED_CARD_REPORT = `${PREFIX}RECEIVED_BLOCKED_CARD_REPORT`
export const ERROR_BLOCKED_CARD_REPORT = `${PREFIX}ERROR_BLOCKED_CARD_REPORT`
export const POPULATED_BLOCKED_CARD_REPORT = `${PREFIX}POPULATED_BLOCKED_CARD_REPORT`

export const getBlockedCards = () => (dispatch, getState) => {
  dispatch({ type: LOADING_BLOCKED_CARD_REPORT })

  const { tagID } = getState().tag

  dispatch({ type: APP_COUNT })

  return issuingAPI
    .get(`/tag/${tagID}/card/blocked-stock`)
    .then((success) => {
      dispatch({ type: RECEIVED_BLOCKED_CARD_REPORT, payload: success.data })
    })
    .catch((error) => {
      dispatch({ type: ERROR_BLOCKED_CARD_REPORT, payload: error })
      return error
    })
}

export const getBlockedCardsReportCSV = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT })

  const { tagID } = getState().tag

  const headers = {
    Accept: 'text/csv',
  }

  const fileName = `Blocked-Cards-Report`

  return issuingAPI
    .get(`tag/${tagID}/card/blocked-stock/stream`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT })
      exportCSVFile(success.data, fileName)
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error })
      return error
    })
}

export const getBlockedCardsReportExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT })

  const { tagID } = getState().tag

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }

  const fileName = `Blocked-Cards-Report`

  return issuingAPI
    .get(`tag/${tagID}/card/blocked-stock/stream`, {
      responseType: 'blob',
      headers,
    })
    .then((success) => {
      dispatch({ type: SUCCESS_EXCEL_REPORT })
      exportExcelFile(success.data, fileName)
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error })
      return error
    })
}
