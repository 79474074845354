import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { issuingAPI } from '../services/Axios'
import { isNumeric } from '../utils/Helper'
import Card from '../enums/card.enum'

export default function useTokenValidity({ tokens = [] }) {
  const { selectedTag } = useSelector((state) => state.tag)
  const { t } = useTranslation()

  async function validateToken(value) {
    if (!isNumeric(value)) {
      return false
    }

    if (value.length !== Card.TOKEN_LENGTH) {
      return false
    }

    if (tokens.includes(value)) {
      return t('Token-already-exist')
    }

    try {
      const response = await issuingAPI.get(
        `/tag/${selectedTag.id}/validate-token`,
        {
          params: {
            token: value,
          },
        },
      )

      return response.data.valid
    } catch (error) {
      return false
    }
  }

  async function getTokenRange(from, to) {
    try {
      const response = await issuingAPI.get(
        `/tag/${selectedTag.id}/validate-token-range`,
        {
          params: {
            from,
            to,
          },
        },
      )

      return response.data.data
    } catch (error) {
      return []
    }
  }

  return {
    validateToken,
    getTokenRange,
  }
}
