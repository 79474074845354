import { useSelector } from 'react-redux'

export default function useAdditionalProducts() {
  const { additionalProducts: products } = useSelector(
    (state) => state.loadCard,
  )

  function findById(id) {
    return products.find((product) => product.id === id)
  }

  return {
    products,
    findById,
  }
}
