/* eslint-disable import/no-anonymous-default-export */
import { SELECTED_LANGUAGE } from '../actions/LanguageAction'

const languagesLocal = [
  { value: 'cs', label: 'CS' },
  { value: 'de', label: 'DE' },
  { value: 'en', label: 'EN' },
  { value: 'es', label: 'ES' },
  { value: 'fi', label: 'FI' },
  { value: 'fr', label: 'FR' },
  { value: 'hu', label: 'HU' },
  { value: 'hr', label: 'HR' },
  { value: 'it', label: 'IT' },
  { value: 'lt', label: 'LT' },
  { value: 'nl', label: 'NL' },
  { value: 'pl', label: 'PL' },
  { value: 'pt', label: 'PT' },
  { value: 'ro', label: 'RO' },
  { value: 'sk', label: 'SK' },
  { value: 'sr', label: 'SR' },
]

const initialState = {
  selectedLanguage: { value: 'en', label: 'EN' },
  languages: languagesLocal,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: state.languages.find(
          (lang) => lang.value === action.payload,
        ),
      }

    default:
      return state
  }
}
