import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../../../UIComponents/Container'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../../Form'
import CloseIcon from '../../../../assets/svg/CloseIcon'
import { issuingAPI } from '../../../../services/Axios'
import { Step, Steps } from '../../../Step'
import {
  ERROR_LOAD_CARDS,
  ERROR_TICKET_INFO,
  getAdditionalProducts,
  getTicketNumber,
  INITIAL_LOAD_CARD,
  LOADING_LOAD_CARDS,
  LOADING_TICKET_INFO,
  OPEN_TICKET_PRINT,
  RECEIVED_TICKET_INFO,
  SUCCESS_LOAD_CARDS,
} from '../../../../actions/LoadCardActions'
import TokenForm from './TokenForm'
import { Userpilot } from 'userpilot'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import LoadCardTicketModal from '../../../Modal/LoadCardTicketModal'
import PaymentMethodField from '../../Orders/PaymentMethodField'
import DeskField from '../../Orders/DeskField'
import ChannelField from '../../Orders/ChannelField'
import { Card, CardFooter, CardTitle } from '../../../Card'
import Money from '../../../Money'
import MessageAlert from '../../../Alert/MessageAlert'
import AdditionalProductsField from './AdditionalProductsField'
import useAdditionalProducts from '../../../../hooks/useAdditionalProducts'
import useLoadCard from '../../../../hooks/useLoadCard'
import CustomerInformationField from '../../Orders/CustomerInformationField'
import useCustomerFields from '../../../../hooks/useCustomerFields'

const OrderSummary = ({ lineItems, totalAmount }) => {
  const { tagCurrency } = useSelector((state) => state.tag)
  const { t } = useTranslation()

  return (
    <Card>
      <CardTitle>{t('place-b2b-order.order-details')}</CardTitle>
      {lineItems.map((lineItem, index) => (
        <div key={index} className="flex flex-row justify-between items-center">
          <div>
            <span>{lineItem.name}</span>
            {lineItem.description && (
              <div className="text-xs">({lineItem.description})</div>
            )}
          </div>
          <div className="flex flex-row space-x-2">
            <Money value={lineItem.amount} currency={lineItem.currency} />
            {lineItem.onRemove && (
              <button onClick={lineItem.onRemove}>
                <CloseIcon w={16} h={17} />
              </button>
            )}
          </div>
        </div>
      ))}
      <CardFooter className="font-MulishBold mr-6">
        {t('Total')}: <Money value={totalAmount} currency={tagCurrency} />
      </CardFooter>
    </Card>
  )
}

export default function LoadCard() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [checkAmounts, setCheckAmounts] = useState(false)
  const { products } = useAdditionalProducts()
  const { hasCustomerFields } = useCustomerFields()
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag)
  const { appCount } = useSelector((state) => state.commonReducer)
  const {
    ticketNumber,
    loadCardOperationID,
    deafultChannel,
    channels,
    desks,
    defaultDesk,
    loadingLoadCards,
  } = useSelector((state) => state.loadCard)
  const { selectedTerminal } = useSelector((state) => state.terminal)
  const [isOpenTicketModal, setIsOpenTicketModal] = useState(false)
  const [resetTokenFormFunction, setResetTokenFormFunction] = useState(() => {})
  const [hasInvalidTokens, setHasInvalidTokens] = useState(false)

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      cards: [],
      fields: {},
      paymentMethod: [{ method_id: '', amount: '' }],
      additionalProducts: [],
      desk: '',
      channel: '',
    },
  })

  const { setValue } = methods

  useEffect(() => {
    Userpilot.reload()

    if (appCount === 0) {
      dispatch({ type: INITIAL_LOAD_CARD })
      dispatch(getTicketNumber())
      dispatch(getAdditionalProducts())
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: INITIAL_LOAD_CARD })
      dispatch(getTicketNumber())
      dispatch(getAdditionalProducts())
    })

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: INITIAL_LOAD_CARD })
      dispatch(getTicketNumber())
      dispatch(getAdditionalProducts())
    })

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
      window.removeEventListener(OPEN_TICKET_PRINT, () => {})
    }
  }, [])

  useEffect(() => {
    if (deafultChannel) {
      setValue('channel', deafultChannel, { shouldValidate: true })
    }
  }, [deafultChannel, setValue])

  useEffect(() => {
    if (defaultDesk) {
      setValue('desk', defaultDesk, { shouldValidate: true })
    }
  }, [defaultDesk, setValue])

  const cards = useFieldArray({ name: 'cards', control: methods.control })
  const paymentMethod = useFieldArray({
    name: 'paymentMethod',
    control: methods.control,
  })
  const { isValid } = methods.formState
  const onRemoveCard = useCallback((index) => cards.remove(index), [cards])

  const { lineItems, totalAmount, isTotalAmountCovered } = useLoadCard({
    cards: methods.watch('cards'),
    additionalProducts: methods.watch('additionalProducts'),
    paymentMethods: methods.watch('paymentMethod'),
    onRemoveCard,
  })

  const addedTokens = useMemo(
    () => cards.fields.map((card) => card.token),
    [cards],
  )

  const onSubmit = (data) => {
    setCheckAmounts(false)

    if (isTotalAmountCovered === false) {
      setCheckAmounts(true)

      return
    }

    if (data.paymentMethod.length === 1) {
      data.paymentMethod[0].amount = totalAmount
    }

    const setPaymentMethods = data.paymentMethod.map(
      ({ amount, method_id }) => {
        return {
          amount: parseFloat(amount).toFixed(2),
          method_id,
        }
      },
    )

    const setTokens = data.cards.map(({ amount, token }) => {
      return {
        amount,
        token,
        valid: true, // requested by the backend
      }
    })

    const setDesk = data.desk?.id || 0

    const setChannel = data.channel?.id || 0

    const fields = Object.keys(data.fields)
      .map((id) => ({
        field_id: parseInt(id),
        value: data.fields[id],
      }))
      .filter((field) => field.value !== '')

    const setAdditionalProducts =
      data.additionalProducts?.map((item) => {
        return {
          product_id: parseInt(item.product),
          quantity: parseInt(item.quantity),
        }
      }) || []

    const purchaseFee = selectedTag.purchase_fee?.amount_with_vat ?? null

    loadCards(
      setTokens,
      setPaymentMethods,
      fields,
      setAdditionalProducts,
      setChannel,
      setDesk,
      purchaseFee,
    )
  }

  const loadCards = (
    tokens,
    selectedPaymentMethods,
    selectedFields,
    selectedAdditionalProducts,
    selectedChannel,
    selectedDesk,
    purchaseFee,
  ) => {
    const headers = { 'operation-id': loadCardOperationID }
    let payload = {
      payments: selectedPaymentMethods,
      tokens: tokens,
      fields: selectedFields,
      additional_products: selectedAdditionalProducts,
      channel: selectedChannel,
      desk: selectedDesk,
    }

    if (selectedTag.purchase_fee_enabled) {
      payload = { ...payload, purchase_fee: purchaseFee }
    }
    dispatch({ type: LOADING_LOAD_CARDS })
    setIsOpenTicketModal(true)
    issuingAPI
      .post(
        `/tag/${selectedTag.id}/ticket/${ticketNumber}/load-card/with-fee?terminal_id=${selectedTerminal?.id}`,
        payload,
        { headers },
      )
      .then((response) => {
        dispatch({ type: SUCCESS_LOAD_CARDS, payload: response.data.ticket })
        showTicketInfo()
      })
      .catch((error) => {
        dispatch({ type: ERROR_LOAD_CARDS, payload: error })
      })
  }

  const showTicketInfo = () => {
    dispatch({ type: LOADING_TICKET_INFO })
    issuingAPI
      .get(`/tag/${selectedTag.id}/ticket/${ticketNumber}`)
      .then((response) => {
        dispatch({ type: RECEIVED_TICKET_INFO, payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: ERROR_TICKET_INFO, payload: error })
      })
  }

  function onAddCard(tokens, invalidTokens) {
    for (const token of tokens) {
      cards.append(token)
    }

    if (invalidTokens.length > 0) {
      setHasInvalidTokens(true)
    }
  }

  function onRequestingTokenRange() {
    setHasInvalidTokens(false)
  }

  function onReset() {
    setHasInvalidTokens(false)
    resetTokenFormFunction()
    methods.reset()
  }

  const shouldSelectDesk = useMemo(() => desks?.length > 1, [desks])
  const shouldSelectChannel = useMemo(() => channels?.length > 1, [channels])
  const hasAdditionalProducts = useMemo(() => products?.length > 0, [products])

  return (
    <Container title={t('Load Card')}>
      <Form {...methods}>
        <Steps className="grid grid-cols-4 gap-x-4">
          <div className="col-span-full sm:col-span-3 xl:col-span-2 flex flex-col">
            {hasInvalidTokens && (
              <div className="mb-4">
                <MessageAlert
                  message={t('add-tokens.invalid-tokens.description')}
                />
              </div>
            )}
            <Step
              title={t(
                'Enter Gift Card TOKEN & amount or swipe the card in the magstripe reader',
              )}
              required
            >
              <TokenForm
                onSetReset={(callback) => {
                  setResetTokenFormFunction(() => callback)
                }}
                onRequestingTokenRange={onRequestingTokenRange}
                onAdd={onAddCard}
                tokens={addedTokens}
              />
              {cards.fields.map((card, index) => (
                <div key={card.id} hidden>
                  <Controller
                    name={`cards.${index}.token`}
                    defaultValue={card.token}
                    render={(field) => <input type="hidden" {...field} />}
                  />
                  <Controller
                    name={`cards.${index}.amount`}
                    defaultValue={card.amount}
                    render={(field) => <input type="hidden" {...field} />}
                  />
                </div>
              ))}
            </Step>
            <div className="flex flex-col">
              {selectedTag.purchase_fee_enabled && (
                <Step title={t('place-b2b-order.purchase-fee.title')}>
                  <div>
                    <div className="justify-start p-3 rounded-md inline-flex bg-gray-200">
                      <Money
                        value={selectedTag.purchase_fee?.amount_with_vat}
                        currency={tagCurrency}
                      />
                    </div>
                  </div>
                </Step>
              )}
              {hasAdditionalProducts && (
                <Step title={t('Additional-products')}>
                  <AdditionalProductsField />
                </Step>
              )}
              {hasCustomerFields && (
                <Step
                  title={t('place-b2b-order.enter-customer-information')}
                  required
                >
                  <CustomerInformationField />
                </Step>
              )}
              <Step title={t('place-b2b-order.select-payment-method')} required>
                <PaymentMethodField paymentMethod={paymentMethod} />
              </Step>
              {shouldSelectChannel && (
                <Step title={t('Channel')} required>
                  <ChannelField />
                </Step>
              )}
              {shouldSelectDesk && (
                <Step title={t('Desk')} required>
                  <DeskField />
                </Step>
              )}
              <Step isLast={true} title={t('place-b2b-order.comments')}>
                <FormField
                  name="additional_comment"
                  defaultValue={''}
                  render={(field) => (
                    <FormItem>
                      <FormControl>
                        <textarea
                          data-testid="additional_comment"
                          {...field}
                          className="rounded-md border border-gfPeriwinkle p-2 focus:outline-none focus:border-gfPeriwinkle"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </Step>
            </div>
            <div>
              {checkAmounts && (
                <div className="border rounded-md text-gfCoral border-gfCoral bg-gfCoral bg-opacity-25 p-4">
                  {t(
                    'The-input-amount-is-does-not-correspond-to-the-total-of-the-purchase',
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between col-start-4">
            <div>
              {cards.fields.length !== 0 && (
                <OrderSummary lineItems={lineItems} totalAmount={totalAmount} />
              )}
            </div>

            <div className="flex flex-row justify-end gap-5">
              <button
                onClick={methods.handleSubmit(onSubmit)}
                data-testid="formSubmitButton"
                type="submit"
                className={`${!isValid || cards.fields.length === 0 ? 'pointer-events-none bg-opacity-50' : 'bg-opacity-100'} bg-gfCoral flex flex-row rounded hover:bg-opacity-50 px-4 py-2 font-MulishBold`}
              >
                {t('Load Card')}
              </button>
              <button
                data-testid="formResetButton"
                onClick={onReset}
                type="button"
                disabled={loadingLoadCards}
                className="bg-gfPeriwinkle rounded hover:bg-opacity-50 px-4 py-2 font-MulishBold"
              >
                {t('Reset')}
              </button>
            </div>
          </div>
        </Steps>
      </Form>
      <LoadCardTicketModal
        isOpen={isOpenTicketModal}
        onClose={() => setIsOpenTicketModal(false)}
      />
    </Container>
  )
}
