import CurrencyFormat from 'react-currency-format'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { randomString } from '../../utils/Helper'

export default function LoadCardReceipt({ componentRef }) {
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag)
  const { receivedTicketInfo, additionalProducts } = useSelector(
    (state) => state.loadCard,
  )
  const { t } = useTranslation()
  const fieldsList = (field) => {
    return (
      parseInt(field.show_in_print) === 1 && (
        <div key={field.id} className="flex flex-row">
          <span
            style={{ fontWeight: 'bold' }}
          >{`${field.form_field.label}: `}</span>
          <span style={{ marginLeft: '4px' }}>{field.value}</span>
        </div>
      )
    )
  }
  const checkFieldSet = (fields) => {
    const hasShowInPrintIndex = fields.findIndex((element) => {
      return parseInt(element.show_in_print) === 1
    })

    return hasShowInPrintIndex >= 0
  }
  const AdditionalProducts = (product) => {
    const rows = []

    for (let i = 0; i < product.quantity; i++) {
      rows.push(
        <div className="mb-2">
          <div className="flex flex-row justify-start item-center">
            <span className="font-MulishBold">{`${t('Name')}: `}</span>
            <span className="mx-2">{product.product.name}</span>
          </div>
          <div className="flex flex-row justify-start item-center">
            <span className="font-MulishBold">{`${t('price')}: `}</span>
            <div className="flex justify-start ml-2">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={product.product.price}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span className="ml-2">{tagCurrency}</span>
            </div>
          </div>
          <div className="flex flex-row justify-start item-center">
            <span className="font-MulishBold">{`${t('VAT')}: `}</span>
            <div className="flex justify-start ml-2">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={product.product.tax}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span className="ml-2">{tagCurrency}</span>
            </div>
          </div>
        </div>,
      )
    }
    return rows
  }

  return (
    <div
      ref={componentRef}
      style={{
        maxWidth: '165px',
        margin: 'auto',
        backgroundColor: 'white',
        color: 'black',
        fontSize: '10px',
        paddingRight: '20px',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
        }}
      >
        <img
          style={{ maxWidth: '100px' }}
          src={`${process.env.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
          alt="logo"
        />
      </div>

      <div
        style={{
          textAlign: 'left',
          fontSize: '16px',
          paddingTop: '30px',
          paddingBottom: '30px',
          fontWeight: 'bolder',
        }}
      >
        {selectedTag.name}
      </div>

      {receivedTicketInfo.user && (
        <div
          style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}
        >
          <span style={{ fontWeight: 'bold' }}>{`${t('User')}: `}</span>
          <span style={{ marginLeft: '4px' }}>{`${String(
            receivedTicketInfo.user.first_name,
          )} ${String(receivedTicketInfo.user.last_name)}`}</span>
        </div>
      )}

      <div style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}>
        <span style={{ fontWeight: 'bold' }}>{`${t('Transaction')}: `}</span>
        <span style={{ marginLeft: '4px' }}>
          {receivedTicketInfo.ticket_number}
        </span>
      </div>

      {receivedTicketInfo.payments &&
        receivedTicketInfo.payments.length !== 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
          >
            <div className="flex flex-row">
              <span style={{ fontWeight: 'bold' }}>{`${t(
                'Payment-methods',
              )}: `}</span>
            </div>
            {receivedTicketInfo.payments.map((payment) => (
              <div className="flex flex-row" key={payment.id}>
                <span className="">{payment.name}</span>
                <span> (</span>
                <div className="flex justify-start">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={payment.total_amount}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
                <span>)</span>
              </div>
            ))}
          </div>
        )}

      {receivedTicketInfo.channel && (
        <div
          style={{ textAlign: 'left', paddingTop: '3px', paddingBottom: '3px' }}
        >
          <span
            style={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              marginRight: '4px',
            }}
          >{`${t('Channel')}: `}</span>
          <span style={{ textTransform: 'uppercase', display: 'inline-block' }}>
            {receivedTicketInfo.channel.name}
          </span>
        </div>
      )}

      {receivedTicketInfo.desk && (
        <div
          style={{ textAlign: 'left', paddingTop: '3px', paddingBottom: '3px' }}
        >
          <span
            style={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              marginRight: '4px',
            }}
          >{`${t('Desk')}: `}</span>
          <span style={{ textTransform: 'uppercase', display: 'inline-block' }}>
            {receivedTicketInfo.desk.name}
          </span>
        </div>
      )}

      <div
        style={{ textAlign: 'left', paddingTop: '3px', paddingBottom: '3px' }}
      >
        <span
          style={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            marginRight: '4px',
          }}
        >{`${t('Date-of-Purchase')}: `}</span>
        <span style={{ display: 'inline-block' }}>
          {receivedTicketInfo.transaction_date_time}
        </span>
      </div>

      {receivedTicketInfo.entries &&
        receivedTicketInfo.entries.length !== 0 && (
          <div>
            <div
              style={{
                textAlign: 'left',
                paddingTop: '20px',
                paddingBottom: '10px',
                fontWeight: 'bolder',
                fontSize: '13px',
              }}
            >
              {`${t('Gift-Cards')}`}
            </div>
            {receivedTicketInfo.entries.map((entry) => (
              <div
                style={{ paddingTop: '3px', paddingBottom: '3px' }}
                key={entry.token}
              >
                <div className="flex ">
                  <span style={{ fontWeight: 'bold' }}>{`${t(
                    'Token',
                  )}: `}</span>
                  <span style={{ marginLeft: '4px' }}>{entry.token}</span>
                </div>
                <div className="flex ">
                  <span style={{ fontWeight: 'bold' }}>{`${t(
                    'Amount',
                  )}: `}</span>
                  <div style={{ marginLeft: '4px' }}>
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={entry.amount}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                  </div>
                </div>
                <div className="flex">
                  <span
                    style={{ fontWeight: 'bold' }}
                  >{`${t('Expiry-date')}: `}</span>
                  <span style={{ marginLeft: '4px' }}>{entry.expiry_date}</span>
                </div>
                <div className="flex">
                  <span
                    style={{ fontWeight: 'bold' }}
                  >{`${t('Purchase fee')}: `}</span>
                  <span style={{ marginLeft: '4px' }}>
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={
                        entry.purchase_fee *
                        (1 - selectedTag.purchase_fee.vat_percentage / 100)
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      suffix={` ${tagCurrency}`}
                    />
                  </span>
                </div>
                <div className="flex">
                  <span style={{ fontWeight: 'bold' }}>{`${t('VAT')}: `}</span>
                  <span style={{ marginLeft: '4px' }}>
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={
                        entry.purchase_fee *
                        (selectedTag.purchase_fee.vat_percentage / 100)
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      suffix={` ${tagCurrency}`}
                    />
                  </span>
                </div>
              </div>
            ))}

            <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
              <div className="flex ">
                <span style={{ fontWeight: 'bold' }}>{`${t('Cards')}: `}</span>
                <span style={{ marginLeft: '4px' }}>
                  {receivedTicketInfo.no_of_entries}
                </span>
              </div>
              <div className="flex flex-row">
                <span style={{ fontWeight: 'bold' }}>{`${t('Amount')}: `}</span>
                <div style={{ marginLeft: '4px' }}>
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={receivedTicketInfo.entries.reduce((sum, item) => {
                      sum += item.amount + item.purchase_fee
                      return sum
                    }, 0)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
              </div>
            </div>
          </div>
        )}

      {receivedTicketInfo.additional_products &&
        receivedTicketInfo.additional_products.length !== 0 && (
          <div className="" key={randomString(2)}>
            <div
              style={{
                textAlign: 'left',
                paddingTop: '20px',
                paddingBottom: '10px',
                fontWeight: 'bolder',
                fontSize: '13px',
              }}
            >{`${t('Additional-products')}`}</div>
            {receivedTicketInfo.additional_products.map((product) =>
              AdditionalProducts(product),
            )}

            <div style={{ paddingLeft: '12px' }}>
              <div className="flex flex-row items-center">
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${t('Products')}: `}</span>
                <span style={{ marginLeft: '4px' }}>
                  {receivedTicketInfo.total_product_count}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <span style={{ fontWeight: 'bold' }}>{`${t('Amount')}: `}</span>
                <div className="ml-1">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={receivedTicketInfo.total_product_price}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
              </div>
              <div className="flex flex-row items-center ">
                <span style={{ fontWeight: 'bold' }}>{`${t('VAT')}: `}</span>
                <div style={{ marginLeft: '4px' }}>
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={receivedTicketInfo.total_product_tax}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="">
        <div
          style={{
            textAlign: 'left',
            paddingTop: '20px',
            paddingBottom: '10px',
            fontWeight: 'bolder',
            fontSize: '13px',
          }}
        >{`${t('Order-Recap')}`}</div>
        <div>
          <div className="flex flex-row items-center">
            <span style={{ fontWeight: 'bold' }}>{`${t('Cards')}: `}</span>
            <span style={{ marginLeft: '4px' }}>
              {receivedTicketInfo.no_of_entries}
            </span>
          </div>

          <div className="flex flex-row items-center">
            <span
              style={{ fontWeight: 'bold' }}
            >{`${t('Total-amount')}: `}</span>
            <div className="flex flex-row ml-1">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={receivedTicketInfo.total_amount}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <span style={{ fontWeight: 'bold' }}>{`${t('VAT')}: `}</span>
            <div className="flex flex-row ml-1">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={
                  receivedTicketInfo.total_purchase_fee *
                    (selectedTag.purchase_fee.vat_percentage / 100) +
                  receivedTicketInfo.total_product_tax
                }
                displayType={'text'}
                thousandSeparator={true}
              />
              <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
            </div>
          </div>
        </div>
      </div>

      {receivedTicketInfo.fields &&
        receivedTicketInfo.fields.length !== 0 &&
        checkFieldSet(receivedTicketInfo.fields) && (
          <div>
            <div
              style={{
                textAlign: 'left',
                paddingTop: '20px',
                paddingBottom: '10px',
                fontWeight: 'bolder',
                fontSize: '13px',
              }}
            >{`${t('User-Information')}`}</div>
            <div>
              {receivedTicketInfo.fields.map((field) => fieldsList(field))}
            </div>
          </div>
        )}

      <div style={{ textAlign: 'center', padding: '30px 0px' }}>
        <div>{selectedTag.name}</div>
        <div className="text-center">{`${selectedTag.address}, ${selectedTag.zip}, ${selectedTag.city}, ${selectedTag.country_code}`}</div>
        <div
          style={{
            width: '155px',
            wordBreak: 'break-all',
          }}
        >
          {selectedTag.support_email}
        </div>
        <div>{selectedTag.support_phone}</div>
        <div>{selectedTag.website}</div>
      </div>

      <div
        style={{
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        {selectedTag.ticket_footer}
      </div>
      {selectedTag.is_giftify_selling_party && (
        <div
          style={{
            textAlign: 'center',
            paddingBottom: '30px',
          }}
        >
          <pre
            style={{
              fontFamily: 'inherit',
              whiteSpace: 'pre-line',
            }}
          >
            {t('invoice-footer-message', {
              invoiceServiceUrl: process.env.REACT_APP_INVOICE_SERVICE_URL,
            }).replace(/&#x2F;/g, '/')}
          </pre>
        </div>
      )}
    </div>
  )
}
