import { issuingAPI } from '../services/Axios'
import { APP_COUNT } from './CommonActions'

export const PREFIX = '@manageShop/'

export const LOADING_MANAGE_SHOP_LIST = `${PREFIX}LOADING_MANAGE_SHOP_LIST`
export const RECEIVED_MANAGE_SHOP_LIST = `${PREFIX}RECEIVED_MANAGE_SHOP_LIST`
export const ERROR_MANAGE_SHOP_LIST = `${PREFIX}ERROR_MANAGE_SHOP_LIST`
export const RESET_MANAGE_SHOP_LIST = `${PREFIX}RESET_MANAGE_SHOP_LIST`

export const LOADING_SHOP_SIGN = `${PREFIX}LOADING_SHOP_SIGN`
export const RECEIVED_SHOP_SIGN = `${PREFIX}RECEIVED_SHOP_SIGN`
export const ERROR_SHOP_SIGN = `${PREFIX}ERROR_SHOP_SIGN`

export const LOADING_SHOP_CATEGORY = `${PREFIX}LOADING_SHOP_CATEGORY`
export const RECEIVED_SHOP_CATEGORY = `${PREFIX}RECEIVED_SHOP_CATEGORY`
export const ERROR_SHOP_CATEGORY = `${PREFIX}ERROR_SHOP_CATEGORY`

export const UPDATING_SHOP = `${PREFIX}UPDATING_SHOP`
export const SUCCESS_UPDATING = `${PREFIX}SUCCESS_UPDATING`
export const FAIL_UPDATING = `${PREFIX}FAIL_UPDATING`

export const RESET_UPDATE = `${PREFIX}RESET_UPDATE`

export const getShopSign = () => (dispatch, getState) => {
  dispatch({ type: LOADING_SHOP_SIGN })

  dispatch({ type: APP_COUNT })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/shop-sign`)
    .then((success) => {
      dispatch({ type: RECEIVED_SHOP_SIGN, payload: success.data })
      dispatch(getShopCategory())
    })
    .catch((error) => {
      dispatch({ type: ERROR_SHOP_SIGN, payload: error })
      return error
    })
}

export const getShopCategory = () => (dispatch, getState) => {
  dispatch({ type: LOADING_SHOP_CATEGORY })

  const { tagID } = getState().tag

  return issuingAPI
    .get(`/tag/${tagID}/shop-category`)
    .then((success) => {
      dispatch({ type: RECEIVED_SHOP_CATEGORY, payload: success.data })
      dispatch(getManageShopList('', 1))
    })
    .catch((error) => {
      dispatch({ type: ERROR_SHOP_CATEGORY, payload: error })
      return error
    })
}

export const getManageShopList = (keyword, page) => (dispatch, getState) => {
  dispatch({ type: LOADING_MANAGE_SHOP_LIST })

  const { tagCardBrands, tagID } = getState().tag

  const params = {
    per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    card_brand_ids: tagCardBrands,
  }

  if (keyword !== '') {
    Object.assign(params, { query: keyword })
  }

  if (page) {
    Object.assign(params, { page })
  }

  return issuingAPI
    .get(`/tag/${tagID}/shop`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_MANAGE_SHOP_LIST, payload: success.data })
    })
    .catch((error) => {
      dispatch({ type: ERROR_MANAGE_SHOP_LIST, payload: error })
      return error
    })
}

export const getManageShopListPagination =
  (keyword, page) => (dispatch, getState) => {
    // dispatch({ type: LOADING_MANAGE_SHOP_LIST });

    const { tagID } = getState().tag

    const params = {
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    }

    if (keyword !== '') {
      Object.assign(params, { query: keyword })
    }

    if (page) {
      Object.assign(params, { page })
    }

    return issuingAPI
      .get(`/tag/${tagID}/shop`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_MANAGE_SHOP_LIST, payload: success.data })
      })
      .catch((error) => {
        dispatch({ type: ERROR_MANAGE_SHOP_LIST, payload: error })
        return error
      })
  }

export const SAVE_SHOP_OPERATION_ID = `${PREFIX}SAVE_SHOP_OPERATION_ID`

export const saveShop = (shop) => (dispatch, getState) => {
  dispatch({ type: UPDATING_SHOP })

  const { tagID } = getState().tag
  const { saveShopOperationID } = getState().manageShops

  const headers = {
    'operation-id': saveShopOperationID,
  }

  const data = {
    shops: [shop],
  }

  return issuingAPI
    .put(`/tag/${tagID}/shop`, { ...data }, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_UPDATING, payload: success.data })
      dispatch({ type: SAVE_SHOP_OPERATION_ID })
    })
    .catch((error) => {
      dispatch({ type: FAIL_UPDATING, payload: error })
      dispatch({ type: SAVE_SHOP_OPERATION_ID })
      return error
    })
}
