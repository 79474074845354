import React from 'react'
import { useSelector } from 'react-redux'
import BellIcon from '../../assets/svg/BellIcon'
import NotificationIcon from '../../assets/svg/NotificationIcon'

export default function Notification({ history }) {
  const { notificationCount } = useSelector((state) => state.notification)

  const notificationOPenHandler = () => {
    history.push('/notification-list')
  }

  return (
    <div className="cursor-pointer" onClick={notificationOPenHandler}>
      <div className="relative w-10">
        {notificationCount !== 0 && (
          <div
            className="absolute rounded-full w-5 h-5 flex justify-center items-center bg-gfCoral"
            style={{ bottom: '-13px', right: '5px' }}
          >
            <span className="text-white text-xs">{notificationCount}</span>
          </div>
        )}
        {/* <NotificationIcon /> */}
        <BellIcon />
      </div>
    </div>
  )
}
