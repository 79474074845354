import React, { forwardRef, useEffect, useRef, useState, useMemo } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Userpilot } from 'userpilot'
import ReactDatePicker from 'react-datepicker'
import format from 'date-fns/format'
import {
  getManageSalesByDeliveryMethod,
  RESET_MANAGE_SALES,
  RESET_ORDER_STATUS,
} from '../../../../actions/ManageSaleActions'
import { TAG_CHANGED } from '../../../../actions/TagActions'
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions'
import ClearIcon from '../../../../assets/svg/ClearIcon'
import SearchIcon from '../../../../assets/svg/SearchIcon'
import FailAlert from '../../../Alert/FailAlert'
import MessageAlert from '../../../Alert/MessageAlert'
import DropdownIndicator from '../../../Dropdown/Components/DropdownIndicator'
import Container from '../../../UIComponents/Container'
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../../utils/Constants'
import ViewDetailOrderB2b from './ViewDetailOrderB2b'
import ChevronUp from '../../../../assets/svg/ChevronUp'
import ChevronDown from '../../../../assets/svg/ChevronDown'
import {
  randomString,
  checkLoggedInUserPermissions,
} from '../../../../utils/Helper'
import OnlineSaleStatusDropdown from '../../../Dropdown/OnlineSaleStatusDropdown'
import OnlineSalesStatusChangeConfirmation from './OnlineSalesStatusChangeConfirmation'
import SalesStatus from './SalesStatus'
import PrepareB2BOrder from './PrepareB2BOrder'
import ActivateB2BOrder from './B2BPrepareOrderSuccess'
import B2BPrepareOrderSuccess from './B2BPrepareOrderSuccess'
import B2BPrepareOrderError from './B2BPrepareOrderError'

export default function ManageSalesB2b() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const pageRef = useRef(null)
  const [selectedOrder, setSelectedOrder] = useState({
    value: 'all',
    label: `${t('All')}`,
    homeDeliver: 0,
  })
  const [selectedStatusChanged, setSelectedStatusChanged] = useState({})
  const [selectedCustomerType, setSelectedCustomerType] = useState({
    value: 'b2b',
    label: `${t('business')}`,
  })
  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [modalData, setModalData] = useState({})
  const [isOpenPrepareOrder, setIsOpenPrepareOrder] = useState(false)
  const { loadingManageSales, receivedManageSales, errorManageSales, meta } =
    useSelector((state) => state.manageSales)
  const { selectedTag } = useSelector((state) => state.tag)
  const [showMore, setShowMore] = useState(false)
  const [selectedID, setSelectedID] = useState('')
  const [selectedPageNumber, setSelectedPageNumber] = useState(0)
  const [chooseDate, setChooseDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { selectedLanguage } = useSelector((state) => state.language)
  const [isOpenStatusChangeOrder, setIsOpenStatusChangeOrder] = useState(false)
  const { appCount } = useSelector((state) => state.commonReducer)
  const [showPrepareOrderPage, setShowPrepareOrderPage] = useState(false)
  const [showSuccessActivateOrder, setShowSuccessActivateOrder] =
    useState(false)
  const [showErrorActivateOrder, setShowErrorActivateOrder] = useState(false)
  const canB2BSalesPaymentStatusUpdate = useMemo(
    () => checkLoggedInUserPermissions('b2b-sales-payment-status-updates'),
    [checkLoggedInUserPermissions],
  )
  const isNotGiftifySeller = !selectedTag.is_giftify_selling_party
  console.log(window?.appConfig.REACT_APP_FEATURE_B2B_CODA_AUTOMATED)
  let deliveryStatus = [
    { value: 'all', label: `${t('all-delivery-methods')}`, homeDeliver: 0 },
  ]

  if (selectedTag.has_pickupDelivery) {
    deliveryStatus = [
      ...deliveryStatus,
      {
        value: 'awaiting-payment',
        label: `${t('awaiting-payment').toUpperCase()}`,
        homeDeliver: 0,
      },
      { value: 'paid', label: `${t('Paid').toUpperCase()}`, homeDeliver: 0 },
      {
        value: 'decline',
        label: `${t('Declined').toUpperCase()}`,
        homeDeliver: 0,
      },
      {
        value: 'to-pick-up',
        label: `${t('To-Pick-Up').toUpperCase()}`,
        homeDeliver: 0,
      },
      {
        value: 'picked-up',
        label: `${t('Picked-Up').toUpperCase()}`,
        homeDeliver: 0,
      },
    ]
  }

  const orderStatus = [
    { id: 'paid', name: `${t('Paid').toUpperCase()}` },
    { id: 'decline', name: `${t('Declined').toUpperCase()}` },
  ]

  useEffect(() => {
    Userpilot.reload()

    setSelectedOrder({
      value: 'all',
      label: `${t('all-delivery-methods')}`,
      homeDeliver: 0,
    })
    setSelectedCustomerType({ value: 'b2b', label: `${t('business')}` })
    setSearchKeyWord('')
    if (appCount === 0) {
      dispatch({ type: RESET_MANAGE_SALES })
      dispatch(
        getManageSalesByDeliveryMethod(
          selectedOrder.homeDeliver,
          selectedOrder.value,
          selectedCustomerType.value,
          searchKeyWord,
          selectedPageNumber,
          getFromDate(),
          getToDate(),
        ),
      )
    }

    window.addEventListener(TAG_CHANGED, () => {})

    window.addEventListener(TERMINAL_CHANGED, () => {})

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {})
      window.removeEventListener(TERMINAL_CHANGED, () => {})
    }
  }, [])

  const searchOnChangeHandler = (event) => {
    setSearchKeyWord(event.target.value)
  }

  const handlePagination = (event) => {
    setSelectedPageNumber(event.selected + 1)
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        event.selected + 1,
        getFromDate(),
        getToDate(),
      ),
    )
  }

  const orderOnChanged = (event) => {
    if (pageRef.current !== null) pageRef.current.setState({ selected: 0 })
    setSelectedOrder(event)
    dispatch(
      getManageSalesByDeliveryMethod(
        event.homeDeliver,
        event.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        getFromDate(),
        getToDate(),
      ),
    )
  }

  const orderStatusChanged = (data, event) => {
    if (checkLoggedInUserPermissions('b2b-sales-payment-status-updates')) {
      setModalData(data)
      setSelectedStatusChanged(event)
      setIsOpenStatusChangeOrder(!isOpenStatusChangeOrder)
    }
  }
  const closeStatusChanged = () => {
    document.body.style.overflow = 'unset'
    setModalData({})
    dispatch({ type: RESET_ORDER_STATUS })
    setIsOpenStatusChangeOrder(!isOpenStatusChangeOrder)
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      fontWeight: '600',
      maxWidth: '100%',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? GF_DARK_BLUE : '#FFFFFF',
      color: state.isSelected ? '#FFFFFF' : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '100%',
      color: GF_DARK_BLUE,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      textTransform: 'capitalize',
    }),
  }

  const clearSearchBox = () => {
    setSearchKeyWord('')
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        '',
        selectedPageNumber,
        getFromDate(),
        getToDate(),
      ),
    )
  }

  const openViewDetailOrder = (data) => {
    document.body.style.overflow = 'hidden'
    setModalData(data)
    setIsOpenPrepareOrder(!isOpenPrepareOrder)
  }

  const closeViewDetailOrder = () => {
    document.body.style.overflow = 'unset'
    setModalData({})
    setIsOpenPrepareOrder(!isOpenPrepareOrder)
    dispatch({ type: RESET_ORDER_STATUS })
  }

  const showHideMoreCards = (event, ticketNumber) => {
    if (event.currentTarget.id === ticketNumber) {
      setShowMore(!showMore)
      setSelectedID(ticketNumber)
    }
  }

  const searchHandler = () => {
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        getFromDate(),
        getToDate(),
      ),
    )
  }

  const fromDatePickHandler = (date) => {
    setChooseDate(date)
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        format(date, 'yyyy-MM-dd'),
        getToDate(),
      ),
    )
  }
  const toDatePickHandler = (date) => {
    setEndDate(date)
    dispatch(
      getManageSalesByDeliveryMethod(
        selectedOrder.homeDeliver,
        selectedOrder.value,
        selectedCustomerType.value,
        searchKeyWord,
        selectedPageNumber,
        getFromDate(),
        format(date, 'yyyy-MM-dd'),
      ),
    )
  }

  const getFromDate = () => {
    let from
    if (chooseDate !== null) {
      from = format(chooseDate, 'yyyy-MM-dd')
    } else {
      from = null
    }
    return from
  }
  const getToDate = () => {
    let to
    if (endDate !== null) {
      to = format(endDate, 'yyyy-MM-dd')
    } else {
      to = format(new Date(), 'yyyy-MM-dd')
    }
    return to
  }

  const openB2BViewHandler = () => {
    setIsOpenPrepareOrder(!isOpenPrepareOrder)
    setShowPrepareOrderPage(!showPrepareOrderPage)
  }

  const openB2BViewCloseHandler = () => {
    document.body.style.overflow = 'unset'
    setShowPrepareOrderPage(!showPrepareOrderPage)
  }

  const successActivateB2BHandler = () => {
    setShowPrepareOrderPage(!showPrepareOrderPage)
    setShowSuccessActivateOrder(!showSuccessActivateOrder)
  }

  const errorActivateB2BHandler = () => {
    setShowPrepareOrderPage(!showPrepareOrderPage)
    setShowErrorActivateOrder(!showErrorActivateOrder)
  }

  const DPCustomInput = forwardRef((props, ref) => (
    <input
      id={props.id}
      ref={ref}
      value={props.value}
      onClick={props.onClick}
      placeholder={props.placeholderText}
      readOnly={true}
      className="border cursor-pointer w-full text-center rounded hover:bg-gfPeriwinkle border-gfPeriwinkle px-8 py-2 focus:outline-none"
    />
  ))

  return (
    <Container title={t('manage-sales-b2b')} loading={loadingManageSales}>
      <div className="flex flex-col w-full mx-auto justify-start space-y-6">
        <div className="flex flex-col md:flex-row gap-10">
          <div className="flex flex-row gap-3">
            <div className="relative">
              {searchKeyWord !== '' && (
                <button
                  onClick={clearSearchBox}
                  className="focus:outline-none focus:shadow-outline"
                  style={{ position: 'absolute', right: '5px', top: '10px' }}
                >
                  <ClearIcon />
                </button>
              )}
              <input
                id="searchInput"
                value={searchKeyWord}
                onChange={searchOnChangeHandler}
                className="h-10 text-lg w-full placeholder-gfGrey  rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
                placeholder={`${t('Search')}...`}
                autoComplete="off"
              />
            </div>

            <button
              id="searchButton"
              onClick={searchHandler}
              className="border  flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
            >
              <SearchIcon />
            </button>
          </div>
          <div className="flex flex-row gap-5">
            <div className="flex flex-col">
              {/* <div className="flex items-center  py-1">{t("Choose-the-date")}:</div> */}{' '}
              <ReactDatePicker
                id="fromDateInput"
                showMonthDropdown
                showYearDropdown
                disabled={false}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <DPCustomInput placeholderText={'From date (optional)'} />
                }
                selected={chooseDate}
                onChange={(date) => fromDatePickHandler(date)}
                locale={selectedLanguage.value}
              />
            </div>
            <div className="flex flex-col ml-5">
              {/* <div className="flex items-center py-1">{t("End-date") + "(" + t("Optional") + ")"}</div> */}
              <ReactDatePicker
                id="toDateInput"
                showMonthDropdown
                showYearDropdown
                disabled={false}
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => toDatePickHandler(date)}
                customInput={
                  <DPCustomInput placeholderText={'To date (optional)'} />
                }
                locale={selectedLanguage.value}
              />
            </div>
          </div>
          <Select
            id="statusDropDown"
            className="w-96"
            styles={customStyles}
            value={selectedOrder}
            getOptionLabel={(option) => `${option.label}`}
            onChange={orderOnChanged}
            placeholder={false}
            options={deliveryStatus}
            isSearchable={false}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator,
            }}
          />
        </div>

        {meta &&
          meta.total > window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
            <div className="flex flex-col md:flex-row md:justify-end w-full">
              <div>
                <ReactPaginate
                  ref={pageRef}
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    meta.total /
                    window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePagination}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          )}

        {receivedManageSales && receivedManageSales.length === 0 && (
          <div className="mt-10">
            <MessageAlert message={t('There-are-no-orders-to-display')} />
          </div>
        )}

        {errorManageSales && (
          <div className="mt-10">
            {errorManageSales && (
              <FailAlert message={errorManageSales.message} />
            )}
          </div>
        )}

        {receivedManageSales && receivedManageSales.length !== 0 && (
          <div className=" overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border ">
            <div className="align-middle inline-block min-w-full  ">
              <div className="shadow overflow-hidden  ">
                <div className="text-right border-b w-full px-4 py-2 text-xl font-MulishBold text-gfDarkBlue bg-gfPeriwinkle bg-opacity-50">
                  {t('B2b_orders_paginate_description', {
                    from: meta.from,
                    to: meta.to,
                    total: meta.total,
                  })}
                </div>
                <table className="min-w-full divide-y divide-gfPeriwinkle">
                  <thead className="bg-gfGrey font-MulishBlack text-xs">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3  text-left   uppercase tracking-wider"
                      >
                        {t('global-order-ref')}
                      </th>
                      <th
                        scope="col"
                        className="py-3  text-left   uppercase tracking-wider"
                      >
                        {t('Date')}
                      </th>
                      <th
                        scope="col"
                        className="py-3  text-left   uppercase tracking-wider"
                      >
                        {t('Order-Ref')}
                      </th>
                      {/* {selectedTag.enable_e_shop_b2b_orders === B2B_ORDERS_ENABLE && ( */}
                      {/*    <th scope="col" className=" py-3  text-center   uppercase tracking-wider"> */}
                      {/*        {t("customer-type")} */}
                      {/*    </th> */}
                      {/* )} */}
                      <th
                        scope="col"
                        className="py-3  text-left   uppercase tracking-wider"
                      >
                        {t('Customer')}
                      </th>
                      <th
                        scope="col"
                        className="py-3  text-left   uppercase tracking-wider"
                      >
                        {t('Payment-method')}
                      </th>
                      {selectedTag.enable_e_shop_variable_symbol > 0 && (
                        <th
                          scope="col"
                          className="py-3  text-left   uppercase tracking-wider"
                        >
                          {t('Variable-symbol')}
                        </th>
                      )}
                      {/* <th scope="col" className=" px-2 py-3  text-center   uppercase tracking-wider"> */}
                      {/*    {t("Delivery-method")} */}
                      {/* </th> */}
                      <th
                        scope="col"
                        className="w-52 py-3 text-left uppercase tracking-wider"
                      >
                        {t('Order-Status')}
                      </th>
                      <th
                        scope="col"
                        className="py-3  text-left   uppercase tracking-wider"
                      >
                        {t('Order-Amount')}
                      </th>
                      {/* <th scope="col" className=" px-4 py-3  text-center uppercase tracking-wider"> */}
                      {/*    {t("Seller")} */}
                      {/* </th> */}
                      <th
                        scope="col"
                        className="py-3  text-left   uppercase tracking-wider"
                      >
                        {t('Action')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                    {receivedManageSales.map((item) => (
                      <tr
                        key={randomString(6)}
                        className={`${item.orders.length > 1 ? 'shadow-md' : ''}`}
                      >
                        <td
                          style={{ verticalAlign: 'top' }}
                          className="px-4 py-4 "
                        >
                          <div className="flex items-center">
                            {item.global_order_ref !== null ? (
                              <div>{item.global_order_ref}</div>
                            ) : (
                              <span>-</span>
                            )}
                          </div>
                        </td>

                        <td style={{ verticalAlign: 'top' }} className="py-4">
                          <div className="flex items-center">
                            {item.creation_date}
                          </div>
                        </td>

                        {item.orders.length === 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="py-4 "
                          >
                            <div className="flex items-center">
                              {item.orders[0].reference}
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td
                            style={{ verticalAlign: 'top' }}
                            className="py-4 "
                          >
                            <div className="flex flex-col  text-gfLightBlue font-MulishBlack">{`${item.orders.length} ${t('Card-orders')}`}</div>
                            <div
                              className={`${showMore && selectedID === item.global_order_ref ? ' flex flex-col' : 'hidden'}`}
                            >
                              <table>
                                <tbody>
                                  {item.orders.map((entry) => (
                                    <tr key={entry.reference}>
                                      <td>
                                        <div className="py-1">
                                          {entry.reference}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}

                        <td style={{ verticalAlign: 'top' }} className="py-4">
                          <div className="flex">{`${item.first_name} ${item.last_name}`}</div>
                        </td>

                        <td style={{ verticalAlign: 'top' }} className="py-4">
                          <div className="flex">{`${t(item.payment_method)}`}</div>
                        </td>

                        {selectedTag.enable_e_shop_variable_symbol > 0 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="flex">{`${item.orders[0].variable_symbol}`}</div>
                          </td>
                        )}

                        {item.orders.length === 1 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="">
                              {item.status === 'awaiting_payment' ? (
                                canB2BSalesPaymentStatusUpdate &&
                                isNotGiftifySeller ? (
                                  <OnlineSaleStatusDropdown
                                    options={orderStatus}
                                    modalData={item}
                                    onChangeHandler={orderStatusChanged}
                                  />
                                ) : canB2BSalesPaymentStatusUpdate &&
                                  !isNotGiftifySeller &&
                                  window?.appConfig
                                    .REACT_APP_FEATURE_B2B_CODA_AUTOMATED ===
                                    'false' ? (
                                  <OnlineSaleStatusDropdown
                                    options={orderStatus}
                                    modalData={item}
                                    onChangeHandler={orderStatusChanged}
                                  />
                                ) : (
                                  <SalesStatus status={item.display_status} />
                                )
                              ) : (
                                <SalesStatus status={item.display_status} />
                              )}
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="flex-col text-gfLightBlue">
                              {item.status === 'awaiting_payment' ? (
                                canB2BSalesPaymentStatusUpdate &&
                                isNotGiftifySeller ? (
                                  <OnlineSaleStatusDropdown
                                    options={orderStatus}
                                    modalData={item}
                                    onChangeHandler={orderStatusChanged}
                                  />
                                ) : canB2BSalesPaymentStatusUpdate &&
                                  !isNotGiftifySeller &&
                                  window?.appConfig
                                    .REACT_APP_FEATURE_B2B_CODA_AUTOMATED ===
                                    'false' ? (
                                  <OnlineSaleStatusDropdown
                                    options={orderStatus}
                                    modalData={item}
                                    onChangeHandler={orderStatusChanged}
                                  />
                                ) : (
                                  <SalesStatus status={item.display_status} />
                                )
                              ) : (
                                <SalesStatus status={item.display_status} />
                              )}
                            </div>
                          </td>
                        )}

                        {item.orders.length === 1 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="flex text-gfLightBlue">
                              <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={
                                  item.orders[0].total_amount +
                                  item.orders[0].fee_amount
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                              <span className="ml-2">{` ${selectedTag.currency}`}</span>
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="flex  text-gfLightBlue font-MulishBlack">
                              <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={item.orders.reduce(
                                  (a, pro) =>
                                    (a += parseFloat(
                                      pro.total_amount + pro.fee_amount,
                                    )),
                                  0,
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                              <span className="ml-2">{` ${selectedTag.currency}`}</span>
                            </div>
                            <div
                              className={`${showMore && selectedID === item.global_order_ref ? ' flex flex-col' : 'hidden'}`}
                            >
                              <table>
                                <tbody>
                                  {item.orders.map((entry) => (
                                    <tr key={entry.reference}>
                                      <td>
                                        <div
                                          key={entry.id}
                                          className="flex flex-row py-1 "
                                        >
                                          <div className="flex text-gfLightBlue">
                                            <CurrencyFormat
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              value={
                                                entry.total_amount +
                                                entry.fee_amount
                                              }
                                              displayType={'text'}
                                              thousandSeparator={true}
                                            />
                                            <span className="ml-2">{` ${selectedTag.currency}`}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}
                        {item.orders.length === 1 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="flex">
                              <button
                                onClick={() => openViewDetailOrder(item)}
                                className="text-left w-24 text-gfLightBlue hover:text-opacity-75 focus:outline-none hover:underline"
                              >
                                {t('View-details')}
                              </button>
                            </div>
                          </td>
                        )}

                        {item.orders.length > 1 && (
                          <td style={{ verticalAlign: 'top' }} className="py-4">
                            <div className="flex">
                              <button
                                onClick={() => openViewDetailOrder(item)}
                                className="text-left w-24 text-gfLightBlue hover:text-opacity-75 py-1 focus:outline-none hover:underline"
                              >
                                {t('View-details')}
                              </button>
                              <button
                                className="focus:outline-none"
                                id={item.global_order_ref}
                                onClick={(event) =>
                                  showHideMoreCards(
                                    event,
                                    item.global_order_ref,
                                  )
                                }
                              >
                                {showMore &&
                                selectedID === item.global_order_ref ? (
                                  <ChevronUp />
                                ) : (
                                  <ChevronDown />
                                )}
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <ViewDetailOrderB2b
        isOpen={isOpenPrepareOrder}
        data={modalData}
        closeHandler={closeViewDetailOrder}
        selectedOrder={selectedOrder}
        selectedCustomerType={selectedCustomerType}
        chooseDate={getFromDate()}
        endDate={getToDate()}
        selectedPageNumber={selectedPageNumber}
        openB2BViewHandler={openB2BViewHandler}
      />
      <OnlineSalesStatusChangeConfirmation
        isOpen={isOpenStatusChangeOrder}
        order={modalData}
        selectedOrder={selectedOrder}
        closeHandler={closeStatusChanged}
        selectedCustomerType={selectedCustomerType}
        selectedStatusChanged={selectedStatusChanged}
        chooseDate={getFromDate()}
        endDate={getToDate()}
        selectedPageNumber={selectedPageNumber}
      />
      <PrepareB2BOrder
        isOpen={showPrepareOrderPage}
        closeHandler={openB2BViewCloseHandler}
        data={modalData}
        successHandler={successActivateB2BHandler}
        errorHandler={errorActivateB2BHandler}
      />
      <B2BPrepareOrderSuccess isOpen={showSuccessActivateOrder} />
      <B2BPrepareOrderError isOpen={showErrorActivateOrder} />
    </Container>
  )
}
