import {
  ERROR_CARD_STOCK,
  LOADING_CARD_STOCK,
  POPULATED_STOCK_EXPIRED_LIST,
  POPULATED_STOCK_SIX_MONTHS_LIST,
  POPULATED_STOCK_THREE_MONTHS_LIST,
  POPULATED_STOCK_TODAY_LIST,
  POPULATED_STOCK_YEAR_LIST,
  RECEIVED_CARD_STOCK,
} from '../actions/CardStockActions'
import { paginate } from '../utils/Helper'

/* eslint-disable import/no-anonymous-default-export */

const PAGINATE_FIRST_PAGE = 1

const initialState = {
  loadingCardStock: false,
  receivedCardStock: null,
  errorCardStock: null,

  populatedStockToday: null,
  populatedStockThree: null,
  populatedStockSix: null,
  populatedStockTwelve: null,
  populatedStockExpire: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CARD_STOCK:
      return {
        ...state,
        loadingCardStock: true,
        receivedCardStock: null,
        errorCardStock: null,
      }

    case RECEIVED_CARD_STOCK:
      return {
        ...state,
        loadingCardStock: false,
        receivedCardStock: action.payload,
        errorCardStock: null,
        populatedStockToday: paginate(
          action.payload.stock.valid_for_today.cards,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),

        populatedStockThree: paginate(
          action.payload.stock.valid_for_three_months.cards,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),

        populatedStockSix: paginate(
          action.payload.stock.valid_for_six_months.cards,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),

        populatedStockTwelve: paginate(
          action.payload.stock.valid_for_twelve_months.cards,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),

        populatedStockExpire: paginate(
          action.payload.stock.expired.cards,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case POPULATED_STOCK_TODAY_LIST:
      return {
        ...state,
        populatedStockToday: paginate(
          state.receivedCardStock.stock.valid_for_today.cards,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case POPULATED_STOCK_THREE_MONTHS_LIST:
      return {
        ...state,
        populatedStockThree: paginate(
          state.receivedCardStock.stock.valid_for_three_months.cards,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case POPULATED_STOCK_SIX_MONTHS_LIST:
      return {
        ...state,
        populatedStockSix: paginate(
          state.receivedCardStock.stock.valid_for_six_months.cards,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case POPULATED_STOCK_YEAR_LIST:
      return {
        ...state,
        populatedStockTwelve: paginate(
          state.receivedCardStock.stock.valid_for_twelve_months.cards,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case POPULATED_STOCK_EXPIRED_LIST:
      return {
        ...state,
        populatedStockExpire: paginate(
          state.receivedCardStock.stock.expired.cards,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      }

    case ERROR_CARD_STOCK:
      return {
        ...state,
        loadingCardStock: false,
        receivedCardStock: null,
        errorCardStock: action.payload,
      }
    default:
      return state
  }
}
