import React from 'react'
import CurrencyFormat from 'react-currency-format'

function Money({ value, currency }) {
  return (
    <CurrencyFormat
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator={true}
      value={value}
      displayType="text"
      suffix={` ${currency}`}
    />
  )
}

export default Money
