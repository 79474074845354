/* eslint-disable import/no-anonymous-default-export */
import {
  APP_COUNT,
  ERROR_CSV_REPORT,
  GET_CHANNEL_API_COUNT,
  GET_DESK_API_COUNT,
  GET_SHOP_LIST_API_COUNT,
  GET_USE_API_COUNT,
  LOADING_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  RESET_APP_COUNT,
  RESET_CSV_REPORT,
  RESET_VALIDATION_ERRORS,
  SUCCESS_CSV_REPORT,
  VALIDATION_ERRORS,
  RESET_EXCEL_REPORT,
} from '../actions/CommonActions'

const initialState = {
  loadingCSV: false,
  successCSV: false,
  errorCSV: null,

  validationErrors: null,

  appCount: 0,
  userApiCount: 0,
  deskApiCount: 0,
  channelApiCount: 0,
  shopApiCount: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_EXCEL_REPORT:
      return {
        ...state,
        loadingExcel: true,
        successExcel: false,
        errorExcel: null,
      }

    case LOADING_CSV_REPORT:
      return {
        ...state,
        loadingCSV: true,
        successCSV: false,
        errorCSV: null,
      }

    case SUCCESS_CSV_REPORT:
      return {
        ...state,
        loadingCSV: false,
        successCSV: true,
        errorCSV: null,
      }

    case SUCCESS_EXCEL_REPORT:
      return {
        ...state,
        loadingExcel: false,
        successExcel: true,
        errorExcel: null,
      }

    case ERROR_CSV_REPORT:
      return {
        ...state,
        loadingCSV: false,
        successCSV: false,
        errorCSV: action.payload,
      }

    case ERROR_EXCEL_REPORT:
      return {
        ...state,
        loadingExcel: false,
        successExcel: false,
        errorExcel: action.payload,
      }

    case RESET_CSV_REPORT:
      return {
        ...state,
        loadingCSV: false,
        successCSV: false,
        errorCSV: null,
      }

    case RESET_EXCEL_REPORT:
      return {
        ...state,
        loadingExcel: false,
        successExcel: false,
        errorExcel: null,
      }

    case VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.payload,
      }

    case RESET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: null,
      }

    case APP_COUNT:
      return {
        ...state,
        appCount: state.appCount + 1,
      }

    case GET_USE_API_COUNT:
      return {
        ...state,
        userApiCount: state.userApiCount + 1,
      }

    case GET_DESK_API_COUNT:
      return {
        ...state,
        deskApiCount: state.deskApiCount + 1,
      }

    case GET_CHANNEL_API_COUNT:
      return {
        ...state,
        channelApiCount: state.channelApiCount + 1,
      }

    case GET_SHOP_LIST_API_COUNT:
      return {
        ...state,
        shopApiCount: state.shopApiCount + 1,
      }

    case RESET_APP_COUNT:
      return {
        ...state,
        appCount: 0,
        userApiCount: 0,
        deskApiCount: 0,
        channelApiCount: 0,
        shopApiCount: 0,
      }

    default:
      return state
  }
}
