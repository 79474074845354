import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import { isNumeric, isSmallScreen } from '../../../../utils/Helper'
import CloseIcon from '../../../../assets/svg/CloseIcon'
import {
  ADD_ONE_CARD,
  CLEAR_CARD_ERRORS,
  FAIL_PREPARE_B2B_ORDER,
  FROM_TOKEN_TEXT_COLOR,
  RECEIVED_FROM_TOKEN_VALIDITY,
  RECEIVED_TO_TOKEN_VALIDITY,
  REMOVE_CARD_FROM_LIST,
  RESET_LOAD_CARD,
  RESET_PREPARE_B2B_ORDER,
  SET_CARD_AMOUNT,
  SET_TOTAL_AMOUNT,
  SUCCESS_PREPARE_B2B_ORDER,
  TO_TOKEN_TEXT_COLOR,
  checkValidityOfFromToken,
  checkValidityOfToToken,
  getCardRange,
  prepareB2BCardOrder,
} from '../../../../actions/ManageSalesB2BActions'
import { ENTER_KEY, GF_DARK_BLUE } from '../../../../utils/Constants'
import ButtonLoading from '../../../../assets/svg/ButtonLoading'
import ValidationErrors from '../../../Alert/ValidationErrors'

ReactModal.setAppElement('#root')

export default function PrepareB2BOrder({
  isOpen,
  closeHandler,
  data,
  successHandler,
  errorHandler,
}) {
  let orders = []
  let globalOrderRef = ''
  let totalAmount = 0
  let totalFeeAmount = 0
  let currency = ''
  let status = ''
  let displayStatus = ''
  let deliveryMethode = ''
  let customerName = ''
  let address = ''
  const orderRows = []

  if (data.hasOwnProperty('global_order_ref')) {
    orders = Array.from(data.orders)
    const order = data.orders[0]
    globalOrderRef = data.global_order_ref
    status = data.status
    displayStatus = data.display_status
    currency = order.currency
    deliveryMethode = order.delivery_method
    customerName = `${data.first_name} ${data.last_name}`
    address = order.address
    orders.forEach(function (order) {
      totalAmount += order.total_amount + order.fee_amount
      totalFeeAmount += order.fee_amount
      if (order.hasOwnProperty('customers')) {
        const customers = Array.from(order.customers)
        customers.forEach(function (customer) {
          if (orderRows.length > 0) {
            const index = orderRows.findIndex(
              (element) => element[0] == customer.amount_loaded,
            )
            if (index === -1) {
              orderRows.push([customer.amount_loaded, 1])
            } else {
              orderRows[index][1] += 1
            }
          } else {
            orderRows.push([customer.amount_loaded, 1])
          }
        })
      }
    })
  }

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [fromToken, setFromToken] = useState('')
  const [toToken, setToToken] = useState('')
  const [tokenAmount, setTokenAmount] = useState('')
  const [validation, setValidation] = useState('')
  const {
    fromTokenTextColor,
    toTokenTextColor,
    fromTokenValidity,
    toTokenValidity,
    cards,
    isCardRangeDirty,
    loadingCardRange,
    cardsTotalAmount,
  } = useSelector((state) => state.manageSalesB2B)
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag)
  const { validationErrors } = useSelector((state) => state.commonReducer)
  const [cardCount, setCardCount] = useState(1)

  const totalRows = orderRows.reduce((a, card) => (a += parseFloat(card[1])), 0)
  const allRowAmount = orderRows.reduce(
    (a, card) => (a += parseFloat(card[0]) * parseFloat(card[1])),
    0,
  )
  const amountsArray = orderRows.map((row) => row[0])

  const fromTokenChangeHandler = (event) => {
    if (event.currentTarget.value !== '') {
      dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' })
      dispatch({ type: CLEAR_CARD_ERRORS })
      let newT = [...fromToken]
      newT = event.currentTarget.value
      setFromToken(newT)
      if (
        isNumeric(event.currentTarget.value) &&
        event.currentTarget.value.length === 9
      ) {
        setFromToken(event.currentTarget.value)
        dispatch(checkValidityOfFromToken(event.currentTarget.value))
      }
    } else {
      setFromToken('')
      setValidation('')
      dispatch({ type: RECEIVED_FROM_TOKEN_VALIDITY, payload: false })
    }
  }

  const toTokenChangeHandler = (event) => {
    if (event.currentTarget.value !== '') {
      dispatch({ type: TO_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' })
      dispatch({ type: CLEAR_CARD_ERRORS })
      let newT = [...toToken]
      newT = event.currentTarget.value
      setToToken(newT)
      if (
        isNumeric(event.currentTarget.value) &&
        event.currentTarget.value.length === 9
      ) {
        setToToken(event.currentTarget.value)
        dispatch(checkValidityOfToToken(event.currentTarget.value))
      }
    } else {
      setToToken('')
      dispatch({ type: RECEIVED_TO_TOKEN_VALIDITY, payload: false })
    }
  }

  const keyPressHandlerFROMtoken = (event) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault()
      if (
        fromToken !== '' &&
        fromTokenValidity &&
        toToken === '' &&
        tokenAmount !== ''
      ) {
        addCardToListHandler()
      } else if (
        fromToken !== '' &&
        fromTokenValidity &&
        toToken !== '' &&
        toTokenValidity &&
        tokenAmount !== ''
      ) {
        addCardToListHandler()
      }
    }
  }

  const keyPressHandlerTOtoken = (event) => {
    if (
      event.key === ENTER_KEY &&
      fromToken !== '' &&
      fromTokenValidity &&
      toToken !== '' &&
      toTokenValidity &&
      tokenAmount !== ''
    ) {
      event.preventDefault()
      addCardToListHandler()
    }
  }

  const amountChangeHandler = (event) => {
    event.preventDefault()
    setTokenAmount(event.target.value)
  }

  const invalidChars = ['-', '+', 'e']

  const preventNonNumeric = (event) => {
    if (event.which === 38 || event.which === 40) {
      event.preventDefault()
    }
    if (invalidChars.includes(event.key)) {
      event.preventDefault()
    }
  }

  const keyPressHandler = (event) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault()
      addCardToListHandler()
    }
  }

  const checkTokenExist = (token) => {
    const { length } = cards
    for (let i = 0; i < length; i++) {
      if (cards[i].token === token) return true
    }
    return false
  }

  const checkEnteredTokenAmount = (amount) => {
    return amountsArray.includes(parseInt(amount))
  }

  const addCardToListHandler = () => {
    if (tokenAmount === '') {
      setValidation(t('Amount-cannot-be-empty'))
    } else if (cardCount > totalRows) {
      setValidation(t('cannot-add-more-than-cards', { totalRows }))
    } else if (checkTokenExist(fromToken)) {
      setValidation(t('Token-already-exist'))
    } else if (!checkEnteredTokenAmount(tokenAmount)) {
      setValidation(
        t('amount-should-be-one-of-these', {
          amountsArray: amountsArray.toString(),
        }),
      )
    } else {
      setValidation('')

      if (fromTokenValidity && tokenAmount !== '' && toToken === '') {
        const card = {
          token: fromToken,
          valid: fromTokenValidity,
          amount: parseFloat(tokenAmount).toFixed(2),
        }
        setCardCount((pre) => pre + 1)
        dispatch({ type: ADD_ONE_CARD, payload: card })
        dispatch({ type: SET_TOTAL_AMOUNT })
        setTokenAmount('')
        setFromToken('')
      }

      if (fromTokenValidity && toTokenValidity && toToken !== '') {
        dispatch({ type: SET_CARD_AMOUNT, payload: tokenAmount })
        dispatch(getCardRange(fromToken, toToken))
        setTokenAmount('')
        setFromToken('')
        setToToken('')
      }
    }
  }

  const removeTokenFromList = (event) => {
    setValidation('')
    setCardCount((pre) => pre - 1)
    dispatch({ type: REMOVE_CARD_FROM_LIST, payload: event.currentTarget.id })
    dispatch({ type: SET_TOTAL_AMOUNT })
  }

  const prepareClickHandler = () => {
    cards.forEach(function (v) {
      delete v.valid
    })
    dispatch(prepareB2BCardOrder(data.global_order_ref, cards))
  }

  window.addEventListener(SUCCESS_PREPARE_B2B_ORDER, () => {
    successHandler()
  })

  window.addEventListener(FAIL_PREPARE_B2B_ORDER, () => {
    errorHandler()
  })

  const resetHandler = () => {
    dispatch({ type: RESET_PREPARE_B2B_ORDER })
    setTokenAmount('')
    setFromToken('')
    setToToken('')
    setValidation('')
    setCardCount(1)
  }

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: !isSmallScreen() ? '50px' : '0px',
          left: !isSmallScreen() ? '100px' : '0px',
          right: !isSmallScreen() ? '100px' : '0px',
          bottom: !isSmallScreen() ? '100px' : '0px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: !isSmallScreen() ? '50px' : '0px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-4 px-10 border max-w-full rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="flex justify-between items-center ">
          <div className="font-MulishBold">
            {t('Prepare-order').toUpperCase()}
          </div>
          <button
            id="closeButton"
            className="focus:outline-none"
            onClick={() => {
              closeHandler(!isOpen)
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="flex flex-col justify-start items-start">
          <div className="font-MulishBold mt-4">
            Order: {data.global_order_ref}
          </div>

          <div className="">
            <div className="border rounded bg-gfPeriwinkle my-4">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-xs font-normal  py-1 text-center px-2 ">{`${t('Cards')}`}</th>
                    <th className="text-xs font-normal  py-1 px-2 text-center">{`${t('quantity')}`}</th>
                    <th className="text-xs font-normal  py-1 px-2 text-center">{`${t('Amount')}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {orderRows &&
                    orderRows.map((value, key) => (
                      <tr key={key}>
                        <td className="text-center  px-2">
                          <div className="flex font-MulishBold ">
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={value[0]}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                            <span className="ml-2">{` ${currency}`}</span>
                          </div>
                        </td>
                        <td className="font-MulishBold text-center px-2">
                          {value[1]}
                        </td>
                        <td className="text-center  px-2">
                          <div className="flex justify-end font-MulishBold ">
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={value[0] * value[1]}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                            <span className="ml-2">{` ${currency}`}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            <input
              id="tokenInput"
              autoFocus
              type="text"
              maxLength="9"
              className={`
                    text-center
                    border
                    focus:outline-none
                    focus:border-gfPeriwinkle
                    h-10
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 
                    ${fromTokenTextColor} `}
              value={fromToken}
              onChange={fromTokenChangeHandler}
              placeholder={t('TOKEN')}
              onKeyPress={(event) => {
                keyPressHandlerFROMtoken(event)
              }}
            />
            <input
              id="tokenToInput"
              maxLength="9"
              type="text"
              className={`
                    text-center
                    border
                    focus:outline-none
                    focus:border-gfPeriwinkle
                    h-10
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 
                    ${toTokenTextColor} `}
              value={toToken}
              onChange={toTokenChangeHandler}
              placeholder={t('Token-to')}
              onKeyPress={(event) => {
                keyPressHandlerTOtoken(event)
              }}
            />
            <input
              id="amountInput"
              type="number"
              min="0"
              max="9999"
              className="border
                    text-center
                    focus:outline-none
                    h-10
                    focus:border-gfPeriwinkle
                    text-gfDarkBlue
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 "
              placeholder={t('Amount-in-EUR', {
                currency: selectedTag.currency,
              })}
              value={tokenAmount}
              onChange={amountChangeHandler}
              onKeyDown={preventNonNumeric}
              onWheel={(e) => e.target.blur()}
              onKeyPress={(event) => {
                keyPressHandler(event)
              }}
            />
            <button
              id="addCardButton"
              disabled={!fromTokenValidity && tokenAmount === ''}
              type="button"
              onClick={addCardToListHandler}
              className={`bg-gfPeriwinkle font-MulishBold ${!fromTokenValidity && tokenAmount === '' ? 'bg-opacity-50' : 'bg-opacity-100'} text-gfDarkBlue rounded py-2 px-3 flex justify-center items-center focus:outline-none`}
            >
              {loadingCardRange && <ButtonLoading color={GF_DARK_BLUE} />}
              {t('Add-a-card')}
            </button>
          </div>

          {validation !== '' && (
            <div className="text-sm text-gfCoral flex justify-items-center py-2">
              {validation}
            </div>
          )}

          {cards && cards.length !== 0 && (
            <div className="mt-4">
              <div className="flex flex-row w-full lg:w-2/4 xl:w-2/4 2xl:w-2/6 justify-between bg-gfPeriwinkle bg-opacity-75 font-MulishBlack rounded text-lg items-center md:px-4 py-1 px-1">
                <div className="text-gfDarkBlue">
                  <span className="">{cards.length}</span>
                  {cards.length === 1 ? ' Card' : ' Cards'}
                </div>
                <div className=" flex flex-row text-lg text-gfDarkBlue space-x-4">
                  <span className="text-gfDarkBlue">Total :</span>
                  <div className="flex justify-start text-gfLightBlue font-semibold">
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={cards.reduce(
                        (a, card) => (a += parseFloat(card.amount)),
                        0,
                      )}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    <span className="ml-1">{`${selectedTag.currency}`}</span>
                  </div>
                </div>
              </div>

              <div className="space-y-1 mt-4 ">
                {cards.map((card, index) => (
                  <div
                    key={index}
                    className="flex flex-row w-full lg:w-2/4 xl:w-2/4 2xl:w-2/6 justify-between bg-gfPeriwinkle bg-opacity-75 rounded text-lg items-center md:px-4 py-1 px-1"
                  >
                    <div
                      className={`${!card.valid && isCardRangeDirty ? 'text-gfCoral' : 'text-gfDarkBlue'}`}
                    >
                      {card.token}
                    </div>
                    {!card.valid && (
                      <div
                        className={`${!card.valid && isCardRangeDirty ? 'text-gfCoral' : 'text-gfDarkBlue'}`}
                      >
                        {t('Card-is-not-loadable')}
                      </div>
                    )}
                    <div className="flex flex-row">
                      <div className="flex justify-start text-gfLightBlue mx-4">
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={card.amount}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <span className="ml-1">{`${selectedTag.currency}`}</span>
                      </div>
                      <button
                        id={card.token}
                        type="button"
                        onClick={removeTokenFromList}
                      >
                        <CloseIcon w={15} h={16} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col justify-end items-end my-4">
            <div className="font-bold text-xl flex flex-row justify-end text-gfDarkBlue space-x-3 mb-5">
              <span>{t('Total')}:</span>
              <div className="flex justify-start text-gfLightBlue font-MulishBlack ">
                <CurrencyFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={cardsTotalAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                <span className="ml-1">{`${tagCurrency}`}</span>
              </div>
            </div>
            <div className="flex flex-row justify-end items-end mt-3">
              <button
                id="prepareButton"
                disabled={
                  cards.length === 0 ||
                  isCardRangeDirty ||
                  cardsTotalAmount !== allRowAmount
                }
                onClick={prepareClickHandler}
                className={`${
                  cards.length === 0 ||
                  isCardRangeDirty ||
                  cardsTotalAmount !== allRowAmount
                    ? 'bg-opacity-50'
                    : 'bg-opacity-100'
                }  focus:outline-none py-2 px-8 text-base font-MulishBold text-gfDarkBlue bg-gfCoral rounded`}
              >
                {t('Prepare-order')}
              </button>
              <button
                id="resetButton"
                onClick={resetHandler}
                type="reset"
                className={`md:ml-8 ml-0 md:mt-0 mt-4 focus:outline-none py-2 px-8 font-MulishBold text-gfDarkBlue bg-gfPeriwinkle rounded`}
              >
                {t('Reset')}
              </button>
            </div>
          </div>

          {validationErrors && (
            <div className="py-2">
              <ValidationErrors {...validationErrors} />
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  )
}
