import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Form, FormControl, FormField, FormItem } from '../../Form'
import QuantityField from '../../Input/QuantityField'
import { InputGroup, InputGroupText } from '../../InputGroup'
import TextField from '../../Input/TextField'

export default function AddGiftCardForm({ onSuccess }) {
  const { t } = useTranslation()
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag)
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: '',
      quantity: 1,
    },
  })

  const {
    formState: { errors, isValid },
  } = methods

  function handleSubmit(data) {
    onSuccess({
      amount: parseFloat(data.amount).toFixed(2),
      quantity: parseInt(data.quantity),
    })
    methods.reset()
  }

  return (
    <Form {...methods}>
      <div className="flex flex-row space-x-3">
        <FormField
          name="amount"
          rules={{
            required: t('place-b2b-order.required'),
            min: {
              value: selectedTag.min_load,
              message: t(
                'Cards-are-loadable-between-Please-select-another-amount-and-try-again',
                {
                  min: selectedTag.min_load,
                  max: selectedTag.max_load,
                  currency: tagCurrency,
                },
              ),
            },
            max: {
              value: selectedTag.max_load,
              message: t(
                'Cards-are-loadable-between-Please-select-another-amount-and-try-again',
                {
                  min: selectedTag.min_load,
                  max: selectedTag.max_load,
                  currency: tagCurrency,
                },
              ),
            },
          }}
          render={(field) => (
            <FormItem className="w-32">
              <InputGroup>
                <FormControl>
                  <TextField
                    {...field}
                    placeholder={`100.00`}
                    className="pr-12 text-right"
                    type="number"
                  />
                </FormControl>
                <InputGroupText position="right">{tagCurrency}</InputGroupText>
              </InputGroup>
            </FormItem>
          )}
        />
        <FormField
          name="quantity"
          rules={{ required: t('place-b2b-order.required'), min: 1 }}
          render={(field) => (
            <FormItem>
              <FormControl>
                <QuantityField {...field} min={1} />
              </FormControl>
            </FormItem>
          )}
        />
        <button
          className="bg-gfPeriwinkle border border-gfPeriwinkle font-MulishBold text-gfDarkBlue rounded text-sm py-2 px-3 focus:outline-none disabled:opacity-50"
          data-testid="addCardsButton"
          type="button"
          disabled={!isValid}
          onClick={methods.handleSubmit(handleSubmit)}
        >
          {t('Add card')}
        </button>
      </div>
      <div className={'mt-2 text-sm font-medium text-gfCoral'}>
        {errors.amount?.message}
      </div>
    </Form>
  )
}
